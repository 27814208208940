.component-list {
  display: flex;
  flex-wrap: wrap;
  &-box {
    background: #f8f8f8;
    padding: 27px 25px;
    height: 100%;
    h3 {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 900;
      color: #ec4f7d;
    }
    a {
      position: relative;
      display: inline-block;
      margin-bottom: 5px;
      padding-left: 11px;
      font-size: 13px;
      line-height: 22px;
      color: #111;
      &:hover {
        color: #337ab7;
      }
      &:before {
        display: inline-block;
        height: 2px;
        width: 2px;
        content: "";
        margin-right: 5px;
        background: #111;
        vertical-align: middle;
        position: absolute;
        left: 0;
        top: 10px;
      }

      i {
        margin-left: 3px;
        font-size: 1rem;
      }
    }
    ul {
      margin-bottom: 28px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .col-md-6{
    padding-bottom: 20px;
  }
}

@include media-breakpoint-down(sm) {
  .component-list-box{
    height: auto;
    margin-bottom: 20px;
  }
}
