/**
 * @file _banner.scss
 * @description banner 관련 파일 
 */

.banner {
  display: inline-block;
}

.banner-fa li,
.banner-inline li {
  display: inline-block;
  float: left;
}

.banner-fa {
  a {
    display: inline-block;
    position: relative;
    padding: 10px;
    text-align: center;
    i {
      height: 72px;
      color: #656565;
      &.fa-address-card-o,
      &.fa-print {
        font-size: 4.8rem;
        padding-top: 5px;
      }
      &.fa-building-o,
      &.fa-id-badge {
        font-size: 5.6rem;
      }
    }
    p {
      color: #1b252e;
      font-size: 1.3rem;
    }
  }
  li+li {
    margin-left: 20px;
    a {
      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 34px;
        left: -14px;
        width: 9px;
        height: 9px;
        border-radius: 5px;
        background-color: #dddddd;
      }
    }
  }
}

.banner-inline {
  li+li {
    margin-left: 10px;
  }
  a {
    // display: inline-block;
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 30px;
    width: 136px;
    height: 146px;
    border: 2px solid #ed8dab;
    text-align: center;
  }
  p {
    position: absolute;
    bottom: 20px;
    width: 100%;
    color: #2a343c;
    font-size: 1.6rem;
    font-weight: bold;
  }
  li+li {
    margin-top: 5px;
  }
  a {
    display: block;
    position: relative;
    width: 222px;
    height: 45px;
    padding-left: 55px;
    line-height: 42px;
    font-size: 1.6rem;
    color: #dde8f2;
    background-color: #293846;
  }
}

.banner-list.banner-partnerplus {
  a {
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 20px;
      width: 24px;
      height: 24px;
      background: url("/assets/icon/pp_banner_list.png") no-repeat;
    }
  }
  li {
    &:nth-child(even) {
      a {
        &:before {
          transform: rotate(106deg);
        }
      }
    }
  }
}