/* Bullet list */

.bu-list {
  >li {
    position: relative;
    margin-top: 5px;
    padding-left: 10px;
    &:first-child {
      margin-top: 0;
    }
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 3px;
      height: 3px;
      top: 7px;
      left: 0;
      border-radius: 50%;
      background-color: #999;
    }
  }
  &.tbl {
    >li {
      line-height: 21px;
      &:before {
        top: 9px;
      }
    }
  }
}

.list-flex {
  display: flex;
  align-items: center;
  gap: 5px;
  &.gap-10 {
    gap: 10px;
  }
  li.wd-remain {
    flex-grow: 1;
  }
}