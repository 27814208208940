/**
 * @file _app.scss
 * @description body, header, sidebar 등의 전체레이아웃과 관련된 스타일 클래스 파일
 */

// IE10&11 Flexbox 버그 픽스
@media all and (-ms-high-contrast:none) {
  html {
    display: flex;
    flex-direction: column;
  }
}

// 루트 (app)
.app, app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  flex: 0 0 $navbar-height;
}

.app-footer {
  flex: 0 0 $footer-height;
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;
  .main {
    flex: 1;
    min-width: 0;
  }
  .sidebar {
    // $sidebar-width is the width of the columns
    // put the nav on the left
    flex: 0 0 $sidebar-width;
    order: -1;
  }
  .aside-menu {
    // $aside-menu-width is the width of the columns
    flex: 0 0 $aside-menu-width;
  }
}

app-content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  //overflow-x: hidden;
}

// 컨테이너
.container, .container-fluid {
  height: 100%;
}

.container-fluid {
  min-height: auto;
  background-color: #f2f5f5;
  overflow-y: auto;
  padding: 0;
}


@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1350px;
  }
}
// 레이아웃 유틸리티 클래스

// 헤더 고정 (fixed)
.header-fixed {
  .app-header {
    position: fixed !important;
    z-index: $zindex-sticky;
    width: 100%;
  }
  .app-body {
    margin-top: $navbar-height;
  }
}

// 사이드바 숨김
.sidebar-hidden {
  .sidebar {
    margin-left: - $sidebar-width;
  }
}

// 사이드바 고정 (fixed)
.sidebar-fixed {
  .sidebar {
    position: fixed;
    z-index: $zindex-sticky - 1;
    width: $sidebar-width;
    height: calc(100vh - #{$navbar-height});
  }
  .main,
  .app-footer {
    margin-left: $sidebar-width;
  }
  &.sidebar-hidden {
    .main,
    .app-footer {
      margin-left: 0;
    }
  }
}

.sidebar-off-canvas {
  .sidebar {
    position: fixed;
    z-index: $zindex-sticky - 1;
    height: calc(100vh - #{$navbar-height});
  }
}

// 일반 (데스크탑 기준) 레이아웃
@include media-breakpoint-up(lg) {
  .sidebar-compact {
    .sidebar {
      flex: 0 0 $sidebar-compact-width;
    }
    &.sidebar-hidden {
      .sidebar {
        margin-left: - $sidebar-compact-width;
      }
    }
    &.sidebar-fixed {
      .main,
      .app-footer {
        margin-left: $sidebar-compact-width;
      }
      .sidebar {
        width: $sidebar-compact-width;
      }
      &.sidebar-hidden {
        .main,
        .app-footer {
          margin-left: 0;
        }
      }
    }
    .sidebar-minimizer {
      display: none;
    }
  }
  .sidebar-minimized {
    .sidebar {
      flex: 0 0 $sidebar-minimized-width;
    }
    &.sidebar-hidden {
      .sidebar {
        margin-left: - $sidebar-minimized-width;
      }
    }
    &.sidebar-fixed {
      .main,
      .app-footer {
        margin-left: $sidebar-minimized-width;
      }
      .sidebar {
        width: $sidebar-minimized-width;
      }
      &.sidebar-hidden {
        .main,
        .app-footer {
          margin-left: 0;
        }
      }
    }
  }
}

// 확장 패널 (aside) 숨김
.aside-menu-hidden {
  .aside-menu {
    margin-right: - $navbar-info-width;
  }
}

// 확장 패널 (aside) 고정 (fixed)
.aside-menu-fixed {
  .aside-menu {
    position: fixed;
    right: 0;
    height: calc(100vh - #{$navbar-height});
    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }
  }
  /*
  .main,
  .app-footer {
    margin-right: $aside-menu-width;
  }
  &.aside-menu-hidden {
    .main,
    .app-footer {
      margin-right: 0;
    }
  }*/
}

.aside-menu-off-canvas {
  .aside-menu {
    position: fixed;
    right: 0;
    z-index: $zindex-sticky - 1;
    height: 100%;
    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }
  }
}

// breadcrumb 고정 (fixed)
.breadcrumb-fixed {
  .main {
    $breadcrumb-height: 2 * $breadcrumb-padding-y + $font-size-base + 1.5 * $spacer;
    padding-top: $breadcrumb-height;
  }
  .breadcrumb {
    position: fixed;
    top: $navbar-height;
    right: 0;
    left: 0;
    z-index: $zindex-sticky - 2;
  }
  // if sidebar + main + aside
  .main:nth-child(2) {
    .breadcrumb {
      right: $aside-menu-width;
      left: $sidebar-width;
    }
  }
  // if sidebar + main
  .main:first-child {
    .breadcrumb {
      right: $aside-menu-width;
      left: 0;
    }
  }
  // if  main + aside
  .main:last-child {
    .breadcrumb {
      right: 0;
    }
  }
  &.sidebar-minimized {
    .main .breadcrumb {
      left: $sidebar-minimized-width;
    }
  }
  &.sidebar-hidden,
  &.sidebar-off-canvas {
    .main .breadcrumb {
      left: 0;
    }
  }
  &.aside-menu-hidden,
  &.aside-menu-off-canvas {
    .main .breadcrumb {
      right: 0;
    }
  }
}

// 푸터 고정 (fixed)
.footer-fixed {
  .app-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-sticky;
    height: $footer-height;
  }
  .app-body {
    margin-bottom: $footer-height;
  }
}

// 애니메이션
.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu,
.quicklink-menu {
  transition: margin-left $layout-transition-speed, margin-right $layout-transition-speed, width $layout-transition-speed, flex $layout-transition-speed;
}

.sidebar-nav {
  transition: width $layout-transition-speed;
  //padding:0 8px;
}

.breadcrumb {
  transition: left $layout-transition-speed, right $layout-transition-speed, width $layout-transition-speed;
}

// 홈, 샘플 등 사이드바가 없는 화면 레이아웃
// eg. sidebar.sidebar-suppressed
.sidebar-suppressed {
  margin-left: -199px;
}

// 모바일 레이아웃
@include media-breakpoint-down(md) {
  .app-header.navbar {
    position: fixed !important;
    z-index: $zindex-sticky;
    width: 100%;
    text-align: center;
    .navbar-toggler {
      background: transparent;
      border: none;
    }
    .navbar-brand {
      position: absolute;
      background-color: transparent;
      width: auto;
      left: calc(50% - 45px);
      span {
        text-align: left;
      }
    }
  }
  .app-body {
    margin-top: $navbar-height;
  }
  .breadcrumb-fixed {
    .main:nth-child(2) .breadcrumb {
      right: auto;
      left: auto;
      width: 100%;
    }
  }
  .sidebar {
    position: fixed;
    z-index: $zindex-sticky - 1;
    width: $mobile-sidebar-width;
    height: calc(100vh - #{$navbar-height});
    margin-left: - $mobile-sidebar-width;
    .sidebar-nav,
    .nav {
      // min-height: calc(100vh - #{$navbar-height});
    }
    .sidebar-minimizer {
      display: none;
    }
  }
  .main,
  .app-footer {
    margin-left: 0 !important;
  }
  .sidebar-hidden {
    .sidebar {
      margin-left: - $mobile-sidebar-width;
    }
  }
  .sidebar-mobile-show {
    overflow-y: hidden;

    .sidebar {
      margin-left: 0;
    }
  }
}

// 로더
.app-loading {
  .sk-cube-grid {
    position: fixed !important;
  }
}

// 출력
@media print {
  aside {
    display: none;
  }
  .aside-menu-fixed {
    .main {
      margin-right: 0 !important;
    }
  }
}

.navbar .navbar-item {
  .show .quicklink-menu {
    margin-left: 0;
  }
  .quicklink-menu {
    position: absolute;
    z-index: $zindex-sticky;
    display: block;
    width: 200px;
    height: calc(100vh - 90px);
    padding: 0;
    margin: 0 0 0 -250px;
    background-color: $white;
    border: 0;
    border-right: 1px solid $border-color;
    &:before {
      content: "";
      position: absolute;
      top:-8px;
      left:18px;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent transparent #f92465 transparent;
      border-width: 0 6px 8px 6px;
      border-style: solid;
    }
    .quicklink-header {
      padding: 15px 0;
      background-color: #f92465;
      font-size: 1.5rem;
      font-weight: $font-weight-bold;
      color: $white;
      text-indent: 20px;
    }
    .quicklink-body {
      height: calc(100vh - 152px);
      margin-top: 10px;
      overflow-y: auto;
      .quicklink-item {
        padding: 4px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .fav-btn {
          color: $gray-200;
          background-color: transparent;
          &.active {
            color: $lua-pink-500;
          }
        }
        a {
          font-size: 1.25rem;
          margin-left: 0.5rem;
          color: $gray-550;
          &:hover,&.active {
            font-weight: $font-weight-bold;
            color: $gray-600;
          }
        }
      }
    }
  }
}