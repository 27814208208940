/**
 * @file _info-page-layout.scss
 * @description 정보성 페이지의 리스트등의 레이아웃 스타일 클래스 파일
 */

.content-info {
  padding-left: 10px;
  p {
    line-height: 20px;
  }
  em {
    color: #337ab7;
  }
  h4+p {
    margin-top: 4px;
  }
  *+img {
    margin-top: 20px;
  }
  img+div {
    margin-top: 30px;
  }
  +h4 {
    margin-top: 40px;
  }
}

.content-inline {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  >div+div {
    margin-left: 35px;
  }
}

.content-list {
  padding: 0;
  margin: 0;
  >li {
    position: relative;
    padding-left: 18px;
    margin: 0;
    line-height: 24px;
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 4px;
      height: 4px;
      top: 11px;
      left: 5px;
      border-radius: 50%;
      background-color: #bcbcbc;
    }
  }
  p {
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    >span {
      display: inline-block;
      float: left;
      height: 20px;
      line-height: 20px;
      +.btn-icon {
        float: left;
        height: 20px;
        line-height: 22px;
      }
    }
  }
}

td {
  .content-list>li:before {
    top: 12px
  }
}

p+.content-list {
  margin-top: 5px;
}