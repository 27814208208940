/**
 * @file _sample-page.scss
 * @description 샘플 메인화면의 스타일 클래스 파일
 */

.intro-layout {
  margin-top: 50px;
  padding-bottom: 50px;
  height: calc(100% - 48px);
  background: #f4f4f4;
  .home-contents-title-sub {
    margin-bottom: 0;
    padding-bottom: 20px;
    background: #fff;
    font-family: 'LGSmHaR', sans-serif;
  }
  &-box {
    padding: 39px 34px 34px;
    border-top: 3px solid #666;
    ul {
      margin-top: 30px;
      max-width: 444px;
    }
    li {
      position: relative;
      margin-bottom: 25px;
      padding-left: 8px;
      font-size: 1.3rem;
      color: #111;
      &:before {
        display: inline-block;
        height: 2px;
        width: 2px;
        content: "";
        margin-right: 5px;
        background: #111;
        vertical-align: middle;
        position: absolute;
        left: 0;
        top: 7px;
      }
    }
    .home-contents-btn-main {
      position: absolute;
      bottom: 30px;
      height: 48px;
      line-height: 47px;
      padding: 0 18px 0 27px;
      font-size: 18px;
      font-family: 'LGSmHaL', sans-serif;
      i {
        vertical-align: -5px;
      }
    }
  }
}

//@include media-breakpoint-down(sm) {
//  .page-content {
//    padding: 0 0 30px;
//  }
//}
