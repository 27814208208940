/**
 * @file _buttons.scss
 * @description 버튼 레이아웃 관련 파일 
 */

.btn {
  height: 30px;
  line-height: 1.5;
  padding: 3px 12px 5px;
  border-radius: 0;
  font-size: 1.2rem;
  &.btn-icon-single {
    height: 26px;
    padding: 3px 8px 5px;
    background: none;
    i {
      color: $gray-600;
      font-size: 1.4rem;
      vertical-align: middle;
    }
    span {
      font-size: 0;
      text-indent: -9999px;
      vertical-align: middle;
    }
  }
  &.btn-large {
    width: 224px;
    height: 46px;
    font-size: 1.4rem;
    font-weight: bold;
  }
  .btn-red {
    border-color: #eb225f;
    background-color: $lua-pink-500;
    color: #fff;
    &:focus,
    &:hover,
    &:active,
    &:active:hover,
    &:active:focus {
      background-color: #eb225f;
    }
  }
  span {
    display: inline;
    text-indent: 0;
    color: inherit !important;
  }
  i+span,
  span+i {
    margin-left: 6px;
  }
  .fa-angle-up,
  .fa-angle-down {
    font-weight: 600;
    color: #494949;
  }
  .fa-bars {
    font-size: 1.3rem;
    color: #bcbcbc;
  }
  &.btn-icon {
    width: 18px;
    height: 18px;
    padding: 0;
    border: none;
    background: none;
    +span {
      margin-left: 4px;
    }
  }
  .fa-file-word-o,
  .fa-file-powerpoint-o,
  .fa-file-pdf-o,
  .fa-file-excel-o,
  .fa-file-text-o,
  .fa-plus,
  .fa-close,
  .fa-refresh,
  .fa-undo {
    position: relative;
    top: 1px;
  }
  .up,
  .down,
  .up-all,
  .up-pic,  
  .check,
  .g-plus,
  .g-minus,
  .plus,
  .minus,
  .undo,
  .file-excel,
  .bars {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 14px;
    height: 14px;
    //top: 1px;
    background: url("/assets/images/icon/grid_button.png") no-repeat 0 -6px;
  }
  .up {background-position-x: -68px;}
  .down {background-position-x: -68px;}
  .up-all {background-position-x: -68px;}
  .up-pic {background-position-x: -118px;}
  .check {background-position-x: -368px;}
  .g-plus {background-position-x: -218px;}
  .g-minus {background-position-x: -268px;}
  .plus {background-position-x: -418px;}
  .minus {background-position-x: -468px;}
  .undo {background-position-x: -168px;}
  .file-excel {background-position-x: -18px;}
  .bars {background-position-x: -562px;}
  &:disabled {
    .up,
    .down,
    .up-all,
    .up-pic,  
    .check,
    .g-plus,
    .g-minus,
    .plus,
    .minus,
    .undo,
    .file-excel {
      background-position-y: -36px;
    }
  }
  span {
    em {
      font-weight: bold;
      color: $lua-pink-500;
    }
  }
}
.btn-custom {
  border: 1px;
  background: white;
  color: black;
  width: 40px; 
  height: 20px; 
  text-align:center
}

.btn-default {
  border-color: #9facac;
  background: #fff;
  color: #202020;
  border-radius: 3px;
  &:focus,
  &:hover,
  &:active,
  &:active:hover,
  &:active:focus {
    border-color: #202020;
  }
  &:disabled {
    opacity: 1;
    border-color: #abb6b6 !important;
    background: #e2e2e2 !important;
    color: #949494 !important;
  }
  .fa-chevron-left,
  .fa-chevron-right {
    position: relative;
    top: 1px;
    color: #bcbcd7;
  }
}

.btn-primary {
  border-color: #2e5a73;
  background: #2e5a73;
  color: #fff;
  border-radius: 3px;
  &:focus,
  &:hover,
  &:active,
  &:active:hover,
  &:active:focus {
    border-color: #193f55 !important;
    background: #193f55 !important;
  }
  &:disabled {
    opacity: 1;
    border-color: #42535d !important;
    background: #42535d !important;
    color: #95a7b2 !important;
  }
}

.btn-secondary {
  border-color: #336798;
  background: #336798;
  color: #fff;
  border-radius: 3px;
  &:focus,
  &:hover,
  &:active,
  &:active:hover,
  &:active:focus {
    border-color: #4e6c88 !important;
    background: #4e6c88 !important;
  }
&:disabled {
    opacity: 1;
    border-color: #4e6c88 !important;
    background: #4e6c88 !important;
    color: #95a7b2 !important;
  }
}

.btn-toggle {
  border-color: #a8a8a8;
  background: #fff;
  color: #293846;
  border-radius: 2px; 
  //&:focus,
  &:hover,
  &:active,
  &:active:hover,
  &:active:focus {
    //padding: 4px 11px 5px;
    border: 1px solid $idp-navy;
    color: #202020;
  }
  span {
    +i {
      margin-left: 10px;
      /*
      position: relative;
      top: -2px;
      display: inline-block;
      width: 10px;
      height: 6px;
      background: url("/assets/images/icon/stepper.png") no-repeat center -27px;*/
    }
  }
}
.btn-toggle2 {
  margin-left: 10px;
  position: relative;
  width:24px;
  height:14px;
  background: transparent;
  span {
    font-size: 0;
  }
  :before {
    content:"";
    position: absolute;
    top: 5px;
    left: 0;
    display: inline-block;
    width: 24px;
    height: 4px;
    background-color :#c7d2d2;
    border-radius: 4px;
  }
  :after {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width:14px;
    height:14px;
    border-radius: 14px;
    border: 3px solid #a4b1b1;
    background: #e9f0f0;
    box-sizing: border-box;
    box-shadow: 0 3px 4px rgba(0,0,0,0.2);
  }
  &.active {
    :before {
      background-color :#92ded2;
    }
    :after {
      left: inherit;
      right: 0;
      border-color: #00cfae;
      background: $white;
    }
  }
}

.btn-inline {
  height: 25px;
  padding: 4px 10px 6px;
  font-size: 1.1rem;
  border-radius: 2px;
}

*+.btn-inline {
  margin-left: 10px;
}

.table-detail {
  th {
    .btn-inline {
      height: 22px;
      padding: 2px 6px 6px;
      background-color: #fdfdfd;
    }
  }
  /*
  td {
    .btn-inline {
      margin-left: 3px;
    }
  }
  */
  .list-inline {
    //margin-left: 0;
    //vertical-align: top;
    .btn-icon {
      height: 25px;
    }
  }
}

.btn-module {
  height: 27px;
  padding: 4px 8px 6px;
  font-size: 1.1rem;
  border-radius: 2px !important;
}

.btn-important {
  width: 80px;
}

.list-inline {
  .btn-module,
  .division,
  .btn-inline {
    margin-right: -5px;
  }
}

*+.btn-block {
  margin-top: 20px;
}
.tab-container +.btn-block {
  margin-top: 0;
}

td *+.btn-block {
  margin-top: 4px;
}

.btn-block {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  +* {
    margin-top: 20px;
  }
  +.module-wrapper {
    margin-top: 30px;
  }
  +.btn-block {
    margin-top: 10px;
  }
  .list-inline {
    &.pull-right {
      margin-right: -5px;
      li {
        margin-bottom: 0;
      }
    }
    .list-inline {
      border-right: 1px solid $gray-200;
    }
  }
}

li {
  >p {
    height: 30px;
    line-height: 28px;
  }
  >select {
    height: 30px;
  }
  >.input-radio {
    label {
      line-height: 24px;
    }
  }
}

td .btn-block .list-inline.pull-right {
  margin-right: 0;
}

.btn-block li>.input-radio input[type="radio"].form-control,
.btn-block li>.input-radio input[type="radio"].form-control+span {
  height: 30px;
}

.list-inline {
  .division {
    display: inline-block;
    position: relative;
    top: 2px;
    width: 1px;
    height: 26px;
    background: #cccccc;
  }
  li .btn.btn-icon {
    height: 26px;
  }
  &.module-toggle li .btn.btn-icon {
    height: 18px;
  }
}

.module-header .list-inline .division,
.module-footer .list-inline .division {
  height: 24px;
  margin-left: 2px;
}

.table-list .list-inline>li {
  line-height: 22px;
}

.table-list .btn-inline{
  margin-left: 0;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
  box-shadow: none !important;
}

.info-text .btn.btn-icon i {
  width: 100%;
  height: 18px;
  line-height: 18px;
}

.btn.btn-default.btn-module span {
  line-height: 13px;
}

.btn-use {
  position: relative;
  width:27px;
  height:16px;
  background: $gray-500;
  border-radius: 16px;
  vertical-align: middle;
  .fa {
    position: absolute;
    left: 3px;
    top: 50%;
    margin-top: -5px;
    display: block;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 10px;
    border: 2px solid #fff;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.2);
  }
  &.active {
    background: #04cfb1;
    .fa {
      left: inherit;
      right: 3px;
    }
  }
  + span {
    display: inline-block;
    margin-left: 5px;
    line-height: 2rem;
    vertical-align: middle;
  }
}

.btn-switch {
  padding: 2px;
  display: inline-block;
  height: 25px;
  background-color: #dde7e8;
  border-radius: 2px;
  text-align: center;
  label {
    min-width: 60px;
    height: 21px !important;
    line-height: 21px !important;
    cursor: pointer;
  }
  label + label {
    margin-left: 5px;
  }
  label input[type="radio"] {
    width: 0;
    padding: 0;
    opacity: 0;
    + span {
      padding: 0 2px;
      position: absolute;
      left:0;
      top:0;  
      display: inline-block;
      width: 100%;
      // height: 21px;
      // line-height: 21px;
      text-align: center;
      color: #80908f;
      border-radius: 2px;
      background-color: transparent;
      margin-left: 0;
    }
    &:checked {
      + span {
        color: #00927f;
        background-color: $white;
        box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
      }
    }
  }
  + * {
    margin-right: 10px;
  }
}

.stepper {
  input[type="text"], input[type="number"] {
    width: 35px !important;
    height: 25px;
    border: 1px solid $input-border-color;
    border-radius: 2px 0 0 2px;
  }
  .btn-stepper {
    margin-left: -1px;
    display: inline-block;
    vertical-align: top;
    font-size: 0;
    width: 25px;
    height: 25px;
    border: 1px solid $input-border-color;
    border-radius: 0 2px 2px 0;
    button {
      display: block;
      width: 23px;
      height: 12px;
      background: url("/assets/images/icon/stepper.png") no-repeat center -5px;
      
      &.btn-up {
        height: 13px;
        border-bottom: 1px solid $input-border-color;
      }
      &.btn-down {
        background-position-y: -25px;
      }
    }
  }
}

.btn-window {
  position: relative;
  background-color: $white;
  width: 36px;
  height: 30px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: url("/assets/images/icon/new_window.png") no-repeat center;
    span {
      font-size: 0;
    }
  }
}

.btn-fav {
  width: 16px;
  height: 16px;
  background: url("/assets/images/icon/fav_star.png") no-repeat -5px -5px;
  &.active {
    background-position-y: -30px;
  }
  span {
    font-size: 0;
    text-indent: -9999px;
  }
}

.btn-delete {
  width: 25px;
  height: 26px;
  background: url("/assets/images/icon/delete.png") no-repeat center;
}

//삭제예정
.tree-button-updown {
  display: block;
  margin-top: 0;
  height: 25px;
  text-align: right;
}
//새로운 tree 버튼
.btn-tree {
  height: 29px;
  padding-left: 5px;
  padding-right: 5px;
  i {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-image: url("/assets/images/icon/tree.png");
    background-repeat: no-repeat;
    background-position-y: -31px;
    &.open {
      background-position-x: -3px;
    }
    &.closed {
      background-position-x: -19px;
    }
  }
  &-arrow {
    width: 48px;
    i {
      display: inline-block;
      width:24px;
      height:24px;
      background-image: url("/assets/images/icon/arrow.png");
      background-repeat: no-repeat;

      background-position-y:0;
      &.vertical {
        background-position-y:-24px;
      }
      &.all-next {
        background-position-x:0;
      }
      &.next {
        background-position-x:-24px;
      }
      &.prev {
        background-position-x:-48px;
      }
      &.all-prev {
        background-position-x:-72px;
      }            
    }
    span {
      font-size: 0;
    }
  }
  
}

.btn-always {
  position: relative;
  z-index: 2000;
}

.list-btn-pop {
  right: -5px !important;
  min-width: 180px !important;
  ul {
    padding: 5px 10px 10px 10px;
    .btn {
      width: 100%;
      white-space: nowrap;
      margin-right: 0;
    }
  }
}

i.edit {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 12px;
  height: 12px;
  background: url("/assets/images/icon/edit.png") no-repeat center;
}

i.search {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 12px;
  height: 12px;
  background: url("/assets/images/icon/search.png") no-repeat center;
}

.btn-content-summary {
  height: 25px;
  padding: 4px 10px 6px;
  font-size: 1.1rem;
  border-radius: 2px;
  margin-bottom: 2px;
  margin-right: 2px;
}

.btn-icon-add {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 25px;
  height: 25px;
  border: 1px solid $input-border-color;
  border-radius: 2px;
  background: url("/assets/images/icon/plus.png") no-repeat center;
}

.btn-multi-switch {
  button {
    border-radius: 0px;
    &.blue-label {
      color: blue;
    }
    &.red-label {
      color: red;
    }
    &.active {
      background-color:  #e6ffcc;
      + span {
        font-weight: bold;        
      }
    }
  }
}

