/**
 * @file _grid.scss
 * @description 레이아웃 그리드 관련 파일
 */

//
// 10 columns grid layout - start
//
.col-xs-101,
.col-xs-102,
.col-xs-103,
.col-xs-104,
.col-xs-105,
.col-xs-106,
.col-xs-107,
.col-xs-108,
.col-xs-109,
.col-xs-110,
.col-sm-101,
.col-sm-102,
.col-sm-103,
.col-sm-104,
.col-sm-105,
.col-sm-106,
.col-sm-107,
.col-sm-108,
.col-sm-109,
.col-sm-110,
.col-md-101,
.col-md-102,
.col-md-103,
.col-md-104,
.col-md-105,
.col-md-106,
.col-md-107,
.col-md-108,
.col-md-109,
.col-md-110,
.col-lg-101,
.col-lg-102,
.col-lg-103,
.col-lg-104,
.col-lg-105,
.col-lg-106,
.col-lg-107,
.col-lg-108,
.col-lg-109,
.col-lg-110 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-101 {
  width: 10%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-101 {
    width: 10%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-101 {
    width: 10%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-101 {
    width: 10%;
    float: left;
  }
}

.col-xs-102 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-102 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-102 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-102 {
    width: 20%;
    float: left;
  }
}

.col-xs-103 {
  width: 30%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-103 {
    width: 30%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-103 {
    width: 30%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-103 {
    width: 30%;
    float: left;
  }
}

.col-xs-104 {
  width: 40%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-104 {
    width: 40%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-104 {
    width: 40%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-104 {
    width: 40%;
    float: left;
  }
}

.col-xs-105 {
  width: 50%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-105 {
    width: 50%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-105 {
    width: 50%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-105 {
    width: 50%;
    float: left;
  }
}

.col-xs-106 {
  width: 60%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-106 {
    width: 60%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-106 {
    width: 60%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-106 {
    width: 60%;
    float: left;
  }
}

.col-xs-107 {
  width: 70%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-107 {
    width: 70%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-107 {
    width: 70%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-107 {
    width: 70%;
    float: left;
  }
}

.col-xs-108 {
  width: 80%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-108 {
    width: 80%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-108 {
    width: 80%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-108 {
    width: 80%;
    float: left;
  }
}

.col-xs-109 {
  width: 90%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-109 {
    width: 90%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-109 {
    width: 90%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-109 {
    width: 90%;
    float: left;
  }
}

.col-xs-110 {
  width: 100%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-110 {
    width: 100%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-110 {
    width: 100%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-110 {
    width: 100%;
    float: left;
  }
}

//
// 10 columns grid layout - end
//

.row.row-equal {
  padding-right: ($grid-gutter-width / 4);
  padding-left: ($grid-gutter-width / 4);
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);
  [class*="col-"] {
    padding-right: ($grid-gutter-width / 4);
    padding-left: ($grid-gutter-width / 4);
  }
}
