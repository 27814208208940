
@mixin ag-theme-base-part-column-drop {

    // CELL (SHARED)

    .ag-column-drop-cell {
        @include ag-color-property(background, chip-background-color);
        border-radius: ag-param(grid-size) * 4;
        height: ag-param(grid-size) * 4;
        padding: 0 ag-param(grid-size) / 2;
    }

    .ag-column-drop-cell-text {
        margin: 0 ag-param(grid-size);
    }

    .ag-column-drop-cell-button {
        min-width: ag-param(grid-size) * 4;

        margin: 0 ag-param(grid-size) / 2;
        @include ag-color-property(color, secondary-foreground-color);
    }
    
    .ag-column-drop-cell-drag-handle {
        margin-left: ag-param(grid-size) * 2;
    }

    .ag-column-drop-cell-ghost {
        opacity: 0.5;
    }

    // HORIZONTAL COLUMN DROP
    
    .ag-column-drop-horizontal {
        @include ag-color-property(background-color, control-panel-background-color);
        @include ag-color-property(color, secondary-foreground-color);
        height: ag-param(row-height);
        @include ag-border(primary, bottom);

        @include ag-theme-rtl(( padding-left: ag-param(cell-horizontal-padding) ));
    }
    
    .ag-column-drop-horizontal-half-width:not(:last-child) {
        @if ag-param(borders) {
            @include ag-theme-rtl((border-right: solid 1px), $color-param: border-color);
        }
    }

    .ag-column-drop-horizontal-cell-separator {
        margin: 0 ag-param(grid-size);
        @include ag-color-property(color, secondary-foreground-color);
    }

    .ag-column-drop-horizontal-empty-message {
        @include ag-color-property(color, disabled-foreground-color);
    }

    .ag-column-drop-horizontal-icon {
        @include ag-theme-rtl(( margin-right: ag-param(cell-horizontal-padding) ));
    }

    // VERTICAL COLUMN DROP

    .ag-column-drop-vertical-list {
        padding-bottom: ag-param(grid-size);
        padding-right: ag-param(grid-size);
        padding-left: ag-param(grid-size);
    }

    .ag-column-drop-vertical-cell {
        margin-top: ag-param(grid-size);
    }

    .ag-column-drop-vertical {
        min-height: 50px;
        max-height: 150px;
        
        @include ag-border(secondary, bottom);
        &.ag-last-column-drop {
            @include ag-border(secondary, bottom, $none: true);
        }
    }

    .ag-column-drop-vertical-icon {
        margin-left: ag-param(grid-size);
        margin-right: ag-param(grid-size);
    }

    .ag-column-drop-vertical-list {
        position: relative;
    }

    .ag-column-drop-vertical-empty-message {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        @include ag-color-property(color, disabled-foreground-color);
        margin-top: ag-param(grid-size);
    }

    .ag-select-agg-func-popup {
        @include ag-card();

        @include ag-color-property(background, background-color);
        height: ag-param(grid-size) * 5 * 3.5;
        padding: 0;
    }

    .ag-select-agg-func-virtual-list-item {
        cursor: default;
        line-height: ag-param(grid-size) * 5;
        padding-left: ag-param(grid-size) * 2;

        &:hover {
            @include ag-color-property(background-color, selected-row-background-color);
        }
    }
}
