/**
 * @file _timepicker.scss
 * @description 타임피커의 레이아웃 스타일 클래스 파일 
 */

.timepicker {
  position: relative;
  display: inline-block;
  //float: left;
  +.timepicker-range {
    float: left;
    display: inline-block;
    width: 22px;
    height: 26px;
    line-height: 24px;
    text-align: center;
  }
  input[type="text"] {
    &.form-control {
      width: 80px;
    }
  }
  >span {
    display: inline-block;
    width: 90px;
    height: 26px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .input-group-append{
    padding: 5px 9px;
    background: none;
    border: none;
    border-radius: 0;
    z-index: 10;
    text-align: left;
    width:auto;
  }
  &.bootstrap-timepicker {
    .input-group-addon {
      padding: 5px 8px;
      background: none;
      border: none;
      border-radius: 0;
      z-index: 10;
      text-align: left;
    }
  }
}

.bootstrap-timepicker-widget {
  min-width: 140px;
  border-color: #a8a8a8;
  border-radius: 0;
  box-shadow: none;
  .timepicker-orient-top {
    margin-top: -1px;
  }
  .timepicker-orient-bottom {
    margin-top: 5px;
  }
  &.timepicker-orient-top:before,
  &.timepicker-orient-top:after,
  &.timepicker-orient-bottom:before,
  &.timepicker-orient-bottom:after {
    display: none;
  }
  table {
    border-top: none;
    td {
      height: 30px;
      padding: 0;
      font-size: 1.2rem;
      a {
        padding: 4px 0;
      }
    }
  }
}

