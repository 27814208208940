/**
 * @file _bootstrap.scss
 * @description bootstrap의 커스텀 스타일 클래스 파일
 */

// bootstrap tooltip
.tooltip.in {
  opacity: 1;
}

.tooltip-inner {
  text-align: left;
  background-color: #3e4d5a;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #3e4d5a;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #3e4d5a;
}

.tooltip.left .tooltip-arrow {
  border-left-color: #3e4d5a;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #3e4d5a;
}

// list-inline
.list-inline {
  display: inline-block;
  margin-bottom: -5px;
  margin-left: -5px;
}

.list-inline:after {
  content: "";
  display: inline-block;
  clear: both;
}

.list-inline>li {
  float: left;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 5px;
  .list-inline>li {
    padding-right: 5px;
    padding-left: 0;
    margin-bottom: 0;
  }
}

td>.list-inline {
  margin-bottom: 0;
  li {
    margin-bottom: 0;
  }
}

// not sure needed or not
.button-block .list-inline li {
  padding: 0 5px;
}


@media (min-width:768px) {
  .modal-dialog {
    max-width: 600px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: none;
  }
}

.modal-dialog.modal-sm {
  width: 290px;
}

.modal-dialog.modal-md {
  width: 435px;
}

.modal-dialog.modal-lg {
  width: 842px;
}

.modal-header{
  display: block;
  h1 {
    margin-top: 0;
  }
}

.panel {
  margin-bottom: 20px;
}

.panel-body:before {
  display: table;
  content: " ";
}

// nav
.nav-pills .nav-link.active {
  background-color: #293846;
}

// pagination
.page-link,
.pagination li a,
.pagination-datatables li a {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #729bc0;
  background-color: transparent;
}
