/**
 * @file _bs-tooltip.scss
 * @description bs-tooltip 의 커스텀 스타일 클래스 파일 
 */

bs-tooltip-container {
    &.tooltip {
      .tooltip-inner {
        background-color: #6c6c6c;
        opacity: 0.9;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 1.2rem;
        border-radius: 4px;
      }
      .tooltip-arrow:before,
      .tooltip-arrow {
        border: none;
      }
    }
  }