/**
 * @file _file-attach.scss
 * @description 파일첨부 레이아웃 스타일 클래스 파일
 */

 .input-file {
  position: relative;
  input[type=file] {
    position: relative;
    width: 150px;
    opacity: 0;
    border: none;
    padding: 0;
    z-index: 2;
  }
  .input-file-label {
    position: absolute;
    left: 0;
    width: 150px;
    border: 1px solid #9facac;
    border-radius: 2px;
    text-indent: 28px;
    z-index: 1;
    &:after {
      content:"";
      position: absolute;
      left: 0;
      width: 23px;
      height: 23px;
      border-radius: 0;
      border-left: 0;
      border-right: 1px solid #9facac;
      background: url("/assets/images/icon/search.png") no-repeat center;
    }
  }
}

.input-file>span,
.input-file>li>span {
  display: inline-block;
  position: relative;
  height: 26px;
  line-height: 24px;
  padding-left: 16px;
  float: left;
}

.input-file>span:before,
.input-file>li>span:before {
  content: "\f0c6";
  font-family: 'FontAwesome', sans-serif;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 1.3rem;
  color: #626ecc;
}

.input-file>span a,
.input-file>li>span a {
  color: #626ecc;
}

.input-file>span em,
.input-file>li>span em {
  color: #555555;
  margin-left: 4px;
}

.input-file>span+button,
.input-file>li>span+button {
  margin-left: 15px;
}

.input-file>button {
  display: inline-block;
  float: left;
  margin-left: 5px;
}

.input-file+button {
  margin-left: 0;
}

.input-file.input-block>form {
  display: block !important;
  width: 100% !important;
}

.file-attached-list {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  padding: 7px 10px 10px 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid $ag-border-color;
  background: #fff;
  * {
    margin: 0;
    padding: 0;
  }
  table {
    border-top: none;
  }
  th {
    vertical-align: top;
  }
  tr {
    position: relative;
    &.active {
      &, td {
        background-color: #555 !important;
        color: #fff !important;
        i {
          color: #eee !important;
        }
      }
    }
    &:hover {
      background-color: #e6faf7 !important;
    }
  }
  td {
    height: 23px;
    border: 0 !important;
  }
  &-btn {
    &-add,
    &-delete,
    &-up,
    &-down {
      float: left;
      line-height: 23px;
      overflow: hidden;
      border: none;
      margin-right: 5px !important;
      height: 25px;
      font-size: 13px;
      text-align: center;
      display: inline-block;
      text-decoration: none !important;
      color: #000 !important;
      background: transparent;
      cursor: pointer;
      &:hover {
        border-radius: 3px;
        background-color: #e5e5e5;
      }
      &:before {
        display: inline-block;
        margin-top: 3px;
      }
    }
    &-add {
      padding: 0 7px 0 30px !important;
      &:before {
        position: absolute;
        top: 3px;
        left: 1px;
        line-height: 10px;
        margin: 5px 7px;
        content: "\f0c6";
        font-family: 'FontAwesome', sans-serif;
        color: #666;
        transform: rotate(315deg);
        font-size: 18px;
      }
    }
    &-delete {
      &:before {
        content: "\f1f8";
        line-height: 21px;
        font-family: 'FontAwesome', sans-serif;
        color: #666;
        font-size: 18px;
        padding: 0 7px 0 6px;
      }
    }
    &-up,
    &-down {
      margin-left: 5px !important;
      width: 23px;
      height: 25px;
      text-indent: 0 !important;
      line-height: 18px;
    }
    &-up {
      &:before {
        content: "\f106";
        font-family: 'FontAwesome', sans-serif;
        color: #666;
        font-weight: 600;
        margin-right: 1px;
      }
    }
    &-down {
      &:before {
        content: "\f107";
        font-family: 'FontAwesome', sans-serif;
        color: #666;
        font-weight: 600;
        margin-left: 2px;
      }
    }
  }
  &-size {
    margin-top: 3px;
    float: right;
    font-size: 12px;
  }
  &-header {
    position: relative;
    border-bottom: 1px #c3c3c3 dashed;
    overflow: hidden;
    height: 30px;
  }
  &-body {
    padding-top: 5px;
    margin-right: 0px;
    min-height: 50px;
    border-top: 1px #e1e1e1 dashed;
    margin-bottom: 0px;
    margin-top: 0px;
    height: 80px;
    overflow-y: auto;
    > ul > li {
      display: flex;
      padding: 0 10px;
      height: 24px;
      line-height: 24px;
      span {
        color: #38c9b2;
        vertical-align: top;
        &.file-attached-list-body-name span {
          text-decoration: underline;
        }
      }      
      &:hover {
        background-color: #e6faf7 !important;
        span {
          color: #337ab7;
          &.file-attached-list-body-icon {
            background-position-y: -25px;
          }
        }
      }

      ul {
        li {
          display: inline-block;
        }
        &.file-text{

        }
        &.file-info {
          margin: 0 0 0 auto;
        }
      }

    }
    &-name {
      text-align: left;
      padding-left: 3px !important;
      text-decoration: underline;
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &-icon {
      width: 7px;
      height: 24px;
      background: url("/assets/images/icon/file.png") no-repeat -9px 0;
    }
  }
}

.file-size {
  font-size: 11px;
  text-align: right;
  padding-right: 10px !important;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0
  }
  to {
    background-position: 0 0
  }
}

@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0
  }
  to {
    background-position: 0 0
  }
}

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0
  }
  to {
    background-position: 40px 0
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0
  }
  to {
    background-position: 0 0
  }
}

.progress {
  min-width: 90px;
  height: 13px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1)
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background-color: #428bca;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width .6s ease;
  transition: width .6s ease
}

.progress-striped .progress-bar {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px
}

.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
  -ms-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite
}

.progress-bar-success {
  background-color: #5cb85c
}

.progress-striped .progress-bar-success {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-bar-info {
  background-color: #5bc0de
}

.progress-striped .progress-bar-info {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-bar-warning {
  background-color: #f0ad4e
}

.progress-striped .progress-bar-warning {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-bar-danger {
  background-color: #d9534f
}

.progress-striped .progress-bar-danger {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.attach-file {
  display: inline-block;
  width: 15px;
  height: 18px;
  background: url("/assets/images/icon/file.png") no-repeat -5px -3px;
}

