/**
 * @file _icons.scss
 * @description 아이콘의 사용과 관련된 스타일 클래스 파일 
 */

i {
  &.signal {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border-radius: 50%;
    vertical-align: -2px;
    &.default {
      border: 1px solid #666;
      background-color: #fff;
      &.t2 {
        border: 1px solid #666;
        background-color: #fff;
        box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
      }
      &.t3 {
        border: 1px solid #666;
        background-color: #fff;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      }
    }
    &.red {
      border: 1px solid #bb0000;
      background-color: #ff3333;
      &.t2 {
        border: 1px solid #bb0000;
        background-color: #ff3333;
        box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
      }
      &.t3 {
        border: 1px solid #bb0000;
        background-color: #ff3333;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      }
      &.error {
        margin-right: 0;
        background-image: url("/assets/images/icon/close.png");
        background-repeat: no-repeat;
        background-position: center;
      }      
    }
    &.green {
      border: 1px solid #007700;
      background-color: #00b050;
      &.t2 {
        border: 1px solid #007700;
        background-color: #00b050;
        box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
      }
      &.t3 {
        border: 1px solid #007700;
        background-color: #00b050;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      }
    }
    &.white {
      border: 1px solid #ccc;
      background-color: #eee;
      &.t2 {
        border: 1px solid #ccc;
        background-color: #eee;
        box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
      }
      &.t3 {
        border: 1px solid #ccc;
        background-color: #eee;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      }
    }
    &.yellow {
      border: 1px solid #ffaa28;
      background-color: #ffc000;
      &.t2 {
        border: 1px solid #ffaf00;
        background-color: #ffc000;
        box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
      }
      &.t3 {
        border: 1px solid #ffaf00;
        background-color: #ffc000;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      }
    }
  }
  &.signalchk {
    //background-color: #00b050;
    position: relative;
    &:before {
      content: "\f00c";
      color: #00b050;
      transition:  all 0.5s;
      opacity: 1;
    }
    &:after {
      content: "\f00d";
      color: #ff3333;
      transition:  all 0.5s;
      opacity: 0;
      position: absolute;
      left: 1px;
      top: 0;
    }
    &.error {
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
    }
  }
}

.btn {
  &.ibtn {
    font-size: 1.2rem;
    padding: 5px 10px;
    &.undo {
      font-size: 1.35rem;
    }
  }
}