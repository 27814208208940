@charset "utf-8";

/**
 * @file _reset.scss
 * @description 기본엘리먼트 설정 파일
 */

* {
  margin: 0;
  padding: 0;
}

html {
  //height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 10px;
}

body {
  position: relative;
  min-height: 800px;
  font-family: "Malgun Gothic", sans-serif;
  background: $body-bg;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

progress {
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #428bca;
  &:hover {
    color: #2a6496;
  }
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

hr {
  height: 0 !important;
  width: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
}

img {
  border: none;
  vertical-align: middle;
}

input,
select,
button, a.btn,
textarea {
  color: #4d4c4c;
}

textarea {
  width: 100%;
  vertical-align: middle;
}

button {
  border: none;
  cursor: pointer;
  span {
    display: inline-block;
    text-indent: -9999px;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

iframe {
  height: 100%;
  border: none;
}

*:focus {
  outline: none !important;
}

caption {
  height: 0 !important;
  width: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
}

legend,
caption {
  display: none;
}

ol,
ul {
  margin: 0;
  list-style: none;
}

p {
  margin: 0;
}

label {
  margin: 0;
}

::-webkit-file-upload-button {
  cursor: pointer;
}

.app-body {
  font-size: 1.2rem;
}

em,
i {
  font-style: normal;
}

.nav:before {
  display: table;
  content: " ";
}

.line-break {
  white-space: pre-wrap;
}
