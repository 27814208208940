/**
 * @file _file-upload.scss
 * @description 파일 업로드시 사용되는 영역의 레이아웃 스타일 클래스 파일 
 */

.dropzone,
.dropzone-wrapper {
  display: inline-block;
  position: relative;
  float: left;
  min-width: 180px;
  min-height: 26px;
  padding: 1px 10px 1px 24px;
  border: 1px solid #a8a8a8;
  cursor: pointer;
}

.dropzone:before,
.dropzone-wrapper:before {
  content: "\f0c6";
  font-family: 'FontAwesome', sans-serif;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 7px;
  font-size: 1.3rem;
  color: #909090;
  line-height: 18px;
}

.dropzone.dropzone-box,
.dropzone-wrapper.dropzone-box {
  width: 100%;
  min-height: 48px;
}

.table-list {
  td {
    .dropzone,
    .dropzone-wrapper {
      line-height: 18px;
    }
  }
}

.dz-image,
.dz-error-message,
.dz-success-mark,
.dz-error-mark {
  display: none;
}

.dz-started .dz-message {
  display: none;
}

.dz-message {
  margin-top: 1px;
  color: #999999;
  font-size: 1.2rem;
}

.dz-preview {
  display: inline-block;
  float: left;
  position: relative;
  height: 18px;
  line-height: 15px;
  padding: 0px 24px 0px 6px;
  margin: 2px 4px 2px 0;
  border: 1px solid #b9d5d2;
  border-radius: 0;
  background-color: #edfaf8;
  font-family: "Malgun Gothic", sans-serif;
  font-size: 1.2rem;
  color: #202020;
  cursor: default;
  .dz-details {
    display: inline-block;
    float: left;
  }
  .dz-filename a {
    color: #626ecc;
  }
  .dz-size {
    display: inline-block;
    float: left;
    margin-left: 4px;
    color: #555;
    strong {
      font-weight: 100;
    }
  }
  .dz-remove {
    display: inline-block;
    position: absolute;
    top: 1px;
    right: 1px;
    height: 14px;
    :hover,
    :active,
    :focus {
      text-decoration: none;
    }
    :before {
      content: "x";
      opacity: 1;
      font-size: 2rem;
      display: inline-block;
      width: 15px;
      height: 14px;
      line-height: 10px;
      color: #747474;
      top: 0;
      left: 0;
    }
  }
  &.dz-error {
    border: 1px solid $lua-pink-500;
    background-color: #fff;
  }
}

.dropzone .dz-preview.dz-complete.dz-error:hover .dz-error-message,
.dropzone-wrapper .dz-preview.dz-complete.dz-error:hover .dz-error-message {
  display: inline-block;
  position: absolute;
  width: auto;
  top: 25px;
  left: -1px;
  padding: 6px 8px;
  border-radius: 4px;
  background-color: #3e4d5a;
  z-index: 10;
}

.dropzone .dz-preview.dz-complete.dz-error .dz-error-message:before,
.dropzone-wrapper .dz-preview.dz-complete.dz-error .dz-error-message:before {
  content: "\f0d8";
  font-family: 'FontAwesome', sans-serif;
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 10px;
  color: #3e4d5a;
  font-size: 1.8rem;
  top: -10px;
  left: 30px;
}

.dropzone .dz-preview.dz-complete .dz-error-message span,
.dropzone-wrapper .dz-preview.dz-complete .dz-error-message span {
  color: #fefefe;
  font-family: "Malgun gothic", sans-serif;
  font-size: 1.2rem;
  text-align: left;
}

.dropzone .dz-preview .dz-progress,
.dropzone-wrapper .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 2px;
  left: 0;
  top: 15px;
  margin-top: 0;
  width: 100%;
  -webkit-transform: scale(1);
  overflow: hidden;
}

.dropzone .dz-preview.dz-processing .dz-progress,
.dropzone-wrapper .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress,
.dropzone-wrapper .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}

.dropzone .dz-preview .dz-progress .dz-upload,
.dropzone-wrapper .dz-preview .dz-progress .dz-upload {
  background: #337ab7;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}