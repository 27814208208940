/**
 * @file _list-tables.scss
 * @description 그리드가 아닌 정보성 테이블의 레이아웃 스타일 클래스 파일
 */

.table-list {
  th,
  td,
  .th,
  .td {
    padding: 3px 10px;
    vertical-align: middle;
    line-height: 25px;
    border-right: 1px solid $table-th-line;
    border-bottom: 1px solid $table-line;
    font-size: 1.2rem;
  }
  th,
  .th {
    text-align: center;
    background: $idp-grid-bg;
    color: #000;
    font-weight: normal;
  }
  td,
  .td {
    background: #fff;
    color: #202020;
    height: 32px;
  }
  th:first-child,
  td:first-child,
  .th:first-child,
  .td:first-child {
    border-left: 1px solid $table-th-line;
  }
  td:last-child,
  .td:last-child {
    border-bottom: 1px solid $table-th-line;
  }  
  &.select-cell {
    tbody {
      td {
        .on,
        :focus,
        :hover {
          background: #fefbf1;
        }
      }
    }
  }
  td {
    &.th-sub {
      text-align: center;
      background-color: #f8f8f8;
    }
  }
  .txt-center input {
    text-align: center;
  }
  .txt-right input {
    text-align: right;
  }
  .txt-error {
    color: $error-color;
  }
  .row-add,
  .row-delete {
    height: 25px;
    line-height: 22px;
    padding: 0;
    margin: 0;
    text-align: center;
    button {
      width: 20px;
      height: 26px;
      padding: 0;
      margin: 0;
      background: none;
    }
  }
  .row-sum td {
    background: #fff5f5;
  }
  thead {
    th {
      .info {
        &.info-tooltip {
          margin-left: 4px;
        }
        i {
          margin-top: 4px;
        }
      }
    }
  }
}

td .table-list {
  border-top: 1px solid #cdcdcd;
}

.table-overflow {
  overflow-y: auto;
  border: 1px solid #cccccc;
  border-top: 2px solid #4d5762;
  .table-list {
    border-top: none;
    th:first-child,
    td:first-child {
      border-left: none;
    }
    th:last-child,
    td:last-child {
      border-right: none;
    }
  }
}

.table-overflow::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.table-overflow::-webkit-scrollbar-track {
  background: none;
}

.table-overflow::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #cccccc;
}

a {
  &.txt-link {
    color: #626ecc;
    &:hover {
      color: #626ecc;
      text-decoration: underline;
    }
  }
}

.list-inline li>a.txt-link {
  line-height: 24px;
}

table.table-list {
  ul {
    padding-left: 0;
  }
  td,
  th {
    a:not(.btn) {
      text-decoration: none;
      color: #337ab7;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

table.table-list {
  td, th {
    input[type=checkbox],
    input[type=radio]{
      vertical-align: middle;
    }
  }
}
