/**
 * @file _dropdown.scss
 * @description 드롭다운 메뉴 레이아웃 스타일 클래스 파일 
 */

.dropdown-item {
  position: relative;
  padding: 10px 20px;
  border-bottom: 1px solid $dropdown-border-color;

  &:last-child {
    border-bottom: 0;
  }

  i {
    display: inline-block;
    width: 20px;
    margin-right: 10px;
    margin-left: -10px;
    color: $dropdown-border-color;
    text-align: center;
  }

  .badge {
    position: absolute;
    right: 10px;
    margin-top: 2px;
  }
}

// Dropdown section headers
.dropdown-header {
  padding: 8px 20px;
  background: $dropdown-divider-bg;
  border-bottom: 1px solid $dropdown-border-color;

  .btn {
    margin-top: -7px;
    color: $dropdown-header-color;

    &:hover {
      color: $body-color;
    }

    &.pull-right {
      margin-right: -20px;
    }
  }
}

.dropdown-menu-lg {
  width: 250px;
}
.app-header {
  .navbar-nav {
    .dropdown-menu {
      position: absolute;
    }
    // Menu positioning
    //
    // Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
    // menu with the parent.
    .dropdown-menu-right {
      right: 0;
      left: auto; 
    }

    .dropdown-menu-left {
      right: auto;
      left: 0;
    }
  }
}

.dropdown {
  .btn-fav {
    width: 16px;
    height: 30px;
    background-position-x: -30px;
    background-position-y: 2px;
  }
  .btn-fav:hover {
    background-position-y: -23px;
  }
  .dropdown-popup {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccd5d5;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
    z-index: 999;
    width: 200px;
    .dropdown-popup-header {
      height: 20px;
      .close {
        width: 20px;
        height: 20px;
        opacity: 1;
        span {
          right: 0px;
          width: 10px;
          height: 10px;
          background: url("/assets/images/icon/close2.png") no-repeat;
          background-position: center center;
        }
      }
    }
    .dropdown-popup-list {
      max-height: 280px;
      overflow-x: hidden;
      overflow-y: auto;
      .no-data {
        padding: 5px 10px;
      }
      .dropdown-popup-item {
        display: flex;
        padding: 5px 10px;
        :first-child {
          flex-grow: 1;
          width: 100%;
        }
        span, label {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
        input {
          height: 18px;
        }
        .inline-buttons {
          height: 18px;
          padding-left: 4px;
          white-space: nowrap;
          button {
            width: 18px;
            height: 18px;
            background-color: #f0f5f5;
            .fa {
              font-size: 1.4rem;
              color: #0b8c8f;
            }
          }
        }
        &:hover {
          background-color: #f0f5f5;
        }
        &:not(:hover) .inline-buttons {
          display: none;
        }
      }
      .dropdown-item {
        padding: 5px 10px;
        border: none;
      }
    }
    .dropdown-popup-footer {
      border-top: 1px solid #d8dbe0;
      margin-top: 5px;
      padding: 5px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .btn {
        float: none;
        height: 25px;
        border-radius: 2px;
        .star {
          display: inline-block;
          width: 14px;
          height: 13px;
          margin: 0;
          vertical-align: -1px;
          background: url("/assets/images/icon/fav_star.png") no-repeat -31px -6px;
        }
      }
      .btn + .btn {
        margin-left: 5px;
      }
    }
  }
  &.show {
    .dropdown-popup {
      display: block;
    }
  }
}