/**
 * @file styles.scss
 * @description 전체 scss 파일을 import 하는 파일
 */

//variables scss
@import 'variables';

//utils scss
@import 'utils/utils';

//base scss
@import 'base/base';

//components scss
@import 'components/components';

//layout scss
@import 'layout/layout';

//pages scss
@import 'pages/pages';

//vendors extension scss
@import 'vendors-extension/vendors-extension';

//docs scss
@import 'docs/docs';

//shame scss
@import 'shame';
