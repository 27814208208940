/**
 * @file _ng-select.scss
 * @description ng-select 의 커스텀 스타일 클래스 파일
 */

.ng-select.select-multi-more {
  display: inline-block;
  min-width: 164px;
  .ng-select-container {
    height: 26px;
    min-height: 26px;
    border: 0;
    font-size: 1.2rem;
    overflow: visible !important;
    padding-right: 35px;
    .ng-value-container {
      height: 100%;
      padding-top: 0;
      padding-left: 0;
      .ng-placeholder {
        position: absolute;
        left: 4px;
        top: unset;
        color: #202020;
        padding-bottom: 0;
      }
      .ng-input {
        width: 100px;
        padding-bottom: 0;
      }
      .ng-value {
        margin: 2px;
        background-color: #e6ffcc;
        border-radius: 0;
        border: 1px solid #a6ff46;
        height: 20px;
        line-height: 16px;
        font-size: 1.2rem;
        max-width: 160px;
        display: flex;
        .ng-value-icon.left {
          border-right: 1px solid #a6ff46;
          color: #7fa653;
        }
        .ng-value-label {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .more-div {
        display: flex;
        padding: 5px;
        position: absolute;
        width: 100%;
        height: auto;
        max-height: 84px;
        left: 0;
        top: 29px;
        border: 1px solid #c0cccc;
        background-color: #fff;
        z-index: 5;
        overflow-y: auto;
      }
    }
    .ng-arrow-wrapper {
      position: absolute !important;
      right: 0;
      box-sizing: initial;
      width: 13px;
    }
  }
}

ng-select.ng-select {
  display: inline-block;
  min-width: 144px;
}

.ng-select .ng-select-searchable .ng-select-container:before {
  font-family: 'FontAwesome', sans-serif;
  content: "\f002";
  position: absolute;
  top: 0px;
  left: 8px;
  line-height: 25px;
  font-size: 1.3rem;
  color: #747474;
}

.ng-select.ng-select-single .ng-select-container {
  height: 26px;
}

select:focus.form-control {
  border-color: #333333;
  background-color: #fff !important;
  box-shadow: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: #ddd;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: #f2f2f2;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label {
  font-weight: normal;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border: 0;
  box-shadow: none;
}

.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border: 1px solid #a8a8a8;
  border-radius: 0;
  margin-top: -1px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 4px 6px;
  border-radius: 0 !important;
}

//ng select 화살표
.ng-select .ng-arrow-wrapper .ng-arrow {
  background:url("/assets/images/icon/arrow_down.png") no-repeat 0 0;
  width: 9px !important;
  height: 8px !important;
  border: 0;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  background:url("/assets/images/icon/arrow_up.png") no-repeat 0 0;
  width: 9px !important;
  height: 6px !important;
  border: 0;
}


.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  flex-wrap: initial;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #ededed !important;
}


.ng-select.lang {
  width: 100%;
  border: 1px solid #cccccc;
  .ng-select-container {
    height: 30px;
    min-height: 30px;
    border: none;
    .ng-value-container {
      height: 100%;
    }
  }
}
.ng-value, .ng-option {
  .lang-select {
    display: flex;
    font-size: 13px;
    font-weight: 700;
    i + span {
      margin-left: 5px;
    }
  }
}

.ng-select.select-search {
  .ng-select-container {
    padding-right: 0;
    border: none;
    background: url("/assets/images/icon/search.png") no-repeat left;
    .ng-value-container {
      height: 100%;
      padding-left: 25px;
      .ng-input {
        top: unset;
        padding-left: 25px;
        padding-right: 25px;
        input {
          height: 100% !important;
          background-color: transparent !important;
        }
      }
    }
    .ng-arrow-wrapper {
      display: none;
    }
  }
}