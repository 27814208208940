/**
 * @file _search.scss
 * @description 검색조건 area 의 레이아웃 스타일 클래스 파일
 */

 .page-search {
  margin-bottom: 20px;
  >div >.list-inline {
    li >label {
      vertical-align: top;
      line-height: 30px;
      input {
        vertical-align: middle;
      }
      span {
        margin-left: 5px;
        display: inline-block;
        //vertical-align: middle;
      }
    }
  }
  .input-group {
    display: inline-block;
    float: left;
    width: auto;
    height: 30px;
    margin: 4px 10px 4px 0;
    border-collapse: collapse;
    .input-group-addon {
      display: inline-block;
      float: left;
      width: auto;
      height: 30px;
      padding: 0;
      line-height: inherit;
      border: none;
      border-radius: 0;
      background: #f0f5f5;
      span {
        display: inline-block;
        height: 100%;
        padding: 5px 12px;
        border-right: 1px solid #f2f2f2;
        font-size: 1.2rem;
        color: $idp-navy;
        line-height: 20px;
        font-weight: $font-weight-bold;
        &.ng-clear-wrapper,
        &.ng-arrow-wrapper {
          display: block;
          height: auto;
          text-align: center;
        }
        &.ng-clear {
          font-size: 18px;
        }
        &.ng-arrow {
          padding: 0;
          border: inherit;
        }
      }
      .ng-value-label {
        border: none;
        padding: 0;
        height: auto;
      }
      select,
      select:focus,
      .ng-select-container {
        display: inline-block;
        width: auto;
        height: 30px;
        padding: 6px 37px 6px 10px;
        margin-left: 0px;
        margin-right: 0px;
        border: none;
        box-shadow: none;
        font-size: 1.2rem;
        color: $idp-navy;
        font-weight: $font-weight-bold;
        background-color: #f0f5f5;
      }
      option {
        color: #202020;
      }
      .ng-select-container {
        display: flex;
        outline: 0;
        overflow: hidden;
        position: relative;
        width: 100%;
      }
    }
    .btn-fav {
      width: 30px;
      height: 30px;
      background-position-x: -23px;
      background-position-y: 2px;
      &:hover {
        background-position-y: -23px;
      }
      &.active {
        background-color: #f0f5f5;
        background-position-y: -23px;
      }
    }    

    .input-group-form {
      display: inline-block;
      float: left;
      width: auto;
      height: 30px;
      background: #fff;
      + .btn-favorites {
        margin-left: 5px;
      }      
      .form-control {
        display: inline-block;
        width: auto;
        height: 30px;
        padding: 5px 12px;
        border: none;
        background: none;
        box-shadow: none;
        font-size: 1.2rem;
        color: #202020;
      }
      input[type="text"].form-control {
        padding: 0px 8px;
      }
      .btn-search {
        padding: 0 5px;
        vertical-align: top;
        background-color: #fff;
        height: 30px;
        border: 1px solid #a8a8a8;
        border-left:0;
      }  
      
      &.datepicker {
        &.datepicker-single input[type="text"].form-control {
          width: 100px;
        }
        input[type="text"].form-control {
          width: 175px;
        }
      }
      .list-inline {
        margin-left: 0;
        >li {
          padding: 0;
          &:first-child input[type="text"].form-control {
            border-right: none;
          }
          >p {
            height: 30px;
            line-height: 30px;
            padding: 0 8px 0 5px;
            background-color: #f8f8f8;
          }
          &:first-child .select2-selection {
            border-right: none;
          }
          &.right-border:first-child .select2-selection {
            border-right: 1px solid #a8a8a8;
          }
          +li .select2-selection {
            padding-left: 10px;
          }
          +li.multiple .select2-selection {
            padding-left: 22px;
            &:after {
              position: absolute;
              left: 0;
              top: 1px;
              height: 30px;
              border-left: 1px solid #f2f2f2;
            }
          }
          +li+li .select2-selection:before {
            display: none;
          }
          &:first-child select.form-control {
            border-right: none;
          }
        }
        .form-chain {
          height: 30px;
          line-height: 28px;
          border-top: 1px solid #a8a8a8;
          border-bottom: 1px solid #a8a8a8;
        }
      }
      select.form-control {
        height: 30px;
        padding: 5px 24px 5px 10px;
        max-width: 150px;
      }
      &.datepicker-single input[type="text"].form-control {
        width: 100px;
      }
      .input-group-btn {
        //bootstrap drop this class on v4
        display: inline-block;
        position: relative;
        font-size: 0;
        white-space: nowrap;
        width: 1%;
        vertical-align: bottom;
        >.btn.btn-icon {
          width: 30px;
          height: 30px;
          .fa-search:before {
            font-size: 1.3rem;
            color: #747474;
          }
        }
        &:last-child>.btn,
        &:last-child>.btn-group {
          z-index: 2;
          margin-left: -1px;
        }
      }
      .datepicker {
        input[type="text"].form-control {
          padding: 2px 8px 2px 28px;
          &:before {
            top: 9px;
          }
        }
      }
      .ng-select-container {
        height: 30px;
        padding: 3px 35px 3px 3px;
        .ng-value-container {
          .ng-input {
            top: 5px;
            input {
              padding: 0;
            }
          }
        }
      }
    }
    &.required {
      .input-group-addon span:before {
        content: "*";
        margin-right: 2px;
        display: inline-block;
        width: 5px;
        height: 5px;
        color: #fd195e;
        font-size: 1.3rem;
      }
      .input-group-form {
        background:  $idp-yellow;
      }
    }
    +.list-inline {
      float: left;
      margin: 4px 0 4px -5px;
    }
    &.no-scroll,
    &.no-scroll .input-group-addon,
    &.no-scroll .input-group-form,
    &.no-scroll .select2-container--default .select2-selection--multiple .select2-selection__rendered {
      height: auto;
    }
    &.no-scroll {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      .input-group-addon span {
        height: 100%;
      }
      .select2-container--default .select2-selection--multiple .select2-selection__rendered {
        overflow: visible;
      }
    }
  }
  .list-inline {
    >li {
      margin-bottom: 0;
    }
  }
  .btn {
    float: left;
    line-height: 1;
  }
  >div {
    padding: 6px 10px;
    background: $idp-gray;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .search-detail {
    display: none;
    .search-detail.collapse.show {
      display: block;
      padding: 11px 10px;
      border-top: 1px dashed #bfbfbf;
    }
  }
  &.on {
    .search-detail {
      display: block;
      padding: 11px 10px;
      border-top: 1px dashed #bfbfbf;
    }
  }
  .input-group-form {
    position: relative;
    border-left: 1px solid $idp-gray;
    .input-checkbox,
    .input-radio {
      padding: 0 12px;
      border: none;
      label {
        height: 30px;
        margin-right: 10px;
        line-height: 30px;
        color: #202020;
        font-size: 1.2rem;
        font-weight: 100;
        cursor: pointer;
        input.form-control {
          height: 14px;
          margin-top: 7px;
          margin-right: 4px;
        }
        input + span {
          height: 30px;
        }        
      }
    }
    .input-checkbox {
      &.checkonly {
        label,
        label input.form-control {
          margin-right: 0;
        }
      }
    }
  }
  .input-file {
    .dropzone,
    .dropzone-wrapper {
      min-height: 30px;
      border-left: none;
    }
    .dropzone:before,
    .dropzone-wrapper:before {
      top: 6px;
    }
    .dropzone {
      &.dz-started {
        max-width: 500px;
        border: 1px solid #a8a8a8;
        background-color: #fff;
        z-index: 10;
      }
    }
    .dz-preview {
      margin-top: 4px;
    }
  }
  .rui-file {
    input[type="text"] {
      width: 100% !important;
      height: 30px;
      box-sizing: border-box;
      border: 1px solid #a8a8a8;
      border-left: none;
    }
    .L-filebox-button {
      display: none;
    }
  }
  .dz-message {
    margin-top: 5px;
  }
  .multiple {
    input[type="text"].form-control,
    .input-checkbox,
    .input-radio {
      border-left: 1px solid #a8a8a8;
    }
  }
}

td {
  .page-search {
    margin-bottom: 0;
    >div {
      background: none;
      padding: 0;
    }
    .input-group {
      height: 26px;
      margin: 0 5px 5px 0;
      .input-group-addon {
        height: 26px;
        span {
          height: 24px;
          padding: 3px 8px;
          font-size: 1.1rem;
        }
      }
      .input-group-form {
        height: 26px;
        input[type="text"].form-control {
          height: 26px;
        }
        select.form-control {
          height: 26px;
          padding: 2px 12px 4px;
        }
        .datepicker:before {
          top: 7px;
        }
      }
    }
    .input-group-form .input-radio label {
      height: 24px;
      line-height: 22px;
      input.form-control {
        margin-top: 6px;
      }
    }
    .select2-container {
      line-height: 22px;
    }
    .select2-container--default .select2-selection--multiple {
      min-height: 26px;
    }
    .select2-rmt+.select2-container--default .select2-selection--multiple:before {
      line-height: 26px;
    }
  }
}

.modal .shuttle .page-search {
  margin-bottom: 10px;
}

.input-group-module {
  display: block;
  height: 30px;
  line-height: 26px;
  margin-right: -5px;
  .input-group-addon {
    float: left;
    width: auto;
    height: 30px;
    padding: 0 10px 0 0;
    line-height: inherit;
    border: 0;
    border-radius: 0;
    background: transparent;
    font-size: 1.2rem;
    color: #333;
    span {
      display: inline-block;
      height: 30px;
      line-height: inherit;
      &:before {
        content: "*";
        display: inline-block;
        width: 5px;
        height: 5px;
        color: #fd195e;
        font-size: 1.2rem;
      }
    }
  }
  &.required {
    .input-group-form {
      margin-right: -5px;
      background: #edfaf8;
      &.check-module {
        background: none;
      }
      .form-control {
        background: none;
      }
    }
  }
  .input-group-form {
    display: inline-block;
    float: left;
    width: auto;
    height: 30px;
    background: transparent;
    .input-radio {
      height: 30px;
      line-height: inherit;
      label {
        line-height: inherit;
      }
      input[type="radio"].form-control {
        margin-top: 3px;
      }
    }
    &.check-module .input-radio input[type="radio"].form-control {
      margin-top: 2px;
    }
    .input-radio label>input+span,
    .input-checkbox label>input+span {
      height: 30px;
    }
    select.form-control {
      height: 30px;
      padding: 2px 4px 4px;
      font-size: 1.2rem;
      vertical-align: top;
      background: transparent;
    }
  }
  .input-checkbox {
    label {
      line-height: 30px;
    }
    input[type="checkbox"].form-control {
      margin-top: 3px;
      margin-bottom: -3px;
    }
  }
  .datepicker {
    &:before {
      top: 8px;
    }
    input[type="text"].form-control {
      height: 30px;
      padding: 3px 8px 3px 28px;
    }
  }
  input[type="text"].form-control {
    height: 30px;
    padding: 5px 8px 7px;
    vertical-align: top;
  }
  .select2-container {
    vertical-align: top;
  }
  .select2-container--default {
    .select2-selection--single {
      height: 30px;
    }
  }
}

.input-group-module .select2-container--default.select2-container--focus .select2-selection.select2-selection--single,
.page-search .input-group .input-group-form .list-inline>li:first-child .select2-container--default.select2-container--focus .select2-selection.select2-selection--single {
  margin-left: 0;
}

.page-search .input-group.no-scroll .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  overflow: visible;
}