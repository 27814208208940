/**
 * @file _modal.scss
 * @description 모달팝업의 레이아웃 스타일 클래스 파일
 */

.modal {
  text-align: center;
  padding-right: 0 !important;
  &:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
  .list-check {
    margin-top: 4px;
    li {
      padding-left: 20px;
      background: url("/assets/images/icon/check.png") no-repeat 2px 8px;
    }
  }
  .loading {
    margin-top: 8px;
    .fa-spinner {
      font-size: 6rem;
    }
    i+p {
      margin-top: 10px;
    }
    +* {
      margin-top: 10px;
    }
  }
  &.fade .modal-dialog {
    transform: none;
    transition: none;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  .modal-sm {
    margin: 0 auto;
  }
}

.modal-wrapper {
  overflow-x: hidden;
}

.modal-open {
  padding-right: 0px !important;
  overflow: visible;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  /* chrome hack for bootstrap modal back scroll */
  .modal-open {
    padding-right: 0px !important;
  }
}

.modal-open .modal::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.modal-open .modal::-webkit-scrollbar-track {
  background: #808080;
}

.modal-open .modal::-webkit-scrollbar-thumb {
  background: #ddd;
}

.modal-sm {
  width: 274px;
}

.modal-md {
  width: 435px;
}

.modal-mdlg {
  width: 520px;
}

@media (max-width:1024px) {
  .modal-lg {
    width: 780px;
  }
  .modal-xl {
    width: 780px;
  }
}

@media (min-width:1025px) {
  .modal-lg {
    width: 842px;
  }
  .modal-xl {
    width: 1000px;
  }
}

/*.modal-backdrop { background: none; opacity: 0; }*/

.modal-content {
  font-size: 1.2rem;
  border-radius: 3px;
  border: 0;
  box-shadow: 0 5px 10px rgba(0,0,0,0.3);
  .modal-body {
    padding: 20px 20px 20px 25px;
  }
}

.modal-header {
  position: relative;
  padding: 15px 15px 14px;
  border-radius: 0;
  h1 {
    height:22px;
    color: #293846;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
  }
  .close {
    position: absolute;
    display: inline-block;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    background: none;
    opacity: 1;
    text-shadow: none;
    span {
      position: relative;
      display: inline-block;
      top: 1px;
      right: 0px;
      width: 12px;
      height: 12px;
      background: url("/assets/images/icon/close4.png") no-repeat;
    }
  }
}

.modal-body em {
  color: $lua-pink-500;
}

.modal-footer .btn-group .btn+.btn {
  margin-left: 10px;
}

.modal-sm {
  .modal-body {
    padding: 22px 25px;
    line-height: 24px;
    text-align: center;
  }
  .modal-footer {
    padding: 0 25px 22px;
    border-top: none;
    text-align: center;
  }
}

.modal-lg .modal-body {
  padding: 2rem;
}

.modal-default {
  .modal-header {
   color: $idp-navy;
  }
}

.modal-warning {
  .modal-content {
    border-color: #e9a254;
  }
  .modal-header {
    color: #fff;
    background-color: #f8ac59;
    border-color: #f8ac59;
  }
  em {
    color: #f8ac59;
    font-weight: bold;
  }
}

.modal-alert {
  .modal-content {
    border-color: #e9225f;
  }
  .modal-header {
    color: #fff;
    background-color: $lua-pink-500;
    border-color: $lua-pink-500;
  }
}

.loading {
  color: #337ab7;
  .fa-spinner {
    margin-right: 4px;
    font-size: 1.3rem;
  }
}

.modal-profile {
  width: 344px;
  .modal-body {
    padding: 20px;
  }
}

.profile-main {
  position: relative;
  min-height: 65px;
  padding-top: 4px;
  padding-left: 66px;
  img {
    position: absolute;
    top: 0;
    left: 0;
  }
  p {
    line-height: 22px;
    font-size: 1.2rem;
    color: #202020;
  }
  b {
    font-size: 1.6rem;
    margin-right: 5px;
  }
}

.profile-contact {
  padding-top: 12px;
  border-top: 1px solid #dbdbdb;
  li {
    position: relative;
    min-height: 20px;
    padding-left: 30px;
    +li {
      margin-top: 6px;
    }
    i {
      position: absolute;
      top: 2px;
      left: 0;
      width: 25px;
      height: 20px;
      text-align: center;
      font-size: 1.4rem;
    }
    a {
      font-size: 1.2rem;
      color: #337ab7;
    }
  }
  span {
    margin-left: 0;
    font-size: 1.2rem;
    color: #202020;
  }
}

//사진첨부 팝업
.photo-modify {
  display: flex;
  .file {
    width: 120px;
    height: 172px;
    background: $idp-gray;
    border-radius: 4px;
    overflow: hidden;
    span {
      display: block;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center 10%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .text {
    flex: 1;
    position: relative;
    margin-left: 20px;
    .info-text {
      > li {
        position: relative;
        padding-left: 16px;
        font-size: 1.2rem;
        &:before {
          content: "";
          display: inline-block;
          position: absolute;
          top: 3px;
          left: 0;
          width: 12px;
          height: 12px;
          background: url("/assets/images/icon/alert.png") no-repeat -6px -30px;
          + li {
            margin-top: 2px;
          }
        }
      }
    }
    .custom-file {
      margin-top: 15px;
      .custom-file-input {
        height: 25px;
      }
      .custom-file-label {
        border: 1px solid $border-color;
        border-radius: 2px;
        text-indent: 26px;
        &:after {
          content:"";
          right: inherit;
          left: 0;
          width: 25px;
          height: 25px;
          border-radius: 0;
          border-left: 0;
          border-right: 1px solid $border-color;
          background: url("/assets/images/icon/search.png") no-repeat center;
        }
      }
    }
    .page-btn {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.inner-navigation-contents {
  display: flex;
  flex-direction: column;   
  height: 100%;
  .inner-contents {
    flex: 1;
  }
}

