/**
 * @file _variables.scss
 * @description Core LUA Variables
 */

 // Bootstrap color variables override

 $white:                           #ffffff;
 $gray-100:                        #fafafa;
 $gray-200:                        #e5e5e5;
 $gray-300:                        #d8dde3;
 $gray-350:                        #cccccc;
 $gray-400:                        #c4c9cf;
 $gray-440:                        #999999;
 $gray-500:                        #aaaaaa;
 $gray-550:                        #757575;
 $gray-600:                        #666666;
 $gray-650:                        #555555;
 $gray-700:                        #31383e;
 $gray-800:                        #333333;
 $gray-900:                        #202020;
 $black:                           #000000 !default;
 $blue:                            #729bc0;
 $pink:                            #f4516c !default;
 $red:                             #f4516c;
 $light-teal:                      #7ececd !default;
 $teal:                            #20c997 !default;
 $cyan:                            #729bc0;
 $colors: (
  blue:                           $blue,
  pink:                           $pink,
  red:                            $red,
  light-teal:                     $light-teal,
  cyan:                           $cyan,
  white:                          $white,
  gray:                           $gray-600,
  dark-gray:                      $gray-800
);

$theme-colors: (
  primary:                        $blue,
  secondary:                      $gray-500,
  info:                           $cyan,
  danger:                         $red,
  light:                          $gray-100,
  dark:                           $gray-800,
  brand:                          $pink,
  blue:                           $blue,
  pink:                           $pink,
  red:                            $red,
  light-teal:                     $light-teal,
  cyan:                           $cyan,
  gray-100:                       $gray-100,
  gray-300:                       $gray-300,
  gray-200:                       $gray-200,
  gray-400:                       $gray-400,
  gray-500:                       $gray-500,
  gray-600:                       $gray-600,
  gray-700:                       $gray-700,
  gray-800:                       $gray-800,
  gray-900:                       $gray-900,
  dark-gray:                      $gray-800
);

// Font
$font-size-base:                  1rem;
//$font-family-base:                "Malgun Gothic", "맑은고딕", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-base:                "Malgun Gothic", "맑은고딕", Arial, sans-serif;
$text-muted:                      $gray-200 !default;
$line-height-base:                1.5 !default;
$font-weight-normal:              400 !default;
$font-weight-bold:                700 !default;

// Options
$enable-rounded:                  false;
$enable-transitions:              true !default;

// Body
$body-color:                      #000;
$body-bg:                         #f7fafa;
$body-secondary-bg:               #f2f3f8;

// Dropdowns
$dropdown-border-color:           $gray-300;
$dropdown-border-light-color:     $gray-200;
$dropdown-divider-bg:             $gray-100;
$dropdown-header-color:           $gray-600 !default;

// Buttons
$btn-padding-x:                   0.75rem !default;
$input-btn-padding-x:             .75rem !default;
$btn-transition:                  color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// input
$input-border-color:              #9facac;
$input-border-color-active:       #23475c;
$input-border-color-error:        #f92465;

//badges
$badge-pill-border-radius:        10rem !default;

//tables
$table-caption-color:             $text-muted !default;
$table-caption-color:             $text-muted !default;
$table-line:                      #d5e0e0;
$table-th-line:                   #c7d2d2;

//tab style
$tab-bg:                          #edf3f3;
$tab-border-color:                #d5e1e1;

//spacing
$spacer: 1rem !default;

//grid
$grid-gutter-width:               30px !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

//z-index
$zindex-sticky:                   1020 !default;

//breadcrums
$breadcrumb-padding-y:            .75rem !default;

//components
$border-width:                    1px !default;

// bootstrap end


//// LUA default color
$lua-light-blue:                      #c4dbf0;
$lua-red:                             #c20035;
$lua-pink:                            #f92465;
$lua-pink-100:                        #fff3f2;
$lua-pink-200:                        #f3dfde;
$lua-pink-500:                        #f92465;
$lua-pink-700:                        #f4516c;
$lua-cyan-100:                        #e0eaf2;
$lua-cyan-200:                        #e0f2f1;

//IDP default color
$idp-green:                           #38c9b2;
$idp-gray:                            #dae6e6;
$idp-yellow:                          #feffeb;
$idp-navy:                            #2f607d;
$idp-grid-bg:                         #e9f0f0;

// sign color
$success-color:                       #293846;
$info-color:                          #17a2b8;
$error-color:                         #f92465;
$warning-color:                       #ffc107;
$confirm-color:                       #28a745;

//brand-color
$brand-color:                         $idp-navy;
$brand-black-color:                   $gray-700;
$contents-title-color:                $gray-600;
$form-label:                          $gray-600;
$enable-sidebar-nav-rounded:          false !default;

$border-color:                        $gray-200 !default;
$border-secondary-color:              $gray-200 !default;
$border-darken-color:                 $gray-350 !default;
$layout-transition-speed:             .25s !default;

// Navbar
$navbar-height:                       90px !default;
$navbar-height-top:                   40px !default;
$navbar-height-bottom:                $navbar-height - $navbar-height-top !default;
$navbar-bg:                           #fff !default;
$navbar-border: (
  bottom: (
    size:                             1px,
    style:                            solid
  )
)  !default;
$navbar-color:                        #f2f2f2;
$navbar-brand-bg:                     #3f4146 !default;
$navbar-brand-logo:                   url("/assets/images/logo.png") !default;
$navbar-brand-border: none !default;

$navbar-brand-minimized-width:        50px !default;
$navbar-brand-minimized-bg:           $navbar-brand-bg !default;
$navbar-brand-minimized-logo:         url("/assets/images/logo.png") !default;
$navbar-brand-minimized-logo-size:    24px !default;
$navbar-brand-minimized-border:       $navbar-brand-border !default;

$navbar-color:                        $gray-600 !default;
$navbar-hover-color:                  $brand-color !default;
$navbar-active-color:                 $gray-700 !default;

$navbar-info-width:                   200px;

// Sidebar

$sidebar-width:                       199px !default;
$sidebar-padding:                     0 !default;
$sidebar-minimized-width:             50px !default;
$sidebar-minimized-height:            $sidebar-minimized-width !default;
$sidebar-compact-width:               150px !default;
$sidebar-compact-height:              $sidebar-compact-width !default;
$sidebar-color:                       $gray-900 !default;
$sidebar-bg:                          #ebf0f0 !default;
$sidebar-borders: (
  right: (
    size:                             1px,
    style:                            solid,
    color:                            #d6e2e2
  )
) !default;

$mobile-sidebar-width:                199px !default;

// Sidebar Header

$sidebar-header-height:               auto !default;
$sidebar-header-bg:                   inherit; //rgba(0,0,0,.2) !default;
$sidebar-header-padding-y:            .85rem !default;
$sidebar-header-padding-x:            .65rem !default;

// Sidebar Form

$sidebar-form-border:                 0 !default;
$sidebar-form-bg:                     #fff !default;
$sidebar-form-color:                  #74757e !default;
$sidebar-form-placeholder-color:      #999 !default;

// Sidebar Navigation

$sidebar-nav-color:                   $gray-900 !default;
$sidebar-nav-title-padding-y:         .75rem !default;
$sidebar-nav-title-padding-x:         1rem !default;
$sidebar-nav-title-color:             $gray-440 !default;
$sidebar-nav-link-padding-y:          .9rem !default;
$sidebar-nav-link-padding-x:          1rem !default;
$sidebar-nav-link-color:              $gray-900 !default;
$sidebar-nav-link-bg:                 transparent !default;
$sidebar-nav-link-icon-color:         $gray-500 !default;
$sidebar-nav-link-borders:            0 !default;

$sidebar-nav-link-hover-color:        $gray-900 !default;
$sidebar-nav-link-hover-bg:           $white !default;
$sidebar-nav-link-hover-icon-color:   $white !default;
$sidebar-nav-link-hover-borders:      0 !default;

$sidebar-nav-link-active-color:       $white !default;
$sidebar-nav-link-active-border-color:$lua-pink-700 !default;
$sidebar-nav-link-active-bg:          #2e5a73 !default;
$sidebar-nav-link-active-icon-color:  $white !default;
$sidebar-nav-link-active-borders:     0 !default;

$sidebar-nav-dropdown-color:          $gray-650 !default;
$sidebar-nav-dropdown-bg:             transparent !default;
$sidebar-nav-dropdown-borders:        0 !default;
$sidebar-nav-dropdown-indicator-color:$gray-600 !default;
$sidebar-nav-dropdown-indicator:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
$sidebar-nav-dropdown-indicator-hover-color:$gray-600;
$sidebar-nav-dropdown-indicator-active-color:$white;
$sidebar-nav-dropdown-indicator-hover:str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-hover-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Sidebar Footer

$sidebar-footer-height:               auto !default;
$sidebar-footer-bg:                   $sidebar-bg !default;
$sidebar-footer-padding-y:            1rem !default;
$sidebar-footer-padding-x:            1rem !default;
$sidebar-footer-borders:(
  top: (
    size:                             1px,
    style:                            solid,
    color:                            #d6e2e2
  )
)!default;

// Sidebar Minimizer

$sidebar-minimizer-height:            50px !default;
$sidebar-minimizer-bg:                $sidebar-bg !default;
$sidebar-minimizer-border-color:      darken($sidebar-bg, 15%) !default;
$sidebar-minimizer-borders:(
  top: (
    size:                             1px,
    style:                            solid,
    color:                            $sidebar-minimizer-border-color
  )
) !default;
$sidebar-minimizer-indicator-color:   $gray-100 !default;
$sidebar-minimizer-indicator:         str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
$sidebar-minimizer-hover-bg:          darken($sidebar-bg, 6%) !default;
$sidebar-minimizer-hover-indicator-color:$sidebar-nav-link-hover-color !default;
$sidebar-minimizer-hover-indicator:   str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-hover-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Top Navigation

$top-nav-bg:                          #fff !default;
$top-nav-color:                       $body-color !default;
$top-nav-borders: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;
$top-nav-ul-borders: (
  all: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

$top-nav-hover-color:                 $white !default;
$top-nav-hover-bg:                    theme-color("primary") !default;
$top-nav-active-color:                $white !default;
$top-nav-active-bg:                   theme-color("primary") !default;
$top-nav-height:                      $navbar-height - 15px !default;

// Content Header

$content-header-color:                $black !default;
$content-header-border-color:         $gray-300;
$content-header-borders: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $content-header-border-color
  )
) !default;

// Breadcrumb

$breadcrumb-border-color:             $gray-300;
$breadcrumb-borders: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $breadcrumb-border-color
  )
) !default;

// Aside

$aside-menu-width:                    250px !default;
$aside-menu-color:                    $gray-800 !default;
$aside-menu-bg:                       #fff !default;
$aside-menu-borders: (
  left: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

$aside-menu-nav-padding-y:            0 !default;
$aside-menu-nav-padding-x:            1rem !default;

// Footer

$footer-height:                       50px !default;
$footer-bg:                           $gray-100 !default;
$footer-color:                        $body-color !default;
$footer-borders: (
  top: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;


// ag-grid overrides (from ag-theme-balham)

$ag-default-font-family :  "Malgun Gothic", "맑은고딕";
$ag-border-top-color :     #c7d2d2;
$ag-border-color :         #c7d2d2;
$ag-border-error :         $error-color;
$ag-header-bg :            #e9f0f0;
$ag-header-height :        32px !default;
$ag-row-height :           32px !default;
//$ag-line-height :        30px !default;
$ag-focus-border-color :   #a8a8a8;
$ag-row-selected-bg :      rgba(230, 250, 247, 1);
$ag-row-hover-bg :         #feffeb;

/// bs-datepicker theme

$main-bg:                  #fff !default;
$main-border-color:        #a8a8a8 !default;
$custom-range-bg:          #eee !default;

$main-box-shadow:          #aaa !default;

$font-color-01:            #202020 !default;
$font-color-02:            #747474 !default;
$border-color:             #e9edf0 !default;

$highlighted:              #e6ffcc !default;
$font-color-highlighted:   #333 !default;
$border-color-highlighted: #a6ff46 !default;
$border-color-hover:       #b4b4b4 !default;

$btn-color:                #747474 !default;
$btn-color-hover:          $idp-green !default;
$btn-bg:                   #e9edf0 !default;
$btn-bg-hover:             #d5dadd !default;

$btn-bg2:                  #9aaec1 !default;
$btn-bg2-hover:            #54708b !default;

$disabled-background:      rgba(221, 221, 221, 0.3) !default;
$disabled-color:           #f5f5f5 !default;


//page header
$page-header-height:        64px;