/**
 * @file _page.scss
 * @description 메인 컨텐츠 페이지 레이아웃 스타일 클래스 파일 
 */

.page-content {
  //padding: 20px 30px;
  position: relative;
  padding-bottom:20px;
}

.page-content-middle {
  width: 994px;
  margin: 0 auto;
}

.popup-window .page-content {
  padding: 20px;
}