/**
 * @file _breadcrumb.scss
 * @description breadcrumb 메뉴 관련 파일 
 */

.lnb-folded .breadcrumbs-nav {
  display: block;
}

.breadcrumbs-nav {
    position: absolute;
    right: 20px;
    bottom: -28px;
  &.off {
    display: none;
  }
  >ul {
    >li {
      float: left;
      span {
        display: inline-block;
        float: left;
        padding-left: 8px;
        height: 24px;
        line-height: 22px;
        font-size: 1.1rem;
        color: #202020;
      }
      i {
        float: left;
        height: 24px;
        line-height: 26px;
        margin-left: 8px;
        &:before {
          font-size: 1.3rem;
          color: #555;
        }
      }
    }
    ul {
      display: none;
      position: absolute;
      top: 33px;
      padding: 10px 0;
      border: 1px solid #404040;
      background: #fff;
      box-shadow: 0 1px 3px #7d7d7d;
      li {
        &:hover {
          background: #f2f2f2;
        }
        a {
          display: block;
          height: 30px;
          line-height: 30px;
          padding: 0 14px;
          span {
            color: #555;
            font-size: 1.2rem;
            white-space: nowrap;
          }
        }
        &.current {
          a {
            span {
              color: #fd195e;
              font-weight: 600;
            }
          }
        }
      }
    }
    .on {
      ul {
        display: block;
      }
    }
  }
}

//
// from coreUI
//

.breadcrumb {
  position: absolute;
  right: 8px;
  top: 13px;
  padding: 0;
  margin: 0;
  background: none;

  .breadcrumb-item {
    a {
      color: $black;
    }

    span {
      color: $black;
      font-size: 1.2rem;
    }
  }

  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0.25rem !important;
    padding-left: 0.5rem;
  }
}

.breadcrumb-menu {
  margin-left: auto;
  padding-right: .5rem;

  &::before {
    display: none;
  }

  .btn-group {
    vertical-align: top;
  }

  .btn {
    padding: 0 $input-btn-padding-x;
    color: $text-muted;
    vertical-align: top;
    border: 0;
    &:hover, &.active {
      color: $body-color;
      background: transparent;
    }
    i {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }
  }

  .open {
    .btn {
      color: $body-color;
      background: transparent;
    }
  }

  .dropdown-menu {
    min-width: 180px;
    line-height: $line-height-base;
  }
}