/**
 * @file _bs-datepicker.scss
 * @description bs-datepicker 의 커스텀 스타일 클래스 파일 
 */
div {
  .bs-datepicker-body {
    table{
      th{
        padding: 3px 5px;
      }
    }
    table.days {
      tr {
        td {
          &:first-child {
            color: $lua-pink-500;
            .is-other-month {
              color: #f9aac2;
            }
          }
          span {
            line-height: 21px;
            width: 28px;
            font-size: 12px;
          }
        }
      }
    }
  }
  .bs-datepicker-head {
    padding: 5px 8px 8px;
    button {
      &.current {
        font-size: 16px;
        font-weight: 700;
      }
      &.previous{
        float: left;
      }
      &.next{
        float: right;
      }
      &:after{
        clear: both;
      }
    }
  }
}

.bs-datepicker {

  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: $main-bg;
  position: relative;
  z-index: 1;
  margin-top: -1px;
  border-radius: 0;
  box-shadow: none;
  padding: 10px;
  border: 1px solid $main-border-color;

  button {
    span {
      text-indent: 0;
    }
  }

  &:after {
    clear: both;
    content: '';
    display: block;
  }

  bs-day-picker {
    float: left;

  }

  /* button */

  button:hover,
  button:focus,
  button:active,
  input:hover,
  input:focus,
  input:active,
  &-btns button:hover,
  &-btns button:focus,
  &-btns button:active,
  &-predefined-btns button:active,
  &-predefined-btns button:focus {
    outline: none;
  }

  /* .bs-datepicker-head */
  &-head {
    min-width: 210px;
    height: 38px;
    border-radius: 0;
    text-align: center; 
    padding: 5px 0 8px;

    &:after {
      content: "";
      display: inline-block;
      vertical-align: top;
      width: 100%;
    }

    /* .bs-datepicker-head button */
    button {
      display: inline-block;
      vertical-align: top;
      padding: 0;
      height: 22px;
      line-height: 21px;
      border: 0;
      background: transparent;
      text-align: center;
      cursor: pointer;
      color: $font-color-01;

      &[disabled],
      &[disabled]:hover,
      &[disabled]:active {
        background: $disabled-background;
        color: $disabled-color;
        cursor: not-allowed;
      }

      &.next,
      &.previous {
        color: $btn-color;
        width: 22px;
        border: 1px solid transparent;
        border-radius: 2px;
        position: relative;

        span {
          display: none;
        }

        &:before {
          position: absolute;
          top: 5px;
          font: normal 900 normal 14px/1 FontAwesome, sans-serif;
          color: #202020;
          font-size: 1.2rem;
        }
      }

      &.previous:before {
        content: "\f0d9";
        left: 7px;
      }

      &.next:before {
        content: "\f0da";
        left: 8px;
      }

      &.current {
        border-radius: 15px;
        max-width: 155px;
        padding: 0;
        font-size: 1.4rem;
        font-weight: 700;
      }
    }
  }

  &-head {
    button {
      &:hover, &:active {
        color: $btn-color-hover;
        &:before {
          color: $btn-color-hover;
        }
        &.next,
        &.previous {
          border-color: #eaeaea;
        }
      }
    }
  }

  /* .bs-datepicker-body */
  &-body {
    padding: 0;
    border-radius: 0;
    min-height: 158px;
    min-width: 210px;
    border: 0;

    .days.weeks {
      position: relative;
      z-index: 1;
    }

    /* .bs-datepicker-body table */
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      border-top: none !important;

      th {
        font-size: 1.2rem;
        color: $font-color-02;
        font-weight: 700;
        text-align: center;
      }

      td {
        color: $font-color-01;
        text-align: center;
        position: relative;
        padding: 0;
        border-bottom: 0;
        span {
          display: block;
          margin: 0 auto;
          font-size: 13px;
          border-radius: 4px;
          border: 1px solid transparent;
          position: relative;
          /*z-index: 1;*/
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }

        &:not(.disabled) span {
          cursor: pointer;
        }

        &.is-highlighted:not(.disabled):not(.selected) span,
        span.is-highlighted:not(.disabled):not(.selected) {
          //border-color: $border-color-hover;
          background: #f0f5f5;
          transition: 0s;
        }

        span.disabled,
        &.disabled span {
          color: $font-color-02;
        }

        span.selected,
        &.selected span {
          color: $font-color-highlighted;
          background-color: $highlighted;
          border-color: $border-color-highlighted;
          font-weight: $font-weight-bold;
        }

        &.active {
          position: relative;

          &.select-start:before {
            left: 35%;
          }

          &.select-end:before {
            left: -85%;
          }
        }

        // 선택영역
        span.active.select-start:after,
        span.active.select-end:after,
        &.active.select-start span:after,
        &.active.select-end span:after {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 100%;
          transition: 0.3s;
          top: 0;
        }

        &:before,
        span:before {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          top: 0;
          bottom: 0;
          left: 0;
          right: -2px;
          box-sizing: content-box;
          background: transparent;
        }

        &.active.select-start + td.active:before {
          left: -20%;
        }

        &:last-child.active:before {
          border-radius: 0 3px 3px 0;
          width: 125%;
          left: -25%;
        }

        span[class*="select-"],
        &[class*="select-"] span {
          color: $font-color-highlighted;

          &.select-start,
          &.select-start span {
            border-radius: 4px 0 0 4px;
          }
          &.select-end,
          &.select-end span {
            border-radius: 0 4px 4px 0;
          }
        }
      }

      /* .bs-datepicker-body table.days */
      &.days {
        td,
        span {
          &.active:not(.select-start):before,
          &.in-range:not(.select-start):before {
            background: #e6ffcc;
            border-color: transparent;
          }
        }

        span {
          width: 28px;
          height: 23px;
          line-height: 21px;

          &.select-start {
            z-index: 2;
          }
          &.is-highlighted.in-range:before,
          &.in-range.select-end:before {
            background: none;
            right: 0;
            left: 0;
          }

          &.in-range {
            border-radius: 0;
          }
        }

        td {
          &.select-start + td.select-end:before,
          &.select-start + td.is-highlighted:before,
          &.active + td.is-highlighted:before,
          &.active + td.select-end:before,
          &.in-range + td.is-highlighted:before,
          &.in-range + td.select-end:before {
            background: $highlighted;
            width: 100%;
          }
        }
      }

      /* .bs-datepicker-body table.weeks */
      &.weeks {
        tr {
          td {
            &:nth-child(2).active:before {
              border-radius: 3px 0 0 3px;
              left: 0;
              width: 100%;
            }
          }
        }
      }

      &:not(.weeks) {
        tr {
          td{
            &:first-child:before {
              border-radius: 3px 0 0 3px;
            }
          }
        }
      }

      &.years {
        td {
          height: 39px;
          padding: 0 3px;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          span {
            padding: 6px;
            border-color: #36b3af;
            border-radius: 1px;
            background: #ebf6f6;
          }

          &.is-highlighted:not(.disabled):not(.selected) span,
          span.is-highlighted:not(.disabled):not(.selected) {
            background: #e6ffcc;
            border-color: #a6ff46;
            color: #fff;
          }
        }
      }

      &.months {
        td {
          height: 39px;
          padding: 0 3px;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          span {
            padding: 6px;
            border-color: #36b3af;
            border-radius: 1px;
            background: #ebf6f6;
          }

          &.is-highlighted:not(.disabled):not(.selected) span,
          span.is-highlighted:not(.disabled):not(.selected) {
            background: #36b3af;
            border-color: #36b3af;
            color: #fff;
          }
        }
      }
    }
  }

  /* .current-timedate */
  .current-timedate {
    color: $font-color-01;
    font-size: 15px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    border-radius: 20px;
    border: 1px solid $border-color;
    margin-bottom: 10px;
    cursor: pointer;
    text-transform: uppercase;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    span:not(:empty):before {
      content: "";
      width: 15px;
      height: 16px;
      display: inline-block;
      margin-right: 4px;
      vertical-align: text-bottom;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
    }
  }

  /* .bs-datepicker-multiple */
  &-multiple {
    display: inline-block;
    border-radius: 4px 0 0 4px;

    & + & {
      margin-left: 15px;
    }

    .bs-datepicker {
      box-shadow: none;
      position: relative;

      &:not(:last-child) {
        padding-right: 10px;
      }

      & + .bs-datepicker:after {
        content: "";
        display: block;
        width: 14px;
        height: 10px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
        position: absolute;
        top: 25px;
        left: -8px;
      }

      .left {
        float: left;
      }

      .right {
        float: right;
      }
    }
  }

  /* .bs-datepicker-btns */
  &-container {
    padding: 0;
  }

  /*.bs-datepicker-custom-range */
  &-custom-range {
    padding: 15px;
    background: $custom-range-bg;
  }

  /* .bs-datepicker-predefined-btns */
  &-predefined-btns {
    button {
      width: 100%;
      display: block;
      height: 30px;
      background-color: $btn-bg2;
      border-radius: 4px;
      color: $font-color-01;
      border: 0;
      margin-bottom: 10px;
      padding: 0 18px;
      text-align: left;
      transition: 0.3s;

      &:active,
      &:hover {
        background-color: $btn-bg2-hover;
      }
    }
  }

  /* .is-other-month */
  .is-other-month {
    color: rgba(0, 0, 0, 0.25);
  }

  /* .bs-datepicker-buttons */
  &-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    padding-top: 10px;
    border-top: 1px solid $border-color;

    .btn-default {
      margin-left: 10px;
    }
  }
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080;
}

/* screen size < 1024px */
@media (max-width: 768px) {
  bs-datepicker-container,
  bs-daterangepicker-container {
    position: fixed !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
  }

  .bs-datepicker {
    &-multiple {
      display: flex;

      & + & {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }
}

.appnt-hday {
  color : #00927f;
  font-weight: bold;
} 

.legal-hday {
  color : #b9c509;
  font-weight: bold;
}

bs-datepicker-inline {
  .bs-datepicker-body {
    width : 380px;
    tr {
      height: 30px;
    }
  }
}