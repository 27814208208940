/**
 * @file _messages.scss
 * @description 화면에서 보여지는 안내, 주의, 오류등의 메세지 스타일 클래스 파일
 */

.info {
  position: relative;
  display: inline-block;
  opacity: 1;
  &:after {
    content: "";
    display: inline-block;
    clear: both;
  }
  i {
    float: left;
    line-height: 18px;
    font-size: 1.5rem;
    color: #337ab7;
    &.fa-exclamation-triangle {
      color: #f8ac59;
    }
    &.fa-exclamation-circle {
      color: $lua-pink-500;
    }
  }
  &.grid {
    line-height: 1;
  }
}

//삭제예정
.info-text {
  p {
    float: left;
    position: relative;
    margin: 0;
    line-height: 16px;
    color: #337ab7;
    em {
      color: $lua-pink-500;
    }
  }
  &.info-multiLine {
    height: auto !important;
    p {
      height: auto !important;
      line-height: normal !important;
    }
  }
  i {
    +p {
      margin-left: 5px;
    }
    &.fa-exclamation-triangle+p {
      color: #f8ac59;
    }
    &.fa-exclamation-circle+p {
      color: $lua-pink-500;
    }
  }
}

//info-tooltip
.info-tooltip {
  cursor: pointer;
  p {
    display: none;
  }
  &:hover {
    p {
      display: inline-block;
      position: absolute;
      min-width: 200px;
      top: -5px;
      left: 22px;
      padding: 4px 10px 6px;
      border-radius: 4px;
      background-color: #3e4d5a;
      color: #fefefe;
      font-family: "Malgun gothic", sans-serif;
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-align: left;
      z-index: 1000;
      &:before {
        content: "\f0d9";
        font-family: 'FontAwesome', sans-serif;
        display: inline-block;
        position: absolute;
        width: 10px;
        height: 10px;
        color: #3e4d5a;
        font-size: 1.8rem;
        top: 4px;
        left: -6px;
      }
    }
  }
}
ul {
  &.input-radio {
    >li {
      .info-tooltip {
        float: left;
        height: 26px;
        margin-left: 0;
        i {
          height: 26px;
          line-height: 26px;
        }
        &:hover {
          p {
            top: -4px;
            &:before {
              top: 5px;
            }
          }
        }
      }
    }
  }
}

h3 {
  +.list-inline.pull-left {
    margin-left: 10px;
  }
  +.info {
    top: 0px;
  }
}

*+.info {
  margin-left: 8px;
}

.module-header {
  .list-inline {
    li {
      >p,
      >.input-radio label>input+span,
      >.input-checkbox label>input+span {
        height: 28px;
        line-height: 28px;
      }
      >.input-radio label>input,
      >.input-checkbox label>input {
        height: 24px;
        margin-top: 2px;
      }
    }
  }
}

.module-header,
.module-footer {
  .info {
    i {
      height: 28px;
      line-height: 30px;
    }
  }
  .info-tooltip:hover p {
    top: -7px;
  }
}

td {
  .list-inline li {
    >.info-tooltip i {
      height: 26px;
      line-height: 26px;
    }
    .info {
      height: 26px;
      i {
        height: 26px;
        line-height: 26px;
      }
      &.info-text p {
        height: 26px;
        line-height: 24px;
      }
    }
  }
  .info-text i {
    top: 2px;
  }
}

.table-detail th .info {
  .info-tooltip {
    margin-left: 0px;
  }
  i {
    margin-top: 2px;
  }
}

// panel
.panel {
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid transparent;
  +table {
    margin-top: 20px;
  }
  &.no-pd .panel-body {
    padding: 0;
    line-height: 20px;
  }
  .table-noLine {
    margin-left: 18px;
    th,
    td {
      font-weight: 100;
      font-size: 12px;
      color: #555555;
      line-height: 20px;
    }
  }

  a:hover {
    text-decoration: underline;
  }
}

.panel-heading {
  //padding: 8px 15px;
  border-color: #eaeaea;
  font-weight: bold;
  font-size: 12px;
  border-radius: 0;
}

.panel-body {
  padding: 12px 20px;
  line-height: 24px;
  em {
    color: $lua-pink-500;
  }
  a {
    color: #337ab7;
  }
  li>p {
    height: auto;
  }
  p {
    .btn-inline {
      margin-left: 10px;
    }
    .info {
      i {
        line-height: 22px;
      }
      p {
        line-height: 20px;
      }
    }
    &.co {
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
    }
  }
  .info {
    i {
      line-height: 22px;
    }
    p {
      line-height: 20px;
    }
  }
}

.panel-info {
  border: 1px solid #eaeaea;
  .panel-heading {
    color: #000000;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
  }
}

.panel-warning {
  border-color: #fde3c7;
  .panel-heading {
    color: #000000;
    background-color: #feeede;
    border-color: #feeede;
  }
}

.panel-default {
  background-color: #e9f0f0;
  border: 1px solid #dae6e6;
  .panel-heading {
    padding: 8px 10px 2px;
    border: none;
    +.panel-body {
      padding-top: 2px;
    }
  }
  .panel-body {
    padding: 10px;
    line-height: 20px;
  }
}

.table-noLine {
  border-top: none;
}

.btn-block+.panel {
  margin-top: 20px;
}

.module-wrapper+.panel {
  margin-top: 20px;
}

p+.panel {
  margin-top: 20px;
}

table+.panel,
.shuttle-list+.panel {
  margin-top: 20px;
}

td {
  div+.panel {
    margin-top: 10px;
  }
  .panel {
    margin-bottom: 0;
  }
}

//지시문, 공지
.panel-notice {
  padding: 10px 15px;
  background: $idp-grid-bg;
  border: 1px solid $idp-gray;
  .notice-list {
    li {
      line-height: 1.5rem;
      color: #545454;
      &:not(.normal) {
        padding-left: 18px;
        position: relative;
      }
      &:not(.normal):before {
        content:"";
        position: absolute;
        left: 0;
        top: 1px;
        display: block;
        width: 12px;
        height: 12px;
      }
      strong {
        display: block;
      }
      span {
        color: $lua-pink-500;
      }
      + li {
        margin-top: 8px;
      }
      &.caution:before {
        background: url("/assets/images/icon/bullet.png") -24px -4px;
      }
      &.warning:before {
        background: url("/assets/images/icon/bullet.png") -4px -4px;
      }
      &.info:before {
        background: url("/assets/images/icon/bullet.png") -44px -4px;
      }
    }
  }
}
//새로운 info-text
p.info-text {
  padding-left: 18px;
  position: relative;
  line-height: 1.5rem;
  color: #545454;
  i {
    display: block;
    position: absolute;
    left:0;
    top:1px;
    width: 12px;
    height: 12px;
    background: url("/assets/images/icon/bullet.png") no-repeat -44px -4px;
  }
  span {
    color: $lua-pink-500;
  }
  &.caution {
    color: #f8ac59;
    i {
      background-position-x: -24px
    }
  }
  &.warning {
    color: $lua-pink-500;
    i {
      background-position-x: -4px
    }
  }
  + .info-text {
    margin-top: 8px;
  }
}
