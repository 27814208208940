/**
 * @block-table.scss
 * @블록 설정 테이블 관련 파일 
 */

.week-schedule {
  display: inline-block;
  div {
  }
  &-time,
  &-day-cell {
    text-align: center;
    div {
      float: left;
      width: 46px;
      height:31px;
      line-height:31px;
    }
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
  &-time {
    border: 1px solid #c7d2d2;
    background-color: #e9f0f0;
    > div {
      border-left: 1px solid #d5e0e0;
      &:first-child {
        border-left: 0;
      }
    }
  }
  &-day {
      border-left: 1px solid #c7d2d2;
      border-right: 1px solid #c7d2d2;
      border-bottom: 1px solid #c7d2d2;
    &-row {
      border-top: 1px solid #d5e0e0;
      background-color: #fff;
      &:first-child {
        border-top: 0;
      }
      div {
        border-left: 1px solid #d5e0e0;
        &:first-child {
          border-left: 0;
        }
      }
    }
  }
}

.schedule-table {
  border: 1px solid $table-th-line;
  td {
    line-height: 30px;   
    text-align: center;   
    padding: 4px 1px;   
    border-top: 1px solid $table-line;
    border-bottom: 1px solid $table-line;
    background: #fff;
    width: 0.2%;
    &.selected {
      background: #e6ffcc;
    } 
    &.label {     
      width: 6%;
      background: $idp-grid-bg;
      border-right: 1px solid $table-line;
    }
    &.red-label {
      background: $idp-grid-bg;
      color: red;
      border-right: 1px solid $table-line;
    }
    &.blue-label {
      background: $idp-grid-bg;
      color: blue;
      border-right: 1px solid $table-line;
    }
    &.right-line {
      border-right: 1px solid $table-line;
    }     
  }    
}

.schedule-area-1 {
  position: absolute;
  z-index: 10;
  border:1px solid #a6ff46;
  background: #e6ffcc;
  cursor: pointer;
}

.schedule-area-index-1 {
  border:1px solid #a6ff46;
  background: #e6ffcc;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.schedule-area-2 {
  position: absolute;
  z-index: 10;
  border:1px solid #4690ff;
  background: #c2dafd;
  cursor: pointer;
}

.schedule-area-index-2 {
  border:1px solid #4690ff;
  background: #c2dafd;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.schedule-area-3 {
  position: absolute;
  z-index: 10;
  border:1px solid #ffbe46;
  background: #fde5b8;
  cursor: pointer;
}

.schedule-area-index-3 {
  border:1px solid #ffbe46;
  background: #fde5b8;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.schedule-area-4 {
  position: absolute;
  z-index: 10;
  border:1px solid #fd66b9;
  background: #fda9d7;
  cursor: pointer;
}

.schedule-area-index-4 {
  border:1px solid #fd66b9;
  background: #fda9d7;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.schedule-area-5 {
  position: absolute;
  z-index: 10;
  border:1px solid #b16ffd;
  background: #e8d6fd;
  cursor: pointer;
}

.schedule-area-index-5 {
  border:1px solid #b16ffd;
  background: #e8d6fd;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.dragged-area {
  position: absolute;
  z-index: 10;
  border: 1px dotted #949494;
  background: #feffeb;
  cursor: pointer;
  pointer-events: none;
}

.shadow-area {
  position: absolute;
  z-index: 5;
}

.index-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-table {
  border: 1px solid $table-th-line;
  display: block;
  overflow-x: auto;
  tr:first-child td {
    border-top: 0;
    border-left: 0;
  }
  tr:last-child td {
    border-bottom: 0;
    border-right: 0;
  }
  td {
    border: 1px solid $table-line;
    line-height: 30px;   
    text-align: center;   
    padding: 4px 1px;   
    background: #fff;
    width: 0.2%;
    min-width: 50px;
    &.selected {
      background: #e6ffcc;
    } 
    &.label {     
      width: 6%;
      background: $idp-grid-bg;
    }  
  }  
}
