/**
 * @file _ag-grid.scss
 * @description ag-grid의 커스텀 클래스 ag-theme-default 스타일 파일
 */

@import "~ag-grid-community/dist/styles/ag-grid.scss";
@import "~ag-grid-community/dist/styles/ag-theme-balham/sass/ag-theme-balham-mixin.scss";

.ag-theme-balham {
  @include ag-theme-balham((
    border-color: #c7d2d2,
    font-family: ("Malgun Gothic", "맑은고딕"),
    suppress-native-widget-styling: true,
    balham-active-color: #23475c,
    control-panel-background-color: #ffffff,

    header-height: 32px,
    header-foreground-color: #000000,
    header-background-color: #e9f0f0,
    header-column-separator: true,
    header-column-separator-height: 100%,
    header-column-separator-width: 1px,
    header-column-separator-color: ag-derived(border-color),

    row-height: 32px,
    odd-row-background-color: #f7fafa,
    selected-row-background-color: #e6faf7,
    row-hover-color: #feffeb,

    cell-horizontal-border: solid ag-derived(border-color),
    input-height: ag-derived(row-height),
    input-focus-border-color: ag-derived(balham-active-color)
  ));

  font-weight: 100;

  .ag-root-wrapper {
    overflow: visible;
  }
  .ag-row-selected {
    font-weight: $font-weight-bold;
  }

  .ag-header-cell-text {
    font-weight: 100;
  }
  .ag-header-center {
    .ag-header-cell-label {
      justify-content: center;
      width: 100%;
      .star {
        color: $lua-pink-500;
      }
    }
    .ag-header-select-all {
      float: none;
    }
  }
  .ag-header-right {
    .ag-header-cell-label {
      justify-content: flex-end;
    }
  }
  .ag-header-required {
    .ag-header-cell-label {
      .ag-header-cell-text {
        display: inline-block;
        &:before {
          content: "*";
          display: inline-block;
          width: 8px;
          color: #fd195e;
          font-size: 1.2rem;
        }
      }
    }
  }

  .ag-checkbox-input-wrapper {
    input[type=checkbox] {
      position: relative;
      width: 13px;
      height: 13px;
      -webkit-appearance: none;
      -moz-appearance:    none;
      appearance:         none;
    }
    input[type=checkbox]::before {
      content: '';
      position: absolute;
      left: 0px;
      display: inline-block;
      width: 13px !important;
      height: 13px !important;
      background-color: transparent;
      background-repeat: no-repeat;
      background-image: url("/assets/images/icon/input_style.png");
      background-size: auto;
      background-position-y: 0;
      background-position-x: 0;
    }
    input[type=checkbox]:checked::before {
      content: '';
      position: absolute;
      left: 0px;
      display: inline-block;
      width: 13px !important;
      height: 13px !important;
      background-color: transparent;
      background-repeat: no-repeat;
      background-image: url("/assets/images/icon/input_style.png");
      background-size: auto;
      background-position-y: 0;
      background-position-x: -13px;
    }
  }
  .ag-cell-inline-editing {
    input {
      border-color: transparent;
      outline: none;
    }
    select {
      border-color: transparent;
      outline: none;
    }
  }
  .ag-popup-editor {
    transform: translate(-1px, -1px);
    box-shadow: none !important;
  }
  .datepicker {
    i {
      top: 9px;
    }
    input[type="text"] {
      width: 100% !important;
      height: ag-param(row-height) - 2 !important;
      border-color: transparent !important;
      padding: 0 8px 2px 28px !important;
    }
  }
  .ag-picker-field-wrapper {
    border-radius: 0;
  }
  .focus-cell {
    background: #e6ffcc !important;
  }
  .invalid-cell {
    background: #fff2f2 !important;
    color: #f92465 !important;
  }
  .warning-cell {
    background: #fffff2 !important;
    color: #b48b18 !important;
  }  
}
.i-chk {
  position: relative;
  font: normal normal normal 14px/1 FontAwesome, sans-serif;
  font-size: inherit;
  text-rendering: auto;
  &-normal:after {
    @extend .i-chk;
    content: "\f00c";
    color: #00b050;
  }
  &-error:after {
    @extend .i-chk;
    content: "\f00d";
    color: #ff3333;
  }
  &-saved:after {
    @extend .i-chk;
    content: "\f14a";
    color: #007ac7;
  }
}
//그리드 아이콘 3단계
@mixin igrid() {
  position: relative;
  font: normal normal normal 12px/1 FontAwesome, sans-serif;
  text-rendering: auto;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
}
.i-grid {
  &-red:after {
    @include igrid;
    content: "";
    border: 1px solid #bb0000;
    background-color: #ff3333;
    background-image: url("/assets/images/icon/close.png");
    vertical-align: -2px;
  }
  &-green:after {
    @include igrid;
    content: "";
    border: 1px solid #007700;
    background-color: #00b050;
    background-image: url("/assets/images/icon/check2.png");
    vertical-align: -2px;
  }
  &-blue:after {
    @include igrid;
    content: "ㅇ";
    border: 1px solid #005a93;
    background-color: #007ac7;
    color: #fff;
  }
}
.text-link {
  color: blue;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}