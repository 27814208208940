/**
 * @file _forms.scss
 * @description 폼컨트롤의 스타일 클래스 파일
 */

/* input box color */ 
input:-webkit-autofill{
  -webkit-box-shadow: 0 0 0 30px none inset !important;
  -webkit-text-fill-color:#000000;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}


 /* 기본 input 스타일 */
label {
  position: relative;
  white-space: pre;
}
label input[type="radio"].form-control,
label input[type="checkbox"].form-control {
  opacity: 0;
  width: 0 !important;
  height: 0 !important;
  + span {
    padding-left: 17px;
    position:relative;
    min-width: 13px;
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -6.5px;
      width: 13px;
      height: 13px;
      background: url("/assets/images/icon/input_style.png") no-repeat 0 0;
    }
  }
}

label input[type="radio"].form-control {
  + span {
    &:before {
      background-position-y: -13px;
    }
  }
}
label input[type="radio"]:checked.form-control,
label input[type="checkbox"]:checked.form-control {
  + span {
    &:before {
      background-position-x: -13px;
    }
  }
}
label input[type="radio"]:disabled.form-control,
label input[type="checkbox"]:disabled.form-control {
  + span {
    &:before {
      background-position-x: -26px;
    }
  }
}

label input[type="radio"]:disabled:checked.form-control,
label input[type="checkbox"]:disabled:checked.form-control {
  + span {
    &:before {
      background-position-x: -39px;
    }
  }
}


input[type="text"].form-control,
input[type="password"].form-control,
input[type="email"].form-control,
input[type="search"].form-control,
input[type="date"].form-control,
input[type="number"].form-control {
  display: inline-block;
  width: auto;
  min-width: 100px;
  height: 26px;
  padding: 4px 6px;
  border-color: $input-border-color;
  border-radius: 2px;
  box-shadow: none;
  font-size: 1.2rem;
  color: #202020;
}
//
.module-buttons {
  input[type="text"].form-control,
  input[type="password"].form-control,
  input[type="email"].form-control,
  input[type="search"].form-control,
  input[type="date"].form-control,
  input[type="number"].form-control,
  select,
  .btn {
    height: 28px;
  }
}

input[type="text"]:focus.form-control,
input[type="password"]:focus.form-control,
input[type="email"]:focus.form-control,
input[type="search"]:focus.form-control,
input[type="date"]:focus.form-control,
input[type="number"]:focus.form-control,
textarea:focus.form-control,
select:focus.form-control {
  border-color: $input-border-color-active;
}

input[type="checkbox"].form-control,
input[type="radio"].form-control {
  box-shadow: none;
}

input[type="file"].form-control {
  padding: 2px 0;
  display: inline-block;
  border: 0;
  height: 28px;
  font-size: 1.2rem;
}

input[readonly].form-control,
textarea[readonly].form-control {
  border-color: $input-border-color;
  background: #e9f0f0 !important;
  color: $gray-800;
}

input[type="text"]:disabled.form-control,
textarea:disabled.form-control,
th.required + td>.form-control:disabled
 {
  border-color: $input-border-color;
  background: #e9f0f0 !important;
  color: #949494 !important;
}

.validate.validate-on {
  position: relative;
  border-color: $lua-pink-500 !important;
}

.validate-info {
  vertical-align: middle;
  display: inline-block;
  line-height: 22px;
}

.validate-info p {
  display: inline-block; //line-height: 24px;
  color: $lua-pink-500;
  font-size: 1.1rem;
}

.validate-popup+.validate-info {
  display: block;
  position: relative;
  margin-left: 0;
}

.validate-popup+.validate-info p {
  display: inline-block !important;
  position: absolute;
  padding: 4px 6px !important;
  background: #fff;
  color: $lua-pink-500;
  font-size: 1.1rem;
}

p.invalid-text {
  color: $lua-pink-500;
  font-size: 1.1rem;
}

span.invalid-text {
  color: $lua-pink-500;
  font-size: 1.1rem;

  * + & {
    margin-left: 5px;
  }
}

.input-block.form-control {
  display: block !important;
  width: 100%;
}

*+.input-block.form-control {
  margin-top: 5px;
}

pre.form-control {
  height: auto;
  padding: 0;
  margin: 0;
  line-height: 18px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  font-family: "Malgun Gothic", sans-serif;
  font-size: 1.2rem;
  color: #202020;
  transition: none;
  white-space: pre-wrap;
}

select.form-control {
  display: inline-block;
  min-width: 60px;
  width: auto;
  height: 26px;
  padding: 2px 20px 4px 10px;
  margin-left: 0px;
  margin-right: 0px;
  box-shadow: none;
  border-color: #a8a8a8;
  border-radius: 2px;
  font-size: 1.2rem;
  color: #202020;
  background-image:url('/assets/images/icon/arrow_down.png') !important;
  background-position: right 7px center !important;
  background-repeat: no-repeat !important;
  appearance:none; 
  -webkit-appearance:none;
  -moz-appearance:none;
  -o-appearance:none;
  &::-ms-expand {
    display:none;
  }
}

.input-search {
  display: inline-block;
  input {
    margin-right: 5px;
    + .btn-inline {
      margin-left: 0;  
    }
  }
}

.input-checkbox:after,
.input-radio:after {
  display: block;
  content: "";
  clear: both;
}

.input-checkbox label,
.input-radio label {
  margin-right: 15px;
  float: left;
  line-height: 24px;
  font-weight: 100;
  cursor: pointer;
}

.input-checkbox input[type="checkbox"].form-control,
.input-radio input[type="radio"].form-control {
  display: inline-block;
  width: 0;
  height: 0;
  border: none;
  border-radius: 0;
  background: none;
  box-shadow: none;
  cursor: pointer;
}

.input-checkbox input[type="checkbox"].form-control:disabled,
.input-checkbox input[type="checkbox"].form-control:disabled+span,
.input-radio input[type="radio"].form-control:disabled,
.input-radio input[type="radio"].form-control:disabled+span {
  cursor: not-allowed;
}

.input-checkbox.input-single,
.input-checkbox.input-single:after,
.input-radio.input-single,
.input-radio.input-single:after,
.input-inlineBlock,
.input-inlineBlock:after {
  display: inline-block;
}

.input-checkbox.input-single label,
.input-checkbox.input-single label [type="checkbox"].form-control,
.input-radio.input-single label,
.input-radio.input-single label [type="radio"].form-control {
  margin-right: 0;
}

.input-radio label>input+span,
.input-checkbox label>input+span {
  display: inline-block;
  height: 26px;
}

ul.input-radio>li {
  height: 26px;
}

.input-radio {
  &.user-radio {
    line-height: 25px;
    &:before {
      content: "(사용자중복검색조건 :";
      padding-right: 5px;
      display: block;
      float: left;
    }
    &:after {
      content: ")";
      position: relative;
      left: 0;
      display: inline-block;
      line-height: 25px;
    }
  }
}

textarea.form-control {
  display: block;
  width: 100%;
  padding: 5px 8px;
  border-color: $input-border-color;
  // border-radius: 0;
  font-size: 1.2rem;
  color: #202020;
  box-shadow: none;
  outline: none;
  resize: vertical;
  border-radius: 2px;
}

textarea:disabled.form-control {
  resize: none;
}

textarea.form-control::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

textarea.form-control::-webkit-scrollbar-track {
  background: none;
}

textarea.form-control::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #cccccc;
}

.form-inline textarea.form-control {
  display: block;
  width: 100%;
}

.form-inline .form-control {
  vertical-align: middle;
}

.info+textarea.form-control {
  margin-top: 3px;
}

th.required+td>input[type="text"].form-control,
th.required+td input[type="text"],
td.required>input[type="text"].form-control,
th.required+td input[type="password"].form-control,
td.required input[type="password"].form-control,
th.required+td>input[type="number"].form-control,
th.required+td input[type="number"],
td.required input[type="number"].form-control,
th.required+td>input[type="search"].form-control,
td.required>input[type="search"].form-control,
th.required+td input[type="date"].form-control,
td.required input[type="date"].form-control,
th.required+td select.form-control,
th.required+td>textarea.form-control,
th.required+td>.select2,
th.required+td>.dropzone,
th.required+td>.datepicker input,
th.required+td>.timepicker input,
th.required+td>.L-numberbox input.form-control,
th.required+td>.dropzone-wrapper th.required+td li input[type="text"].form-control,
td.required li input[type="text"].form-control,
th.required+td li select.form-control,
th.required+td li textarea.form-control,
th.required+td li .select2,
th.required+td li .dropzone,
th.required+td li .dropzone-wrapper,
/*th.required+td li .L-numberbox input.form-control,*/

td.required+td input[type="text"].form-control,
td.required+td input[type="password"].form-control,
td.required+td select.form-control,
td.required+td textarea.form-control,
td.required+td .select2,
td.required+td .dropzone,
td.required+td .L-numberbox input.form-control,
td .required input[type="text"].form-control,
td .required input[type="password"].form-control,
td .required select.form-control,
td .required textarea.form-control,
td .required .select2,
td .required .dropzone,
td .L-numberbox.required input.form-control {
  background-color: $idp-yellow !important;
  &:-webkit-autofill{
    -webkit-box-shadow: 0 0 0 30px $idp-yellow inset !important;
  }
  &.ng-invalid {
    &.ng-touched, &.ng-dirty {
      &.form-control {
        position: relative;
        border-color: $input-border-color-error !important;
        background-color: #fff2f2 !important;
      }
    }
  }
  &:disabled {
    background-color: #e9ecef !important;
  }
}

th.required + td {
  app-typeahead-select {
    .ng-select {
      background-color: $idp-yellow !important;
    }
    &.ng-invalid {
      &.ng-touched, &.ng-dirty {
        .ng-select {
          border-color: $input-border-color-error !important;
          background-color: #fff2f2 !important;
        }
      }
    }
  }
}

td input[type="text"].form-control,
td input[type="password"].form-control,
td input[type="email"].form-control,
td input[type="search"].form-control,
td input[type="date"].form-control,
td input[type="number"].form-control {
  height: 25px;
}

.table-list td.required input[type="text"].form-control,
.table-list td.required input[type="password"].form-control,
.table-list td.required input[type="number"].form-control,
.table-list td.required select.form-control,
.table-list td.required textarea.form-control,
.table-list td.required .select2,
.table-list td.required .dropzone,
.table-list td.required .dropzone-wrapper {
  background-color: #fff5f5;
}

.input-group input[type="text"].form-control {
  width: 100%;
}

.input-group-btn>.btn {
  height: 26px;
}

.input-group-btn>.btn.btn-icon {
  width: 26px;
  height: 26px;
  padding: 0;
  border: 1px solid #949494;
  background: #fff;
}

.input-group-inline {
  position: relative;
  display: table-cell;
  width: 1%;
  vertical-align: middle;
  font-size: 0;
  white-space: nowrap;
}

input+.input-group-inline {
  padding-left: 8px;
}

.input-group-inline .input-checkbox label {
  font-size: 1.2rem;
}

td input[type="text"].form-control,
td input[type="number"].form-control,
td input[type="password"].form-control,
td input[type="email"].form-control,
td input[type="search"].form-control,
td input[type="date"].form-control {
  min-width: auto;
}

td .list-inline li>* {
  float: left;
  margin-right: -5px;
}

.list-process {
  display: inline-block;
}

.list-process:after {
  content: "";
  display: inline-block;
  clear: both;
}

.list-process li {
  float: left;
  display: inline-block;
  position: relative;
  height: 26px;
}

.list-process li a {
  text-decoration: none;
  color: #202020;
  font-size: 1.2rem;
}

.list-process li a:hover,
.list-process li a:focus {
  text-decoration: none;
  color: #202020;
}

.list-process li.active a {
  display: block;
  height: 26px;
  line-height: 22px;
  padding: 0 8px 0 19px;
  border: 1px solid #aeaeae;
  background: #f6f6f6;
  font-size: 1.1rem;
}

.list-process li.active a:hover,
.list-process li.active a:focus {
  border-color: #949494;
  background: #efefef;
}

.list-process li.active a span {
  position: relative;
}

.list-process li.active a span:before {
  content: "\f040";
  font-family: 'FontAwesome', sans-serif;
  display: inline-block;
  position: absolute;
  top: -2px;
  left: -13px;
  color: #4c4c4c;
}

.list-process li.disabled a {
  cursor: not-allowed;
  color: #999999;
}

.list-process li+li {
  margin-left: 16px;
}

.list-process li+li:before {
  content: "\f0da";
  font-family: 'FontAwesome', sans-serif;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: -10px;
  color: #bcbcbc;
  font-size: 1.4rem;
}

.input-checkbox.input-style label {
  position: relative;
  padding-left: 27px;
  height: 20px;
  line-height: 18px;
  cursor: pointer;
}

.input-checkbox.input-style label span {
  display: inline;
}

.input-checkbox.input-style label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: url("/assets/images/icon/checkbox.png") no-repeat 0 -20px;
}

.input-checkbox.input-style label input[type=checkbox]:checked + span:before {
  background-position-y: 0;
}

.input-checkbox.input-style.checked span:before {
  background-position-y: 0;
}

.input-checkbox.input-style input[type=checkbox] {
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0;
  cursor: pointer;
}

.list-inline .form-chain {
  display: inline-block;
  width: 10px;
  height: 26px;
  line-height: 24px;
  text-align: center;
}

.list-inline li>p {
  display: inline-block;
  height: 26px;
  line-height: 24px;
}

.list-inline+p,
textarea+p {
  margin-top: 5px;
}

i.status {
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid #c1c1c1;
  border-radius: 50%;
  background: #f8f8f8;
  vertical-align: middle;
}

i.status.status-positive {
  background: #337ab7;
  border-color: #337ab7;
}

.input-group .form-control {
  z-index: 1;
}

.table-detail {
  th {
    .input-checkbox{
      vertical-align: top;
      height: 20px;
      label {
        float: none;
        height: 20px;
        line-height: 20px;
        margin-right: 0;
        span {
          height: 20px;
          line-height: 20px;
        }
      }
    }
  }
  td {
    .input-radio,
    .input-checkbox,
    .table-search {
      display: inline-block;
      vertical-align: top;
      min-height: 25px;
    }
    span,
    .input-radio,
    .input-checkbox {
      + input[type="text"],
      + input[type="number"],
      + .table-search {
        margin-left: 10px;
      }
    }
    .table-search {
      + .input-radio,
      + .input-checkbox {
        margin-left: 10px;
      }
      .fav-pop {
        top:24px;
        .fav-list {
          max-height: 50px;
        }
      }
    }
    label {
      height: 25px;
      line-height: 25px;
    }
    select {
      display: inline-block;
      vertical-align: top;
      width: auto;
      height: 25px !important;
      padding: 3px 20px 3px 6px;
      margin-left: 0px;
      //margin-right: 0px;
      box-shadow: none;
      border-color: $input-border-color;
      border-radius: 2px;
      + span {
        margin-left: 5px;
      }
    }
    input {
      vertical-align: top;
      padding: 4px 6px;
      height: 25px;
      border: 1px solid $input-border-color;
      + span {
        margin-left: 5px;
      }
    }
    input:disabled {
      cursor: not-allowed;
    }
  }
  .table-search {
    position: relative;
    input[type="text"] {
      border: 1px solid $input-border-color;
      vertical-align: middle;
      padding-left: 25px;
      height: 25px;
      //line-height: 25px;
      border-radius: 2px;
    }
    .btn-icon-single {
      padding: 0;
      position: absolute;
      left: 0;
      top: 0;
      min-width: 25px;
      height: 25px;
      background: url("/assets/images/icon/search.png") no-repeat center;
      i {
        font-size: 0; //삭제예정
      }
    }
  }
}

td .table-list .input-checkbox {
  float: none;
}

.form-control::placeholder {
  color: #6c757d;
}
.form-control {
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #6c757d;
  }
}


// validator
.ng-invalid.ng-touched {
  &.form-control,
  >.ng-select-container {
    position: relative;
    border-color: $input-border-color-error !important;
    background-color: #fff2f2 !important;
  }
}

.ng-invalid {
  &.ng-touched, &.ng-dirty {
    &.form-control,
    >.ng-select-container {
      position: relative;
      border-color: $input-border-color-error !important;
      background-color: #fff2f2 !important;
    }
  }
}

.table-ellipsis {
  display:table;
  table-layout: fixed;
  position: relative;
  width: 100%;
  min-height: 25px;
  .btn {
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border: 1px solid $input-border-color;
    border-radius: 2px;
    background: url("/assets/images/icon/search.png") no-repeat -2px -1px;
  }
  .text {
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    padding-left: 30px;
    span {
      display: initial;
    }
  }
}


//커스텀 엑셀 업로드
.custom-file {
  height: 27px;
  cursor: pointer;
  &.excel {
    width: auto;
    .custom-file-label {
      position: relative;
      padding: 0 8px;
      font-size: 1.1rem;
      background-color: #fff;
      vertical-align: top;
      border-radius:2px;
      height:27px;
      line-height: 27px;
      &:before {
        margin-right: 5px;
        position: relative;
        top:0;
        display: inline-block;
        content: "";
        background: url("/assets/images/icon/grid_button.png") no-repeat -69px -8px;
        width: 12px;
        height: 12px;
      }
      span {
        vertical-align: top;
        line-height:25px;
      }
      &:after {
        padding: 0;
        content:"";
        top: initial;
        right: initial;
        bottom: initial;
        left: initial;
        height: 0;
        line-height: 0;
        background: none;
      }
      .custom-file-input {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .custom-file-name {
      margin-left: 15px;
      display: inline-block;
      color: #38c9b2;
      text-decoration: underline;
      &:before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 18px;
        background: url("/assets/images/icon/file.png") no-repeat -9px 0;
      }
    }
  }
}

.attached-file {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  cursor: pointer;
  .attached-file-name {
    display: inline-block;
    color: #38c9b2;
    text-decoration: underline;
    &:before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 18px;
      background: url("/assets/images/icon/file.png") no-repeat -9px 0;
    }
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

.search-input {
  position: relative;
  input[type="text"] {
    border: 1px solid $input-border-color;
    vertical-align: middle;
    padding-left: 25px;
    height: 25px;
    border-radius: 2px;
  }
  .btn-icon-single {
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    min-width: 25px;
    height: 25px;
    background: url("/assets/images/icon/search.png") no-repeat center;
  }
}

.input-typeahead {
  position: relative;
  input[type="text"] {
    padding-left: 18px;
  }
  &:after {
    content: "\f002";
    font-family: 'FontAwesome', sans-serif;
    color: #747474;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 4px;
  }
}