/**
 * @file _autocomplete.scss
 * @description auto-complete 커스텀 스타일 클래스 파일
 */

//auto-complete

// 표준 자동완성 컨트롤
typeahead-container {
  &.dropdown-menu {
    &.open {
      z-index: $zindex-sticky;
      min-width: 144px;
      margin-top: -1px;
      border: 1px solid #a8a8a8;
      color: #333;
      .dropdown-header {
        padding: 6px;
        border-bottom: #eaeaea;
      }
      .dropdown-item {
        padding: 4px 6px;
        border: none;
        color: #333;
        font-size: 1.2rem;
        line-height: 20px;
        &.active {
          background-color: #ddd;
        }
        &:hover {
          background-color: #ddd;
        }
        span {
          text-indent: 0px;
          strong {
            color: #f4516c;
          }
        }
      }
    }
  }
}

// 통합검색등에서 사용하는 자동완성 컨트롤
.page-search-main {
  typeahead-container {
    margin-top: 1px !important;
    &.dropdown-menu {
      min-width: 450px;
      box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
      border: 1px #ddd solid;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .dropdown-item {
        color: #22537c !important;
        font-size: 1.2rem;
        border: 0;
        padding: 8px 12px !important;
        &.active,
        &:active {
          text-decoration: none;
          background-color: #f1f1f1;
        }

        span {
          color: #22537c;
          text-indent: 0;
          strong {
            background-color: #daf7f7;
            color: #22537c !important;
          }
        }
      }
    }
  }
}
