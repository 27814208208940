/**
 * @file _swal2.scss
 * @description swal2 의 커스텀 스타일 클래스 파일 
 */

// swal2
.swal2-popup #swal2-content {
  text-align: left !important;
  font-size: 1.4rem;

  .swal2-title {
    display: block;
    position: relative;
    max-width: 100%;
    margin: 0 0 0.4em;
    padding: 0;
    color: #595959;
    font-size: 1.6em;
    font-weight: 600;
    text-align: center;
    text-transform: none;
  }
}

.swal2-popup.swal2-modal {
  padding: 0;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .3);

  .swal2-header {
    height: 46px;
    border-bottom: 1px solid $border-color;
    color: #293846;
    font-size: 1.4rem;
    align-items: flex-start;
    position: relative;
    padding: 13px 22px 12px;

    .swal2-title {
      font-size: 15px;
      position: absolute;

      &:before {
        position: relative;
        margin-right: 5px;
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url("/assets/images/icon/alert.png") no-repeat -54px -30px;
      }
    }
  }

  &.swal-success {
    .swal2-header {
      .swal2-title {
        color: #293846 !important;

        &:before {
          background-position-x: -78px;
        }
      }
    }
  }

  &.swal-error {
    .swal2-header {
      .swal2-title {
        color: $lua-pink-500 !important;

        &:before {
          background-position-x: -102px;
        }
      }
    }
  }

  &.swal-warning {
    .swal2-header {
      .swal2-title {
        color: #ff8a00 !important;

        &:before {
          background-position-x: -6px;
        }
      }
    }
  }

  &.swal-info {
    .swal2-header {
      .swal2-title {
        color: #293846 !important;
      }
    }
  }

  .swal2-content {
    padding: 22px 25px;
    line-height: 24px;
    text-align: center;
  }

  .swal2-actions {
    padding: 0 25px 22px;
    justify-content: flex-end;
    margin: 0;
  }

  .swal2-success-circular-line-left,
  .swal2-success-circular-line-right,
  .swal2-success-line-tip,
  .swal2-success-line-long,
  .swal2-success-ring,
  .swal2-success-fix {
    display: none;
  }

  .swal2-close {
    top: 15px;
    right: 25px;
    width: 15px;
    height: 15px;
    display: block !important;
    font-size: 0;
    text-indent: -9999px;
    background: url("/assets/images/icon/close4.png") no-repeat 2px 2px;
  }
}

.swal2-icon.swal2-success {
  display: none !important;
}

.swal2-cancel, .swal2-deny {
  margin-left: 10px;
}

.swal2-icon {
  display: none !important;
}