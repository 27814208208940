/**
 * @file _dashboard.scss
 * @description 대시보드 관련 파일
 */

.dashboard-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.card {
  font-family: 'Malgun Gothic', sans-serif;
  margin-bottom: 3rem;

  .card-header {
    font-size: 16px;
    color: #293846;
    font-weight: bold;
    cursor: pointer;

    .card-header-actions {
      button {
        background-color: #ffffff;
        margin-left: 5px;
      }
    }
  }

  .card-body {
    padding: 20px;

    &.widget-single-status {
      padding: 20px 30px;
      display: flex;
      i.fa {
        font-size: 72px;
        margin-right: 20px;
      }
      .count {
        font-size: 48px;
        color: #555555;
        margin-right: 5px;
        &.warning {
          color: #ff6475;
        }
      }
      .type {
        font-size: 15px;
        color: #555555;
      }
    }
    
    &.widget-multi-status {
      .type {
        font-size: 13px;
        color: #293846;
      }
      .count {
        font-size: 36px;
        color: #555555;
        &.warning {
          color: #ff6475;
        }
      }
      li {
        display: flex;
        justify-content: space-between;
        line-height: 66px;
        &:not(:last-child) {
          border-bottom: 1px solid #d8dbe0;
        }
      }
      .updated {
        font-size: 12px;
        color: #949494;
        margin-top: 5px;
      }
    }
    
    &.widget-notice {
      .title {
        width: 100%;
        color: #333333;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        &.today {
          color: #f92465;
        }
      }
      .updated {
        font-size: 12px;
        color: #949494;;
      }
      li {
        height: 48px;
      }
    }

    &.widget-alert {
      .period {
        height: 30px;
        display: block;
        text-align: right;
      }
      .type {
        width: 60px;
        padding-top: 6px;
        margin-right: 10px;
        span {
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          text-align: center;
          color: #ffffff;
          font-size: 11px;
          font-weight: $font-weight-bold;
          padding: 4px;
          border-radius: 3px;
        }
        &.type-1 span {
          background-color: #38c9b2;
        }
        &.type-2 span {
          background-color: #31bedc;
        }
        &.type-3 span {
          background-color: #3898c9;
        }
        &.type-4 span {
          background-color: #00c69a;
        }
        &.type-5 span {
          background-color: #868fda;
        }
      }
      .title {
        width: 100%;
        color: #333333;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        &.important {
          color: #f92465;
        }
      }
      .updated {
        font-size: 12px;
        color: #949494;;
      }
      li {
        height: 48px;
        display: flex;
        div {
          min-width: 0; // flex item의 ellipsis 처리를 위해 필요함
          overflow: hidden;
        }
      }

    }

    &.widget-quicklink {
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          margin-right: 5px;
          margin-bottom: 15px;
          .link {
            font-size: 12px;
            font-weight: $font-weight-bold;
            padding: 4px 12px;
            border-radius: 3px;
            border: 1px #000000 solid;
            &.link1 {
              color: #38c9b2;
              border-color: #88dfd1;
            }
            &.link2 {
              color: #31bedc;
              border-color: #83d8ea;
            }
            &.link3 {
              color: #3898c9;
              border-color: #88c1df;
            }
            &.link4 {
              color: #00c69a;
              border-color: #66ddc2;
            }
            &.link5 {
              color: #868fda;
              border-color: #b6bce9;
            }
            &.link6 {
              color: #3898c9;
              border-color: #88c1df;
            }
          }
        }
      }
    }

    &.widget-connectsvr {
      .table-container {
        &.row-5 {
          max-height: 193px;
        }
        &.row-10 {
          max-height: 353px;
        }
        &.row-15 {
          max-height: 513px;
        }
        &.row-20 {
          max-height: 673px;
        }
        &.row-25 {
          max-height: 833px;
        }
        &.row-30 {
          max-height: 993px;
        }
        &.row-35 {
          max-height: 1153px;
        }
        &.row-40 {
          max-height: 1313px;
        }
        &.row-45 {
          max-height: 1473px;
        }
        &.row-50 {
          max-height: 1633px;
        }
        overflow-y: auto;
        border: 1px solid #c7d2d2;
        .table-list {
          width: 100%;
          border-width: 0;
          th, td {
            &:first-child {
              border-left-width: 0;
            }
            &:last-child {
              border-right-width: 0;
            }
          }
          td {
            max-width: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .fa {
              font-size: 14px;
            }            
          }
          tr:last-child td {
            border-bottom-width: 0;
          }
        }
      }
    }

    &.today-visit {
      .dot-line {
        height: 2px;
        border: 1px #ccd6e5 solid;
        display: flex;
        justify-content: space-around;
        margin: 20px 0 10px;
        .dot {
          width: 12px;
          height: 12px;
          background-color: #ffffff;
          border: 4px solid;
          border-radius: 6px;
          margin-top: -6px;
          &.dot1 {
            border-color: #ffc339;
          }
          &.dot2 {
            border-color: #ff7d54;
          }
          &.dot3 {
            border-color: #f14771;
          }
        }
      }
      .visit-cnt {
        display: flex;
        justify-content: space-around;
        div {
          width: 80px;
          &.count {
            font-size: 32px;
            color: #555555;
            text-align: center;
          }
          &.label {
            font-size: 15px;
            color: #949494;
            text-align: center;
          }
        }
      }
    }
  }

  .no-data {
    font-size: 15px;
  }
}

.table-detail.widget-setup {
  td.half-half {
    div {
      width: 50%;
      display: inline-block;
      > * {
        vertical-align: middle;
      }
    }
  }
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}