input::-ms-clear {
  display: none;
}

input[type=checkbox],
input[type=radio] {
  display: inline-block;
  width: auto;
  height: auto;
}

.btn-primary:not(:disabled):not(.disabled):active:focus{
  box-shadow: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-search-input {
  height: 28px !important;
}

// messenger {
//   width: 100%;
//   flex: 1;
//   display: flex;
//   flex-direction: column;
// }

main.home {
  margin-left: 0 !important;
}

// 컴포넌트 가이드
.value-info {
  display: block;
  margin: .5rem 0 0 0;
  color: #757575;
  padding: 0;
  &.inline {
    display: inline-block;
    margin: 0 0 0 1rem;
    line-height: 26px;
  }
}

// 자동완성 하이라이트 (app-search-component.ts)
em.highlight {
  font-weight: $font-weight-bold !important;
  font-style: normal !important;
  color: $brand-color !important;
}

//from core ui
.bg-primary,
.bg-success,
.bg-info,
.bg-warning,
.bg-danger,
.bg-inverse {
  color: #fff;
}
