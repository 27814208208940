// scss-lint:disable all
.animated {
  animation-duration: 1s;
  // animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

$animation-appear-bounce: scale 0.5s cubic-bezier(0.47, 0.46, 0.27, 1.55);

@keyframes scale{
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

//$animation-shake: shake .1s ease-in .1s infinite alternate;
$animation-shake: shake .1s ease-in .1s infinite alternate;

@keyframes shake {
  from {
    transform: rotate(13deg);
  }
  to {
    transform: rotate(-13deg);
  }
}

.shake {
  animation: $animation-shake;
}
