@charset "utf-8";

/**
 * @file _navbar.scss
 * @description LUA navbar header 스타일
 */

.app-header.navbar {
  position: relative;
  height: $navbar-height;
  padding: 0;
  margin: 0;
  background-color: $navbar-bg;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  .navbar-item {
    display: flex;
    width: 100%;
    height: $navbar-height-top;
  }
  .navbar-item + .navbar-item {
    height: $navbar-height-bottom;
    border-top: 1px solid $navbar-color;
  }  
  .navbar-item {
    .navbar-brand {
      display: inline-block;
      width: $sidebar-width;
      height: $navbar-height-top;
      margin-right: 0;
      background-color: transparent;
      padding: 0;
      font-weight: $font-weight-normal;
      transition: all 0.25s;
      overflow: hidden;
      background-image: $navbar-brand-logo;
      background-repeat: no-repeat;
      background-position: 15px 50%;
      top: 0;
      span {
        display: inline-block;
        width: 100%;
        padding-left: 50px;
        color: #242424;
        font-size: 1.6rem;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
        small {
          font-family: 'LGSmHaL', sans-serif;
          font-size: 1.2rem;
          color: rgba(0,0,0,.85);
          margin-left: 0.5rem;
        }
      }
    }
    .navbar-info {
      margin: 0 0 0 auto;
      border-left: 1px solid $navbar-color;
      li {
        position: relative;
        padding: 10px 5px;
        display: inline-block;
        vertical-align: middle;
        /*
        &:hover:after {
          content:"";
          position: absolute;
          bottom:0;
          left:0;
          width:100%;
          height:1px;
          background-color: $idp-green;
        }*/
      }
      .navbar-btn {
        display: inline-block;
        vertical-align: top;
        width: 30px;
        height: 30px;
        font-size: 1.2rem;
        color: $gray-500;
        background: url("/assets/images/icon/push2.png") no-repeat;
        span {
          font-size: 0;
          text-indent: -9999px;
        }
        &.appr {
          background-position: -21px 0;
        }
        &.notice {
          position: relative;
          background-position: -91px 0;
          .num {
            position: absolute;
            top: 2px;
            right: 2px;
            display: block;
            width: 14px;
            height: 14px;
            line-height: 14px;
            border-radius: 14px;
            background-color: #f92566;
            color: #fff;
            text-align: center;
            font-size:11px;
          }
        }
        &.setting {
          background-position: -161px 0;
        }
        &.modify {
          background-position: -234px 0;
        }
      }
    }
    .navbar-toggler {
      position: relative;
      min-width: 47px;
      height: $navbar-height-bottom - 1px;
      margin-left: 0;
      font-size: 1.8rem;
      color: $gray-550;
      i {
        position: relative;
        &.fa-star {
          color: $lua-pink-500;
        }
        &.all {
          width: 100%;
          height: 100%;
          background: url("/assets/images/icon/allmenu.png") no-repeat center;
        }
      }
      span {
        margin-left: 0;
        font-size: 0;
        text-indent: -9999px;
      }
      /*
      &:hover:after {
        content: "";
        display: inline-block;
        position: absolute;
        bottom: 0 !important;
        left: 0;
        width: 100%;
        height: 1px;
        background: $idp-green;
        z-index: 10;
        transition: width 0.4s ease-out;
      }*/
    }
    /*
    .right-toggler {
      width: 30px;
      height: 30px;
      margin-right: 1rem;
      margin-left: 5px;
      border: 1px solid $gray-200;
      border-radius: 50%;
      padding: 0;
      font-size: 0.833rem;
      background-color: $white;
      color: $gray-500;
      transition: background-color, color 0.3s;
      &:hover {
        color: $brand-color;
        //background: $gray-150;
        i{
          font-weight: 900;
        }
      }
      i {
        display: inline-block;
        vertical-align: middle;
        transition: transform 0.3s ease;
        position: relative;
      }
      //&.open {
      //  i {
      //    transform: rotate(180deg);
      //    transform-origin: center center;
      //  }
      //}
    }*/
    .navbar-quick {
      /*width: $sidebar-width;*/
      > li {
        height: $navbar-height-bottom - 1px;
        border-right: 1px solid $navbar-color;
        &.show {
          .navbar-toggler {
            color: $idp-green;
            /*
            &:after {
              content: "";
              display: inline-block;
              position: absolute;
              bottom: 0 !important;
              left: 0;
              width: 100%;
              height: 1px;
              background: $idp-green;
              z-index: 10;
              transition: width 0.4s ease-out;
            }*/
          }
          .navbar-full {
            display: block;
          }
        }
      }
    }
    //full down menu
    .navbar-full {
      display: none;
      position: absolute;
      top: $navbar-height;
      left: 0;
      width: 100%;
      border-top: 1px solid $navbar-color;
      background-color: $white;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      font-size: 1.25rem;
      .inner {
        padding:0 50px;
        display: flex;
      }
      .fullmenu {
        margin-right: 20px;
        padding: 25px 0 45px;
        width:100%;
        border-right: 0px solid $navbar-color;
        > ul {
          text-align: left;
          &:after {
            content:"";
            clear: both;
            display: block;
          }
        }
        li.list {
          display: inline-block;
          padding: 0 1rem 3rem;
          //width: 100%;
          min-width: 180px;
          max-width: 260px;
          text-align: left;
          vertical-align: top;
          h4 {
            margin: 0 0 1rem 0;
            font-size: 1.4rem;
            color: $gray-900;
          }
          li {
            display: inline-block;
            padding:0.5rem 0;
            &:first-child {
              width: 12px;
            }
            .fav-btn {
              color: $gray-200;
              background-color: transparent;
              &.active {
                color: $lua-pink-500;
              }
            }
            a {
              margin-left: 0.5rem;
              color: $gray-550;
              &:hover,&.active {
                font-weight: $font-weight-bold;
                color: $gray-600;
              }
            }
          }
        }  
      }
      .quickmenu {
        padding-top: 25px;
        width:200px;
        li + li {
          margin-top: 5px;
        }
        button {
          width: 180px;
          height: 50px;
          background: #fff;
          border-radius: 3px;
          text-align: left;
          i {
            display: inline-block;
            width: 50px;
            height: 50px;
            vertical-align: middle;
            background: url("/assets/images/icon/menu_quick.png") no-repeat 0 center;
            &.notice {background-position-x: 0;}
            &.help {background-position-x: -50px;}
            &.data {background-position-x: -100px;}
          }
          em {
            display: inline-block;
            vertical-align: middle;
            font-size: 13px;
          }
          &:hover {
            background-color: #f0f5f5;
            color: #119a84;
            font-weight: $font-weight-bold;
            em {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 100%;
                height: 1px;
                background-color: #368d7f;
              }
            }
          }          
        }
      }      
    }    
    .navbar-nav {
      position: relative;
      flex-direction: row;
      align-items: center;
    }
    .nav-item {
      position: relative;
      margin: 0;
      min-width: 35px;
      text-align: center;
      transition: 0.3s;
      button {
        margin: 0 auto;
      }
      .nav-link {
        position: relative;
        line-height: $navbar-height-bottom;
        height: $navbar-height-bottom;
        color: $gray-900;
        padding: 0 5px;
        &:hover {
          font-weight: $font-weight-bold;
        }
        &.active {
          font-weight: $font-weight-bold;
          &:after {
            width: 100%;
          }
        }
        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 47px;
          left: 0;
          width: 0;
          height: 2px;
          background: $idp-green;
          z-index: 10;
          transition: width 0.4s ease-out;
          box-sizing: initial !important;
        }
      }
      &.search-wrap {
        position: absolute;
        right: 0;
        top: 0;
        height: $navbar-height-top;
        line-height: $navbar-height-top;
        background: $white;
        &:hover,
        &.active {
          .nav-link {
            &.search {
              i {
                background-position-y: -40px;
              }
            }
          }
          &:after {
            content:"";
            position:absolute;
            left: 0;
            bottom: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: $idp-green;
          }
        }
        &.active {
          .nav-link {
            &.search {
              +input {
                width: 180px;
                
              }
            }
          }
        }
        .nav-link {
          &.search {
            display: inline-block;
            position: relative;
            height: $navbar-height-top;
            line-height: $navbar-height-top;
            padding: 0;
            vertical-align: -2px;
            transition: transform 0.3s ease-out;
            i {
              display: inline-block;
              vertical-align: top;
              width:35px;
              height:40px;
              background: url("/assets/images/icon/search2.png") no-repeat center 0;
              font-size: 0;
              text-indent: -9999px;
            }
            &+input {
              display: inline-block;
              margin: 0;
              width: 125px;
              height: $navbar-height-top;
              border: none;
              //padding: 0.5rem 0 0.5rem 5px;
              transition: width 0.3s ease-out;
              vertical-align: top;
              &:focus {
                box-shadow: none;
              }
            }
            &:after {
              height: 0;
            }
          }
        }
        input {
          font-size: 1.3rem;
          -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
        }
      }
      .nav-link {
        background: 0;
        border: 0;
        font-size: 1.4rem;
        padding: 0 1rem;
        height: $navbar-height-bottom;
        line-height: $navbar-height-bottom;
        >.img-avatar {
          height: $navbar-height - 73px;
          margin: 0;
          position: absolute;
          top: calc(50% - 14px);
          right: 6px;
        }
      }
      .nav-link-icon {
        width: 30px;
        height: 30px;
        border: 1px solid $gray-200;
        border-radius: 50%;
        padding: 0;
        background-color: $white;
        color: $gray-500;
        transition: background-color, color 0.3s;
        display: inline-block;
        position: relative;
        &:hover {
          color: $brand-color;
        }
        i {
          font-size: 1.167rem;
          line-height: 30px;
          display: inline-block;
          vertical-align: middle;
          transition: transform 0.3s ease;
        }
      }
    }
    .nav-link,
    .nav-link-icon {
      .badge {
        font-family: $font-family-base !important;
        position: absolute;
        top: 30%;
        left: 55%;
        margin-top: -16px;
        margin-left: 0;
      }
    }
  }
}

#dropdown-setup.dropdown-menu {
  line-height: $line-height-base;
  border: 0;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.2);
  background: $white;
  top: 95% !important;
  font-size: 12px;
  .dropdown-item {
    min-width: 180px;
    color: rgba(0, 0, 0, .5);
    padding: 8px 20px;
    border-bottom: 1px solid $dropdown-border-light-color;
    cursor: pointer;
    &:last-child {
      border-bottom: 0;
    }
    &.hover,
    &:hover {
      color: $brand-color;
    }
    &.active,
    &:active {
      color: $brand-color;
      text-decoration: none;
      background: $gray-100;
    }
    i {
      color: $gray-550;
      vertical-align: middle;
    }
    img.avatar {
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-right: 10px;
      margin-left: -10px;
      color: #d8dde3;
      text-align: center;
      border-radius:50%
    }
  }
}

.navbar-brand {
  color: $navbar-active-color;
  vertical-align: top;
  font-size: 1.5rem;
  padding-left: 58px;
  font-weight: 600;
  @include hover-focus {
    color: $navbar-active-color;
  }
}

.navbar-nav {
  .nav-link {
    color: $navbar-color;
    @include hover-focus {
      color: $navbar-hover-color;
    }
  }
  .open>.nav-link,
  .active>.nav-link,
  .nav-link.open,
  .nav-link.active {
    @include plain-hover-focus {
      color: $navbar-active-color;
      font-weight: 400;
    }
  }
}

.navbar-divider {
  background-color: rgba(0, 0, 0, .075);
}

/*
@include media-breakpoint-up(lg) {
  .brand-minimized {
    .app-header.navbar {
      .navbar-brand {
        width: 50px;
        background-position: 10px 50%;
        @include borders($navbar-brand-minimized-border);
      }
    }
  }
}
*/
/*
@include media-breakpoint-up(lg) {
  .app-header.navbar .navbar-brand.navbar-brand-home {
    width: 50px;
    background-position: 10px 50%;
    span {
      display: none;
    }
  }
}
*/
.app-header {
  .app-header-indicator {
    position: absolute;
    left: 0;
    top: 47px;
    height: 2px;
    width: 0;
    overflow: hidden;
    background: $idp-green;
    transition: left .3s;

    &.disappear {
      transition: width .3s;
    }
  }

  @include media-breakpoint-down(md) {
    .navbar-full {
      .list {
        flex-basis: 33.33%;
      }
    } 
  }

}

.sidebar-hidden {
  .sidebar {
    .navbar-toggler {
      padding: 0;
      position: absolute;
      top:  20px;
      right: -40px;
      width: 25px;
      height: 25px;
      border: 1px solid #9facac;
      border-radius: 3px;
      background: #fff;
      i {
        position: relative;
        &:before {
          content: "\f0c9";
        }
      }
    }
  }
}