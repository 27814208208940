/**
 * @file _header.scss
 * @description 헤더의 레이아웃만 관련된 스타일 클래스 파일  
 */

.app-header {
  .navbar-toggler {
    &.dropdown-toggle {
      &:after {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .dropdown-menu {
    &.main-menu {
      display: none;
    }
  }
}

