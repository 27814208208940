/**
 * @file _tree.scss
 * @description 트리 레이아웃과 관련된 스타일 클래스 파일
 */

 h4+.tree {
  margin-top: 10px;
}


.tree-button-updown {
  margin-top: 8px;
  vertical-align: bottom;
  .btn.btn-icon {
    border: 1px solid #949494;
    border-radius: 4px;
    line-height: 0;
    & + .btn.btn-icon {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.tree-wrapper {
  border: 1px solid #c7d2d2;
  .tree-header {
    padding: 0 10px;
    height: 30px;
    line-height: 29px;
    background: #e9f0f0;
    border-bottom: 1px solid #c7d2d2;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    &-block {
      +.tree-header-block {
        margin-top: 10px;
      }
      .page-search {
        margin-bottom: 0;
      }
      &:after {
        display: block;
        content: "";
        clear: both;
      }
    }
    .search {
      float: left;
      position: relative;
      display: inline-block;
      width: 120px;
      input {
        width: 100%;
        height: 28px;
        margin-right: 6px;
        padding-left: 30px;
        border: 1px solid #eaeaea;
      }
      i {
        content: "\f002";
        font-family: 'FontAwesome', sans-serif;
        display: inline-block;
        position: absolute;
        color: #747474;
        font-size: 1.3rem;
        top: 7px;
        left: 10px;
      }
      +span {
        margin-left: 10px;
      }
    }
    span {
      font-size: 1.2rem;
      color: #747474;
      em {
        color: $lua-pink-500;
      }
    }
    .input-radio {
      float: left;
      height: 28px;
      line-height: 26px;
    }
    &-block>.btn,
    .btn-group {
      float: right;
    }
  }

  //shuttle 
  .jstree-default {
    //li background color
    .jstree-clicked {
      background: #e6faf7;
      border-radius: 0;
    }
    .jstree-hovered {
      background: #feffeb;
      border-radius: 0;
    }
    .jstree-wholerow {
      height: 32px;
      &-clicked {
        background: #e6faf7;
      }      
      &-hovered {
        background: #feffeb;
      }
    }
    &.jstree-checkbox-no-clicked {
      .jstree-clicked.jstree-hovered,
      >.jstree-wholerow-ul .jstree-wholerow-clicked.jstree-wholerow-hovered {
        background: #feffeb;
      }
    }

    //base images
    .jstree-icon,
    .jstree-node,
    .jstree-themeicon-custom {
      background-image:url("/assets/images/icon/tree.png");
    }
    .jstree-icon,
    .jstree-icon:empty {
      width: 16px;
      margin-top: 5px;
    }    
    .jstree-node {
      margin-left: 16px;
      min-width: 16px;
      min-height: 32px;
      line-height: 32px;
      background: none;
    }
    >.jstree-container-ul>.jstree-node {
      margin-left: 0;
    }
    .jstree-anchor {
      padding: 0 5px 0 0;
      height:32px;
      line-height: 32px;
    }
    .jstree-ocl {
      margin-left: 10px;
      margin-right: 0;
    }
    

    //tree open&close icon
    .jstree-open>.jstree-ocl {
      background-position: -16px -24px;
    }
    .jstree-no-dots .jstree-closed > .jstree-ocl,
    .jstree-closed>.jstree-ocl {
      background-position: 0 -24px;
    }

    .jstree-leaf>.jstree-ocl,
    > .jstree-no-dots .jstree-leaf > .jstree-ocl {
      background: url("/assets/images/icon/tree.png") -32px -48px no-repeat;
    }

    //checkbox
    .jstree-checkbox {
      margin-left: 5px;
      margin-right: 10px;
      background-position: 0 0;
    }
    .jstree-checkbox-disabled {
      opacity: 1;
      background-position: -48px 0;
    }    
    .jstree-anchor>.jstree-undetermined {
      background-position: -16px 0;
    }
    &.jstree-checkbox-selection .jstree-clicked >.jstree-checkbox,
    .jstree-checked >.jstree-checkbox {
      background-position: -32px 0;
    }
    &.jstree-checkbox-selection .jstree-clicked >.jstree-checkbox-disabled,
    .jstree-checked >.jstree-checkbox-disabled {
      background-position: -64px 0;
    }
    .jstree-disabled {
      .jstree-checkbox {
        background-position: -48px 0;
      }
      &.jstree-clicked {
        .jstree-checkbox {
          background-position: -64px 0;
        }
      }
    }
    
    //tree icon
    .jstree-themeicon {
      margin-right: 5px;
      display: none;
      background-position: 0 -73px;
      &-custom {
        display: inherit;      
        &.graphic {
          &-location {
            background-position: 0 -96px;
          }
          &-device {
            background-position: -32px -96px;
          }
          &-user {
            background-position: -16px -96px;
          }
          &-etc {
            background-position: -32px -96px;
          }
          &-m {
            background-position: -48px -96px;
          }
          &-c {
            background-position: -64px -96px;
          }
          &-list {
            background-position: 0 -120px;
          }
          &-screen {
            background-position: -16px -120px;
          }
          &-button {
            background-position: -32px -120px;
          }
        }
      }
    }
    // tree를 펼칠때에 아이콘이 중복되어 해당 라인 주석 처리
    // .jstree-open>.jstree-anchor>.jstree-themeicon {      
    //   background-position: -27px -48px;
    // }
  }
}

.tree-wrapper {
  .tree-body {
    width: 100%;
    height: 600px;
    overflow: auto;
  }
  .tree-header {
    + .tree-body {
      height: 570px;
    }
  }  
  select[multiple] {
    border: 0;
    width: 100%;
    height: 100%;
    vertical-align: top;
    overflow: auto;
    &:focus {
      option {
        color: #4d4c4c !important;
        &:active,
        &:checked {
          color: #4d4c4c !important;
          //background: #e6faf7 linear-gradient(0deg, #e6faf7 0%, #e6faf7 100%);
        }
      }
    }
    option {
      position: relative;
      padding: 8px 10px 8px 25px;
      color: #4d4c4c !important;
      min-height: 32px;
      &:hover {
        background: #feffeb;
      }
      &:active,
      &:checked {
        color: #4d4c4c !important;
        //background: #e6faf7 linear-gradient(0deg, #e6faf7 0%, #e6faf7 100%);
      }
      &:before {
        position: absolute;
        left: 15px;
        top: 13px;
        content: "";
        display: block;
        width:4px;
        height: 4px;
        background: url("/assets/images/icon/tree.png") no-repeat -38px -58px;
      }
      /*
      &:after {
        position: absolute;
        left: 32px;
        top: 10px;
        content: "";
        display: block;
        width:12px;
        height: 12px;
        background: url("/assets/images/icon/tree.png") no-repeat -18px -78px;
      }
      */
    }
  }  
}
