/**
 * @file _aside.scss
 * @description 우측 슬라이드 메뉴의 스타일 클래스 파일
 */

.aside-menu {
  z-index: $zindex-sticky;
  border: none;
  position: fixed;
  width: $navbar-info-width;
  background: $aside-menu-bg;
  color: $aside-menu-color;
  @include borders($aside-menu-borders);
  display: flex;
  flex-direction: column;
  .alert-header {
    padding: 15px 0;
    position: relative;
    background-color: #23475c;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    color: $white;
    text-indent: 20px;
    &:before {
      content: "";
      position: absolute;
      top:-8px;
      right:54px;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent transparent #23475c transparent;
      border-width: 0 6px 8px 6px;
      border-style: solid;
    }
  }
  .alert-body {
    padding: 0 15px;
    flex: 1;
    overflow-y: auto;
    li {
      position: relative;
      margin-top: 10px; 
      padding: 5px 0;
      i {
        position: absolute;
        display: block;
        left: 0;
        top: 4px;
        width: 18px;
        height: 18px;
        background: url("/assets/images/icon/alert.png") no-repeat 0 -3px;
        &.info {
          background-position-x: -28px;
        }
        &.excl-tri {
          background-position-x: -52px;
        }
        &.excl-circ {
          background-position-x: -4px;
        }
      }
      p {
        padding-left: 23px;
        height: auto;
        line-height: 1.5rem;
        font-size: 1.2rem;
        word-break: keep-all;
        &.text-muted {
          margin-top: 3px;
        }
      }
    }
    &::-webkit-scrollbar {width: 5px; height: 5px;}
    &::-webkit-scrollbar-thumb {background-color: #e4e4e4; border-radius: 10px;}
    &:hover::-webkit-scrollbar-thumb {background-color: #bbbbbb;}
  }
  .aside-footer {
    position: relative;
    height: 37px;
    ul {
      width: 100%;
      font-size: 1.5rem;
      position: absolute;
      &.buttons {
        background-color: #ebf0f0;
        border-top: 1px solid #d6e2e2;
        padding: 4px;
        z-index: 1;
        li {
          display: inline-block;
          padding: 2px 10px;
          border: 1px solid #aaaaaa;
          cursor: pointer;
          background-color: $white;
          border-radius: 100px;
          font-size: 1.5rem;
        }
        li:not(:first-child) {
          margin-left: 4px;
        }
      }
      &.setup {
        left: 0px;
        bottom: -100%;
        width: 100%;
        border: 1px solid #d6e2e2;
        background-color: $white;
        transition: bottom $layout-transition-speed;
        &.open {
          bottom: 100%;
        }
        li {
          padding: 2px 4px;
          display: flex;
          align-items: center;
          span:first-child {
            flex-grow: 1;
          }
        }
      }
    }
  }
}