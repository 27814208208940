/**
 * @file _main-page.scss
 * @description 로그인 후 메인화면의 스타일 클래스 파일
 */

// main page
.main-contents.home {
  display: flex;
  flex-direction: row;
}

.home-left {
  min-width: 482px;
  min-height: 2000px;
  background: #e7e7e7;
  &-logo {
    position: fixed;
    width: 564px;
    height: 418px;
    top: 51px;
    background: url(/assets/images/logo/main-logo-big.png) no-repeat;
  }
}

.home-contents {
  overflow: hidden;
  width: 100%;
  min-width: 920px;
  max-width: 1460px;
  padding: 12px 20px;
  margin: 0 auto;
  font-family: 'LGSmHaR', sans-serif;
  &-title {
    margin-bottom: 20px;
    font-family: 'LGSmHaSB', sans-serif;
    font-size: 46px;
    &-ver {
      font-size: 32px;
      color: #999;
    }
    &-sub {
      margin-bottom: 20px;
      font-size: 18px;
      i {
        vertical-align: -3px;
        margin-right: 5px;
        font-size: 26px;
        color: #f92465;
      }
    }
  }
  p {
    margin-bottom: 20px;
    word-break: keep-all;
    font-size: 14px;
  }
  &-btn-area {
    margin-top: 40px;
  }
  &-btn-main {
    display: inline-block;
    height: 51px;
    line-height: 51px;
    margin-right: 18px;
    padding: 0 23px 0 30px;
    border: 1px solid #636363;
    border-radius: 3px;
    box-shadow: 1px 2px 4px 0 rgba(213, 213, 213, 1);
    font-size: 22px;
    color: #fff;
    background: #666;
    font-family: 'LGSmHaL', sans-serif;
    i {
      margin-left: 5px;
      vertical-align: -3px;
      font-size: 30px;
      color: #000;
      &.fa-file-download {
        width: 22px;
        height: 29px;
        vertical-align: -7px;
        background-image: url(/assets/images/bg/icon-download.png);
        background-repeat: no-repeat;
      }
    }
    &:hover {
      color: #fff;
      background: #555;
    }
  }
  &-hr {
    height: 1px;
    background: #acacac;
    margin-top: 50px;
    margin-bottom: 35px;
  }
  &-btn-sub {
    display: inline-block;
    height: 47px;
    line-height: 47px;
    margin-right: 18px;
    padding: 0 27px;
    border: 1px solid #636363;
    border-radius: 3px;
    -webkit-box-shadow: 1px 2px 4px 0px rgba(213, 213, 213, 1);
    -moz-box-shadow: 1px 2px 4px 0px rgba(213, 213, 213, 1);
    box-shadow: 1px 2px 4px 0px rgba(213, 213, 213, 1);
    font-size: 18px;
    color: #000;
    &.grey {
      border-color: #d7d7d7;
    }
    &:hover {
      color: #000;
      background: #f1f1f1;
    }
  }
  &-menu {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-top: 35px;
    &-image {
      width: 336px;
      height: 199px;
      margin-right: 35px;
      background-color: #f4f4f4;
      background-repeat: no-repeat;
      &.guide {
        background-image: url(/assets/images/bg/main-guide.png);
      }
      &.components {
        background-image: url(/assets/images/bg/main-components.png);
      }
      &.patterns {
        background-image: url(/assets/images/bg/main-patterns.png);
      }
      &.samples {
        background-image: url(/assets/images/bg/main-samples.png);
      }
    }
    &-title {
      display: inline-block;
      margin-bottom: 20px;
      font-family: 'LGSmHaSB', sans-serif;
      font-size: 20px;
      color: #ec4f7d;
      &:hover {
        color: #ec4f7d;
      }
      i {
        font-size: 14px;
        vertical-align: 2px;
        font-weight: 900;
      }
    }
    &-text {
      width: 440px;
      margin-bottom: 20px;
      font-size: 14px;
      word-break: keep-all;
      color: #111;
      &.title {
        margin-bottom: 2px;
        font-family: 'LGSmHaSB', sans-serif;
      }
    }
  }
  &-notice {
    margin-top: 20px;
    margin-bottom: 123px;
    font-size: 14px;
    > li {
      display: flex;
      max-width: 455px;
      padding: 3px 0;
    }
    &-text {
      height: 27px;
      margin-top: 0;
      color: #474ede;
      &:hover {
        color: #474ede;
        text-decoration: underline;
      }
    }
    &-date {
      width: 80px;
      margin-left: auto;
      color: #999;
    }
  }
  &-footer {
    display: flex;
    align-items: flex-end;
    height: 199px;
    padding-bottom: 30px;
    &-info {
      font-size: 13px;
      color: #555;
      &-title {
        font-family: 'LGSmHaB', sans-serif;
      }
    }
  }
}

.footer-background {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  right: 0;
  &-image {
    min-width: 313px;
    height: 199px;
    background-position: bottom 0 left 0;
    background-image: url(/assets/images/bg/main-footer.png);
    background-repeat: no-repeat;
  }
  &-grey {
    width: calc(100vw - 1476px);
    height: 199px;
    background-image: url(/assets/images/bg/footer-bg-unit.png);
  }
}

// @include media-breakpoint-down(lg) {
//   .home-contents {
//     width: 100%;
//     min-width: 920px;
//     max-width: 1120px;
//     padding: 52px 20px 0 20px;
//   }
// }

@media screen and (max-width: 1460px) {
  .home-left,
  .footer-background {
    display: none;
  }
}