/**
 * @file _sidebar.scss
 * @description LUA Sidebar scss 파일
 */

// 사이드바 기본 스타일
.sidebar {
  display: flex;
  flex-direction: column;
  padding: $sidebar-padding;
  color: $sidebar-color;
  background: $sidebar-bg;
  @include borders($sidebar-borders);
  .sidebar-close {
    position: absolute;
    right: 0;
    display: none;
    padding: 0 $spacer;
    font-size: 24px;
    font-weight: 800;
    line-height: $navbar-height;
    color: $sidebar-color;
    background: 0;
    border: 0;
    opacity: .8;
    &:hover {
      opacity: 1;
    }
  }
  .sidebar-header {
    flex: 0 0 $sidebar-header-height;
    text-align: center;
    height: 48px;
    //background: #e8e8e8;

    // 사이드바 타이틀
    .sidebar-context-title {
      height: 48px;
      display: flex;
      align-items: center;
      color: #000;
      padding-left: 0.9rem;
      i {
        font-size: 1.5rem;
        &:before {
          content: "\f0c9";
        }
      }
      > span {
        font-size: 1.5rem;
        font-weight: normal;
        margin: 0;
        padding-left: 0.2rem;
        opacity: 1;
        -webkit-transition: all .5s;
        transition: all .5s;
        height: 48px;
        line-height: 48px;
      }
      .navbar-toggler{
        i {
          position: relative;
        }
        &:hover{
          color: #38c9b2;
        }
      }
    }
  }
  .sidebar-form {
    text-align: center;
    padding: 1rem .8rem;
    .input-group {
      &:before {
        font-family: 'simple-line-icons', sans-serif;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        content: "\e090";
        position: absolute;
        top: 8px;
        left: 8px;
        font-size: 1.2rem;
        color: #747474;
        z-index: 10;
      }
    }
    .form-control {
      color: $sidebar-form-color;
      background: $sidebar-form-bg;
      border: $sidebar-form-border;
      height: 30px;
      padding: 4px 12px 6px 28px;
      font-size: 1.1rem;
      &::placeholder {
        color: $sidebar-form-placeholder-color;
      }
    }
  }
  .sidebar-button-group {
    margin: 10px 8px 0 8px;
    background: #a50034;
    height: 38px;
    transition: none;
    overflow: hidden !important;
    .button-nav {
      float: left;
      margin: 0;
      padding: 8px 18px;
      text-decoration: none;
      display: block;
      transition: none;
      &.highlight {
        background: #a50034;
        color: #fff;
        font-size: 13px;
        display: block;
        text-align: center;
      }
      &+.button-nav.highlight {
        float: right;
        padding: 8px 13px;
        background: #92002e;
      }
    }
  }
  .sidebar-nav {
    position: relative;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include sidebar-width($sidebar-borders, $sidebar-width);
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 3px;
    }
  }
  .nav {
    flex-direction: column;
    //padding-top: 11px;
  }

  // 네비게이션 소제목
  .nav-title {
    padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
    font-size: 11px;
    font-weight: 100;
    color: $sidebar-nav-title-color;
    text-transform: uppercase;
  }

  // 네비게이션 구분자
  .nav-divider,
  .divider {
    height: 10px;
  }

  li:first-child .nav-item {
    border-top: none;
  }
  // 네비게이션 메뉴
  .nav-item {
    position: relative;
    margin: 0;
    transition: none;
    border-top: 1px #ddd solid;
    font-weight: normal;
    width: 100%;
    &.nav-item-banner {
      border-top: none;
    }
    &.depth1 {
      & > .nav-link {
        line-height: 17px;
        color: $sidebar-nav-color;
        height: 37px;
        font-size: 1.3rem;
        //font-weight: 600;
      }
      ul {
        padding:0;
      }
    }
    &.open {
      > ul {
        padding: 0 5px 8px;
        //padding-bottom: 8px;
      }
    }
  }
  .nav-dropdown-items {
    max-height: 0;
    padding: 0 0 8px;
    margin: 0;
    overflow: hidden;
    .nav-item {
      padding: 0;
      box-sizing: border-box;
      list-style: none;
      font-size: 1.2rem;
      border-top: none;
      .nav-link {
        line-height: 18px;
        height: 28px;
        padding: 0.4rem 1.7rem 0.4rem .75rem;
        color: $sidebar-nav-dropdown-color;
        &.active{
          background: #23475c;
          color:#fff;
          &:before{
            background: #fff;
          }
          &:after{
            transform: rotate(-90deg);
            color: #fff;
          }
          i{
            &:after{
              background-color: #fff;
            }
          }
        }
      }
    }
    .nav-dropdown-items {
      .nav-item {
        .nav-link {
          line-height: 16px;
          height: 28px;
          padding: .55rem .75rem .55rem 2rem;
          .bullet {
            &:after {
              content: "";
              display: inline-block;
              width: 5px;
              height: 1px;
              background: #888;
            }
          }
          &.active{
            background: #666;
            color:#fff;
            &:before{
              background: #fff;
            }
            &:after{
              transform: rotate(-90deg);
              color: #fff;
            }
          }
        }
      }
    }
  }
  & a.nav-link {
    color: $sidebar-nav-link-color;
  }
  .nav-link {
    display: block;
    padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
    color: $sidebar-nav-link-color;
    text-decoration: none;
    background: $sidebar-nav-link-bg;
    transition: none;
    @include borders($sidebar-nav-link-borders);
    @if $enable-sidebar-nav-rounded {
      border-radius: $border-radius;
    }
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    .bullet {
      position: relative;
      text-align: right;
      height: 3px;
      vertical-align: middle;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        width: 3px;
        height: 3px;
        background: $gray-600;
        border-radius: 50%;
      }
    }
    i {
      display: inline-block;
      width: 8px;
      margin: 0 ($sidebar-nav-link-padding-x / 2) 0 0;
      font-size: 14px;
      color: $sidebar-nav-link-icon-color;
      text-align: center;
      vertical-align: middle;
    }
    .icon-nav-circle {
      display: none;
    }
    .badge {
      float: right;
      margin-top: 2px;
    }
    &:not(.nav-link-banner) {
      &::after {
        content: "\f107";
        font-family: 'FontAwesome', sans-serif;
        color: $sidebar-nav-dropdown-indicator-color;
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        top: calc(50% - 4px);
        right: 20px;
        opacity: 0;
        line-height: 8px;
        transform: rotate(-90deg);
        transition: 0.5s transform;
        background: none;
        font-weight: normal;
      }
    }
    &:hover:not(.active) {
      color: $sidebar-nav-link-hover-color;
      background: $sidebar-nav-link-hover-bg;
      @include borders($sidebar-nav-link-hover-borders);
      i {
        color: $sidebar-nav-link-hover-icon-color;
      }
      &:not(.nav-dropdown-toggle) {
        &::after {
          opacity: 1;
          right: 8px;
          color: $sidebar-nav-dropdown-indicator-color;
          transition: 0.5s all;
        }
      }
    }
    &.active {
      color: $sidebar-nav-link-active-color !important;
      background: $sidebar-nav-link-active-bg;
      @include borders($sidebar-nav-link-active-borders);
      i {
        color: $sidebar-nav-link-active-icon-color;
        &::after {
          background-color: $sidebar-nav-dropdown-indicator-active-color;
        }
      }
      &::after {
        right: 8px;
        color: $sidebar-nav-dropdown-indicator-active-color;
        opacity: 1;
      }
    }
    @each $color,
    $value in $theme-colors {
      &.nav-link-#{$color} {
        background: $value;
        i {
          color: rgba(255, 255, 255, .7);
        }
        &:hover {
          background: darken($value, 5%) !important;
          i {
            color: #fff;
          }
        }
      }
    }
    &.nav-link.nav-link-banner {
      height: 40px;
      font-size: 1.3rem;
      font-weight: normal;
      color: #dde8f2;
      text-align: left;
      line-height: 40px;
      margin: 8px 0 0 0;
      padding: 0 0 0 42px;
      border: 0;
      background: #808080 url("/assets/images/icon/ban_link_list_01.png") no-repeat 10px 8px;
      i {
        color: #dde8f2;
        vertical-align: middle;
      }
      &:hover {
        color: #dde8f2 !important;
        text-decoration: none;
        background-color: #293846;
      }
    }
  }

  // eg. <a class="nav-link nav-dropdown-toggle" href="#">Components</a>
  .nav-dropdown-toggle {
    position: relative;
    cursor: pointer;
    &::before {
      content: "\f107";
      font-family: 'FontAwesome', sans-serif;
      color: $sidebar-nav-dropdown-indicator-color;
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      top: calc(50% - 5px);
      right: 8px;
      opacity: 1;
      line-height: 10px;
      font-size: 1.2rem;
      transition: transform .5s;
    }
  }

  // eg. <li class="nav-item nav-dropdown">
  .nav-dropdown.open {
    background: $sidebar-nav-dropdown-bg;
    @include borders($sidebar-nav-dropdown-borders);
    @if $enable-sidebar-nav-rounded {
      border-radius: $border-radius;
    }
    >.nav-dropdown-items {
      max-height: 1500px;
    }
    .nav-link {
      border-left: 0 !important;
    }
    >.nav-link.nav-dropdown-toggle::before {
      transform: rotate(180deg);
      opacity: 0.5;
    }
    .nav-dropdown.open {
      border-left: 0;
    }
  }
  .nav-label {
    display: block;
    padding: ($sidebar-nav-link-padding-y / 8) $sidebar-nav-link-padding-x;
    color: $sidebar-nav-title-color;
    &:hover {
      color: $sidebar-color;
      text-decoration: none;
    }
    i {
      width: 20px;
      margin: -3px ($sidebar-nav-link-padding-x / 2) 0 0;
      font-size: 10px;
      color: $sidebar-nav-link-icon-color;
      text-align: center;
      vertical-align: middle;
    }
  }
  @if (lightness( $sidebar-bg) < 40) {
    .progress {
      background-color: lighten($sidebar-bg, 15%) !important;
    }
  }
  .sidebar-footer {
    flex: 0 0 $sidebar-footer-height;
    padding: $sidebar-footer-padding-y $sidebar-footer-padding-x;
    background: $sidebar-footer-bg;
    @include borders($sidebar-footer-borders);
    .title {
      padding-left: 38px;
      position: relative;
      line-height: 30px;
      font-size: 1.8rem;
      color: #8c9f9f;
      &:before {
        content: "";
        position:absolute;
        left:3px;
        top:0;
        display: block;
        width: 30px;
        height: 30px;
        background: url("/assets/images/icon/sidebar_help.png") no-repeat center;
      }
    }
    .info {
      margin-top: 2px;
      color: #949494;
    }
  }
  .sidebar-minimizer {
    position: relative;
    flex: 0 0 $sidebar-minimizer-height;
    background-color: $sidebar-minimizer-bg;
    border: 0;
    @include borders($sidebar-minimizer-borders);
    i {
      position: absolute;
      top: 18px;
      right: 16px;
      transition: .3s;
      font-size: 18px;
      color: $gray-300;
      font-family: 'simple-line-icons', sans-serif;
      speak: none;
      font-style: normal;
      font-weight: 100;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      &::before {
        content: "\e07a";
      }
    }
    &:focus,
    &.focus {
      outline: 0;
    }
    &:hover {
      background-color: $sidebar-minimizer-hover-bg;
      &::before {
        background-image: $sidebar-minimizer-hover-indicator;
      }
    }
  }
}

// 일반 사이드바
@include media-breakpoint-up(lg) {
  .sidebar-compact {
    .sidebar {
      .sidebar-nav {
        @include sidebar-width($sidebar-borders,
        $sidebar-compact-width);
      }
      .nav {
        @include sidebar-width($sidebar-borders,
        $sidebar-compact-width);
        .nav-title {
          text-align: center;
        }
        .nav-item {
          width: $sidebar-compact-width;
          border-left: 0 !important;
          .nav-link {
            text-align: center;
            i {
              display: block;
              width: 100%;
              margin: $spacer / 4 0;
              font-size: 24px;
            }
            .badge {
              position: absolute;
              top: 18px;
              right: 10px;
            }
            &.nav-dropdown-toggle {
              &::before {
                top: 30px;
              }
            }
          }
        }
      }
    }
  }

  // 사이드바 최소화
  .sidebar-minimized {
    .sidebar{
      .tab-content{
        width: 249px;
        overflow-y: hidden;
      }
    }
    .hidden-cn {
      display: none;
    }
    .sidebar {
      z-index: $zindex-sticky - 1;
      .sidebar-nav {
        padding: 0;
        overflow: visible;
        @include sidebar-width($sidebar-borders,
        $sidebar-minimized-width);
      }
      .nav {
        @include sidebar-width($sidebar-borders,
        $sidebar-minimized-width);
      }
      .nav-divider,
      .divider,
      .nav-title,
      .sidebar-footer,
      .sidebar-form
      {
        display: none;
      }
      .sidebar-context-title {
        > span {
          opacity: 0;
          margin-left: -200px;
          transition: all .5s ease-in-out;
        }
        //sidebar arrow
        /*
        i {
          &:before {
            content: "\f18e";
          }
        }*/ 
      }
      .sidebar-minimizer::before {
        width: 100%;
        transform: rotate(-180deg);
      }
      .nav-item {
        @include sidebar-width($sidebar-borders,
        $sidebar-minimized-width);
        overflow: hidden;
        border-top: none;
        border-left: 0 !important;
        &:hover {
          background: #e1e1e1 !important;
          width: $sidebar-width + $sidebar-minimized-width;
          overflow: visible;
          >.nav-link {
            background: #e1e1e1 !important;
            i {
              color: $sidebar-nav-link-hover-color;
            }
          }
        }
        .nav-link {
          &::after {
            opacity: 0;
          }
        }
        .bullet {
          &:after {
            top: 8px !important;
            right: 6px !important;
            background: $gray-600;
          }
        }
        .icon-nav-circle {
          border-radius: 50%;
          background: #bbb;
          width: 30px;
          height: 30px;
          margin: -4px 20px -4px 10px;
          text-align: center;
          display: block;
          float: left;
          font-size: 1rem;
          line-height: 30px;
          color: #fff;
          font-weight: 100;
        }
      }
      .nav-link {
        position: relative;
        padding-left: 0;
        margin: 0;
        white-space: nowrap;
        border-left: 0 !important;
        i {
          display: block;
          float: left;
          width: 12px;
          font-size: 18px;
        }
        .badge {
          position: absolute;
          right: 15px;
          display: none;
        }
        &:hover {
          width: $sidebar-width + $sidebar-minimized-width;
          background: #e1e1e1 !important;
          .badge {
            display: inline;
          }
        }
        &.nav-dropdown-toggle::before {
          display: none;
        }
      }
      .nav-dropdown-items {
        .nav-item {
          width: $sidebar-width;
          .nav-link {
            width: $sidebar-width;
          }
        }
      }
      .nav>.nav-dropdown {
        >.nav-dropdown-items {
          display: none;
          max-height: 1000px;
          background: $sidebar-bg;
        }
        &:hover {
          background: $sidebar-nav-link-hover-bg;
          >.nav-dropdown-items {
            position: absolute;
            left: $sidebar-minimized-width;
            display: inline;
          }
        }
      }
      .nav-link-banner {
        display: none;
      }
    }
    .sidebar-button-group {
      opacity: 0;
      overflow: hidden !important;
    }
    .sidebar-form {
      opacity: 0;
      overflow: hidden !important;
    }
    .sidebar-minimizer {
      i {
        transform: rotate(180deg);
      }
    }
    .sidebar-tabs {
      .nav-tabs {
        .nav-link {
          width: 49px;
          transition: width .5s, background-color .5s, opacity .5s;
          opacity: 1;
          &:hover{
            width: 150px;
            border-top: 0;
            background-color: #6c6c6c !important;
            color: #fff !important;
            opacity: 0.8;
          }
        }
      }
    }
  }
  .btn-mobile-menu-show{
    display: none;
  }
}

// 모바일 사이드바
@include media-breakpoint-down(md) {
  .sidebar-backdrop {
    visibility: hidden;
    position: fixed;
    z-index: 1018;
    cursor: pointer;
    opacity: 0;
    padding-top: 51px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: opacity .5s, visibility .5s;
    transition: opacity .5s, visibility .5s;
  }
  .sidebar-mobile-show .sidebar-backdrop {
    visibility: visible;
    opacity: 1;
  }

  // 대메뉴 선택 드롭다운 (모바일 상태)
  .sidebar .sidebar-header {
    .sidebar-context-title {
      padding-left: 0;
      > span {
        margin-left: 0;
        padding-left: 1.6rem;
        width: 100%;
        text-align: left;
        cursor: pointer;
        &:hover {
          background: $white;
        }
      }
      .dropdown-menu.main-menu {
        font-size: 1.4rem;
        width: 198px;
        margin:0;
        border-radius: 0;
        border: 0;
        padding: 0.6rem 0.8rem;
        box-shadow: 0 6px 12px rgba(0,0,0,0.1);
        .dropdown-item {
          font-family: 'LGSmHaSB', sans-serif;
          font-size: 1.4rem;
          font-weight: normal;
          line-height: 1.2;
          border-color: #ddd;
          padding: 10px 10px;
          &:hover {
            color: $lua-pink-500;
          }
          &:active, &.active {
            background-color: #f3f8fd;
          }
        }
      }
      .btn-mobile-menu-show {
        color: $gray-600;
        float: right;
        margin: 15px 12px 0 0;
        background: transparent;
        i {
          font-size: 1.6rem;
          position: relative;
        }
      }
    }
  }
}

// 탭이 있는 사이드바
.sidebar-tabs {
  .nav-tabs {
    flex-direction: row;
    padding-top: 0 !important;
    border-bottom: 1px solid #ccc;
    .nav-item {
      border: none !important;
      flex: 1;
      border-radius: 0 !important;
      &:first-child {
        & > a {
          margin: 0 !important;
        }
      }
      & > a {
        border-radius: 0 !important;
        border-right: none !important;
        border-left: 1px solid #ccc !important;
        border-top: 1px solid #ccc !important;
        border-bottom: none;
        position: relative;
        display: block;
        padding: 10px 0;
        line-height: 1.2rem;
        vertical-align: middle;
        text-align: center;
        height: 36px;
        span {
          line-height: 1.2rem;
          vertical-align: middle;
        }
      }
      &+.nav-item {
        margin-right: 1px;
      }
      .nav-link {
        &:hover,
        &.active {
          background-color: $white;
          font-weight: 100;
          color: $brand-color;
        }
        &::after {
          opacity: 0 !important;
        }
      }
    }
  }
  .tab-content {
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    max-height: calc(100vh - 135px);
    .tab-pane {
      padding: 0 !important;
      border: none !important;
      background-color: transparent !important;
    }
  }
}

// 트리형태 사이드 바
.sidebar-nav {
  &.sidebar-nav-tree {
    .nav-btn-toolbar {
      padding-left: 1rem;
    }
    .btn-toggle {
      height: 16px;
      width: 16px;
      padding: 0;
      line-height: 16px;
      font-size: 16px;
      background: none;
      color: $light-teal;
      &:hover,
      &:focus,
      &:active {
        color: $teal;
      }
      @include transition($btn-transition);
    }
  }
}


