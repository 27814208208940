/**
 * @file _layout.scss
 * @description 위치, 간격, 사이즈등의 레이아웃과 관련된 util 클래스 
 */

.clear {
  clear: both;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.txt-center {
  text-align: center !important;
}

.txt-left {
  text-align: left !important;
}

.txt-right {
  text-align: right !important;
}

.txt-red {
  color: #f01313 !important;
}

.txt-blue {
  color: #1456CF !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.wd-35 {
  width: 35px !important;
  min-width: 0 !important;
}

.wd-40 {
  width: 40px !important;
  min-width: 0 !important;
}

.wd-50 {
  width: 50px !important;
}

.wd-60 {
  width: 60px !important;
  min-width: 0 !important;
}

.wd-100 {
  width: 100px !important;
}

.wd-150 {
  width: 150px !important;
}

.wd-200 {
  width: 200px !important;
}

.wd-230 {
  width: 230px !important;
}

.wd-300 {
  width: 300px !important;
}

.wd-350 {
  width: 350px !important;
}

.wd-400 {
  width: 400px !important;
}

.wd-500 {
  width: 500px !important;
}

.wd-600 {
  width: 600px !important;
}

.wd-full {
  width: 100% !important;
}

.wd-per {
  &20 {
    width: 10% !important;
  }
  &30 {
    width: 30% !important;
  }
  &40 {
    width: 40% !important;
  }
  &50 {
    width: 50% !important;
  }
  &60 {
    width: 60% !important;
  }
  &70 {
    width: 70% !important;
  }
  &80 {
    width: 80% !important;
  } 
}


.list-inline {
  &.wd-type {
    margin-left: 0;
    width: 100%;
    li {
      padding-right: 0;
      &:first-child {
        padding-left: 0;
      }
    }

  }
}