/**
 * @file _tab.scss
 * @description 탭, 서브탭의 레이아웃과 관련된 스타일 클래스 파일 
 */

.nav {
  >li {
    >a {
      height: 41px;
      padding: 11px 20px 12px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

ul {
  &.nav-tabs {
    >li {
      >a.nav-link {
        margin-right: -1px;
        border-radius: 0;
        border: 1px solid #d5e1e1;
        background: $tab-bg;
        color: #747474;
        &:focus,
        &:hover {
          border-bottom: 1px solid #f7fafa;
          background: transparent;
          color: #1b252e;
          font-weight: 600;
        }
      }
      &.active {
        background: transparent;
        >a,
        >a:focus,
        >a:hover {
          color: #1b252e;
          font-size: 1.2rem;
          font-weight: 600;
          border-bottom: 1px solid #f7fafa !important;
          background: transparent !important;
          z-index: 1;
        }
      }
    }
    &.nav-subtabs {
      border-bottom: 4px solid #edeff1;
    }
    .nav-link {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.nav-subtabs {
  border-bottom: 4px solid #edeff1;
  >li {
    margin-bottom: -4px;
    >a {
      padding: 6px 18px;
      margin-right: 2px;
      border: none;
      background: none;
      border-bottom: 4px solid #abb5be;
      color: #99a6b2;
      &:focus,
      &:hover {
        border: none;
        background: none;
        border-bottom: 4px solid #337ab7;
        color: #337ab7;
        font-weight: 100;
      }
    }
    &.active {
      >a,
      >a:focus,
      >a:hover {
        border: none;
        background: none;
        border-bottom: 4px solid #337ab7;
        color: #337ab7;
        font-weight: 600;
      }
    }
  }
}

.tab-content {
  padding: 20px 0;
  overflow-y: auto;
  overflow-x: hidden;
  &.no-pb {
    padding: 20px 0 0;
  }
  &.add-mb {
    margin-bottom: -20px;
  }
}

.tab-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.tab-content::-webkit-scrollbar-track {
  background: none;
}

.tab-content::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #cccccc;
}

table+.nav,
.module-wrapper+.nav {
  margin-top: 20px;
}

.docs-tab-container {
  .nav>li>a {
    height: 41px;
    padding: 11px 20px 12px;
  }
  .nav-tabs>li {
    &:active {
      >a {
        &,
        &:focus,
        &:hover {
          color: #1b252e;
          font-size: 1.2rem;
          font-weight: 600;
        }
      }
    }
    >a {
      margin-right: -1px;
      border-radius: 0;
      border: 1px solid #ddd;
      background: #f8f8f8;
      color: #747474;
      &:focus,
      &:hover {
        border: 1px solid #ddd;
        border-bottom: none;
        background: #f8f8f8;
        color: #1b252e;
        font-weight: 600;
      }
    }
  }
  .tab-content {
    padding: 20px 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .tab-content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .tab-content::-webkit-scrollbar-track {
    background: none;
  }
  .tab-content::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #cccccc;
  }
  .tab-content.no-pb {
    padding: 20px 0 0;
  }
  .tab-content.add-mb {
    margin-bottom: -20px;
  }
  table+.nav,
  .module-wrapper+.nav {
    margin-top: 20px;
  }
  .nav-item {
    a.nav-link {
      text-align: center;
      min-width: 100px;
      &:hover,
      &.active {
        font-weight: normal !important;
      }
    }
  }
  .tab-content {
    border-right: none;
    border-left: none;
    border-bottom: none;
    .tab-pane {
      padding: 0;
    }
  }
}



//.process-map
.tab-process {
  padding: 0 10px;
  height: 38px;
  background-color: $tab-bg;
  border-bottom: 1px solid $tab-border-color;
  li {
    position: relative;
    display: inline-block;
    line-height: 38px;
    span {
      padding: 0 8px;
      display: block;
      color: #949494;
    }
    + li {
      padding-left: 27px;
      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 27px;
        height: 38px;
        background: url("/assets/images/icon/arrow_right.png") no-repeat center;
      }
    }
    &.success span {
      color: $gray-900;
    }
    &.active span {
      position: relative;
      font-weight: $font-weight-bold;
      color: $idp-navy;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-color: $idp-navy;
      }
    }
  }
}