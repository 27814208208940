/**
 * @file _ng-timepicker.scss
 * @description ng-timepicker 의 커스텀 스타일 클래스 파일 
 */

timepicker {
  &>table {
    border: none;
    td {
      border: none !important;
      text-align: center;
      input {
        &.bs-timepicker-field {
          border: none;
        }
      }
    }
    .bs-chevron {
      display: block;
    }
    .btn {
      &.btn-link {
        height: 23px;
      }
    }
  }
}