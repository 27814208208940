/**
 * @file _ng2-datepicker.scss
 * @description ng2-datepicker 의 커스텀 스타일 클래스 파일 
 */

div.dp-popup {
  padding: 10px;
  border: 1px solid #a8a8a8;
  margin-top: -2px;
  * {
    background: none;
    :not(button) {
      border: none !important;
    }
  }
  dp-day-calendar {
    .dp-weekdays {
      font-size: 12px;
    }
    .dp-calendar-wrapper {
      padding-top: 7px;
      .dp-calendar-week {
        button {
          &:first-child {
            color: $lua-pink-500;
            &.dp-prev-month {
              color: #f9aac2;
            }
          }
        }
      }
    }
    .dp-calendar-weekday {
      color: #747474;
    }
    .dp-calendar-day {
      width: 28px;
      height: 23px;
      margin: 0 1px;
      color: #202020;
      border: 1px solid transparent;
      &.dp-selected {
        background: #36b3af;
        border-radius: 4px;
        color: #fff;
        &:hover {
          border-color: #36b3af;
          background: #36b3af;
          color: #fff;
        }
      }
    }
    .dp-next-month,
    .dp-prev-month {
      color: #ccc;
    }
    .dp-calendar-day:hover {
      background-color: #fff;
      border: 1px solid #b4b4b4;
      border-radius: 4px;
    }
  }
  dp-time-select {
    .dp-time-select-control-down,
    .dp-time-select-control-up {
      background: none;
    }
    .dp-time-select-controls {
      margin-top: -5px;
    }
  }
}

// border-color: #36b3af;
// background-color: #36b3af;
// color: #fff!important;
// box-shadow: none;
// background:#fff;
// border:1px solid #b4b4b4;
dp-month-calendar {
  dp-calendar-nav {
    .dp-calendar-nav-container {
      height: 37px;
    }
  }
  .dp-nav-header span {
    font-size: 16px;
    font-weight: 700;
    padding-top: 5px;
    display: inline-block;
  }
  .dp-calendar-wrapper {
    .dp-calendar-month {
      width: 51px;
      height: 33px;
      color: #202020;
      border: 1px solid #36b3af;
      background: #ebf6f6;
      margin: 3px;
      &.dp-selected {
        background: #36b3af;
        border-radius: 1px;
        color: #fff;
        &:hover {
          border-color: #36b3af;
          background: #36b3af;
          color: #fff;
        }
      }
      &:hover{
        border-color: #36b3af;
        background: #36b3af;
        color: #fff;
      }
    }
  }
}

dp-month-calendar dp-calendar-nav,
dp-day-calendar dp-calendar-nav {
  .dp-nav-header {
    position: static;
    transform: none;
    display: block;
    height: 37px;
    text-align: center;
  }
  .dp-nav-header-btn {
    font-size: 16px;
    font-weight: 700;
    padding: 5px 0 8px;
  }
  .dp-nav-btns-container {
    display: block;
    position: relative;
    top: -35px;
    .dp-current-location-btn {
      visibility: hidden;
    }
    .dp-calendar-nav-container-left button,
    .dp-calendar-nav-container-right button {
      position: absolute;
      display: inline-block;
      width: 17px;
      height: 17px;
      // line-height: 15px;
      padding-left: 1px;
      font: normal normal normal 14px/1 FontAwesome, sans-serif;
      border: 1px solid transparent;
      color: #202020;
      font-size: 1.3rem;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      &:hover {
        border-color: #eaeaea;
        border-radius: 2px;
      }
      &:before {
        border: none;
        transform: none;
      }
      &:hover:before {
        color: #ff0066;
      }
    }
    .dp-calendar-nav-container-left button {
      left: 13px;
      &:before {
        content: "\f0d9";
      }
    }
    .dp-calendar-nav-container-right button {
      right: 2px;
      padding-left: 4px;
      &:before {
        content: "\f0da";
      }
    }
  }
}