/**
 * @file _shuttle.scss
 * @description 셔틀 컴포넌트 레이아웃 스타일 클래스 파일 
 */

*+.shuttle {
  margin-top: 30px;
}

.module-header+.shuttle {
  margin-top: 0;
}

.shuttle-default {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  .shuttle-left,
  .shuttle-right {
    float: left;
    width: 260px;
  }
  .shuttle-button {
    float: left;
    width: 98px;
  }
  .shuttle-list {
    height: 360px;
  }
}

.shuttle-button {
  text-align: center;
  padding: 0;
  ul {
    display: inline-block;
    position: relative;
    top: 50%;
    margin-top: -36px;
  }
  li {
    margin: 10px 0;
    button {
      padding: 0 16px;
      line-height: 18px;
      i {
        font-size: 1.6rem;
        margin: 0 1px;
      }
    }
  }
  .btn {
    &.shuttle-button-down i:before,
    &.shuttle-button-up i:before {
      font-weight: normal;
    }
  }
  &.shuttle-button--text {
    button {
      width: 100%;
      text-align: left;
      padding: 0 10px;
      i {
        font-size: 1.2rem;
        margin-right: 6px;
      }
    }
  }
  .shuttle-top {
    +.shuttle-button {
      margin: 15px 0;
      ul {
        top: auto;
        margin-top: auto;
      }
      li {
        display: inline-block;
        +li {
          margin-left: 8px;
        }
      }
    }
  }
}

.shuttle-button-down i,
.shuttle-button-up i {
  display: block;
  height: 7px;
  line-height: 5px;
}

.shuttle-list-total {
  display: block;
  height: 28px;
  line-height: 28px;
  margin-top: 10px;
  font-size: 1.2rem;
  color: #747474;
  em {
    color: $lua-pink-500;
  }
}

.shuttle-list {
  border-top: 2px solid #4d5762;
  ul {
    height: 100%;
    overflow-y: auto;
    padding: 6px 0;
    border: 1px solid #cccccc;
    border-top: none;
  }
  li {
    height: 30px;
    line-height: 28px;
    padding: 0 12px;
    cursor: pointer;
    &.selected {
      background-color: #f2f2f2;
    }
    &:hover {
      background-color: #ddd;
    }
    span {
      font-size: 1.2rem;
      color: #202020;
    }
  }
}

*+.shuttle-list {
  margin-top: 10px;
}

.shuttle-list ul::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.shuttle-list ul::-webkit-scrollbar-track {
  background: none;
}

.shuttle-list ul::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #cccccc;
}

.shuttle-buttons {
  text-align: center;
  padding: 0;
  ul{
    display: inline-block;
    padding: 0;
    margin-top: 30px;
  }
  li {
    margin: 7px 0;
    .btn {
      padding: 0 16px;
      line-height: 18px;
      i {
        font-size: 1.6rem;
      }
    }
  }
  .btn{
    width: 100%;
    margin: 0;
  }
}

.shuttle {
  textarea {
      width: 100%;
      border: 1px solid #a8a8a8;
  }
  &-buttons {
      text-align: center;
      padding: 0;
      .btn {
          width: 100%;
          margin: 0;
      }
      ul {
          display: inline-block;
          padding: 0;
          margin-top: 30px;
      }
      li {
          margin: 7px 0;
      }
  }
}