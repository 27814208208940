/**
 * @file _popover.scss
 * @description 팝오버 레이아웃 스타일 클래스 파일 
 */

//icon guide
.popover {
  font-size: 1.1rem;
  border-left: 3px solid #6c6c6c;
  h3 {
    font-size: 1.2rem;
  }
  .arrow {
    &:after,
    &:before {
      border: none;
    }
  }
  .popover-header {
    border-bottom: none;
    color: #6c6c6c;
  }
}