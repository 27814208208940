/**
 * @file _fontawesome.scss
 * @description fontawesome 의 커스텀 스타일 클래스 파일 
 */

/* icon */

.fa-file-word-o,
.fa-file-powerpoint-o,
.fa-file-pdf-o,
.fa-file-excel-o,
.fa-file-text-o,
.fa-trash-o {
  color: #0b8c8f;
  font-size: 1.4rem;
}

.fa-plus+.fa-plus {
  margin-left: 1px;
}

.fa-plus-square-o {
  color: $lua-pink-500;
  font-size: 1.4rem;
}

.fa-minus-square-o {
  color: #0b8c8f;
  font-size: 1.4rem;
}

.fa-user {
  color: #0b8c8f;
  font-size: 1.4rem;
}

.fa+span {
  margin-left: 6px;
}

.fa-check-circle {
  color: #2cbebf;
}

.fa-exclamation-circle {
  color: #ffc339;
}

.fa-times-circle {
  color: #ff6475;
}

.fa-camera {
  font-size: 1.4rem;
}

.fa-spin {
  -webkit-animation: fa-spin 1s infinite linear;
  animation: fa-spin 1s infinite linear;
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}