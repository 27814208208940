@charset "utf-8";

/**
 * @file _typography.scss
 * @description 기본폰트 설정 파일
 */

@font-face {
  font-family: 'LGSmHaL', sans-serif;
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/LGSmHaL.eot');
  src: url('/assets/fonts/LGSmHaL.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/LGSmHaL.woff') format('woff'), url('/assets/fonts/LGSmHaL.ttf') format('truetype');
}

@font-face {
  font-family: 'LGSmHaR', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/LGSmHaR.eot');
  src: url('/assets/fonts/LGSmHaR.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/LGSmHaR.woff') format('woff'), url('/assets/fonts/LGSmHaR.ttf') format('truetype');
}

@font-face {
  font-family: 'LGSmHaB', sans-serif;
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/LGSmHaB.eot');
  src: url('/assets/fonts/LGSmHaB.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/LGSmHaB.woff') format('woff'), url('/assets/fonts/LGSmHaB.ttf') format('truetype');
}

@font-face {
  font-family: 'LGSmHaSB', sans-serif;
  font-style: normal;
  src: url('/assets/fonts/LGSmHaSB.eot');
  src: url('/assets/fonts/LGSmHaSB.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/LGSmHaSB.woff') format('woff'), url('/assets/fonts/LGSmHaSB.ttf') format('truetype');
}

@font-face {
  font-family: 'LGEIcons', sans-serif;
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/LGEIcons.eot');
  src: url('/assets/fonts/LGEIcons.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/LGEIcons.woff') format('woff'), url('/assets/fonts/LGEIcons.ttf') format('truetype');
}
