/**
 * @file _datepicker.scss
 * @description 달력 데이트피커 레이아웃 관련 파일
 */

.datepicker {
  position: relative;
  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 7px;
    left: 8px;
    width: 12px;
    height: 12px;
    background: url("/assets/images/icon/calendar.png") no-repeat;
    z-index: 4;
  }
  i {
    content: "";
    display: inline-block;
    position: absolute;
    top: 7px;
    left: 8px;
    width: 12px;
    height: 12px;
    background: url("/assets/images/icon/calendar.png") no-repeat;
    z-index: 4;
    cursor: pointer;
  }
  input[type="text"].form-control {
    float: left;
    width: 175px !important;
    padding: 2px 8px 2px 28px;
  }
  &.datepicker-single input[type="text"].form-control {
    width: 100px !important;
  }
  .date-select {
    display: inline-block;
    position: relative;
    float: left;
    width: 100px;
    height: 26px;
    line-height: 22px;
    padding: 0 10px;
    margin-left: 8px;
    border: 1px solid #a8a8a8;
    cursor: pointer;
    i {
      position: absolute;
      right: 12px;
      top: 6px;
      color: #767676;
    }
  }
}

.datepicker:before {
  display: none;
}

.page-search .datepicker i {
  top: 9px;
}

.daterangepicker {
  padding: 0;
  margin-top: -1px;
  border-radius: 0;
  box-shadow: none;
  border-color: #a8a8a8;
  &.single {
    &.show-calendar {
      min-width: 230px;
    }
  }
  &.show-calendar {
    min-width: 462px;
    .range_inputs {
      display: block;
    }
  }
  &:before,
  &:after {
    display: none;
  }
  select.monthselect {
    margin-left: 2%;
    margin-right: 0;
    width: 30%;
  }
  .calendar-table {
    padding: 0;
    border: none;
  }
  &.ltr {
    .left .daterangepicker_input,
    .right .daterangepicker_input {
      padding: 0 !important;
      .calendar {
        &.left .calendar-table,
        &.right .calendar-table {
          padding: 0;
        }
      }
    }
    .calendar {
      &.left {
        padding: 10px 10px 10px 10px;
      }
      &.right {
        padding: 10px 10px 10px 10px;
      }
    }
    .ranges {
      float: right;
      clear: both;
    }
    .range_inputs {
      padding: 10px;
      float: right;
    }
  }
  .calendar {
    margin: 0;
    max-width: 230px;
    tr:first-child th {
      padding: 5px 0 8px;
      &:hover {
        background: none;
      }
    }
    th.month {
      font-size: 1.6rem;
      color: #000000;
    }
    th,
    td {
      min-width: 30px;
      padding: 2px 5px;
      font-size: 1.2rem;
      color: #202020;
    }
    th {
      color: #747474;
    }
    td {
      &.off {
        color: #cccccc;
        &.weekend:first-child {
          color: #f9aac2;
        }
        &:hover{
          color:#ccc;
        }
      }
      &.today {
        color: #36b3af;
        font-weight: bold;
      }
      &.weekend:first-child,
      &.holiday {
        color: $lua-pink-500;
      }
    }
  }
  &.single .calendar {
    margin-bottom: 0;
  }
  table {
    border-collapse: separate;
    border-top: none;
  }
  .input-mini {
    &.form-control {
      width: 100%;
      line-height: 22px;
      padding: 2px 8px;
      &:focus {
        border-color: #202020;
      }
    }
    &.active {
      border-color: #202020;
      border-radius: 0;
    }
  }
  .fa-chevron-left,
  .fa-chevron-right {
    width: 17px;
    height: 17px;
    line-height: 15px;
    padding-left: 1px;
    &:hover {
      border: 1px solid #eaeaea;
      border-radius: 2px;
      &:before {
        color: #ff0066;
      }
    }
  }
  .fa-chevron-left {
    &:before {
      content: "\f0d9";
      color: #202020;
      font-size: 1.3rem;
    }
  }
  .fa-chevron-right:before {
    content: "\f0da";
    color: #202020;
    font-size: 1.3rem;
  }
  td {
    &.in-range {
      background-color: #ebf6f6;
    }
    &.available:hover {
      background-color: #fff;
      border-color: #b4b4b4;
    }
    &.active,
    &.active:hover {
      border-color: #36b3af;
      background-color: #36b3af;
      color: #fff !important;
      box-shadow: none;
    }
    &.active.today {
      font-weight: normal;
    }
  }
  .daterangepicker_input i {
    display: none;
  }
  .applyBtn {
    border-color: #202020;
    background: #293846;
    color: #fff;
    &:hover,
    &:focus {
      background: #000;
    }
    +.cancelBtn {
      margin-left: 4px;
    }
  }
  .ranges {
    border: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      width: 94px;
      height: 29px;
      line-height: 24px;
      padding: 0;
      margin: 5px;
      border-color: #36b3af;
      border-radius: 1px;
      background: #ebf6f6;
      font-size: 1.2rem;
      color: #202020;
      text-align: center;
      [data-range-key="Custom Range"] {
        display: none;
      }
      &.active,
      &:hover {
        background: #36b3af;
        color: #fff;
      }
    }
    ul {
      width: 218px;
      padding: 5px;
    }
  }
}

td.txt-center .datepicker {
  display: inline-flex;
}
