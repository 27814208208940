/**
 * @file _bs-timepicker.scss
 * @description bs-timepicker 의 커스텀 스타일 클래스 파일
 */

/* .bs-timepicker */
.bs-timepicker {
  &-container {
    padding: 10px 0;
  }

  &-label {
    color: $font-color-01;
    margin-bottom: 10px;
  }

  &-controls {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;

    button {
      width: 20px;
      height: 20px;
      border-radius: 0;
      border: 0;
      background-color: $btn-bg;
      color: $font-color-01;
      font-size: 16px;
      font-weight: 700;
      vertical-align: middle;
      line-height: 0;
      padding: 0;
      transition: 0.3s;

      &:hover {
        background-color: $btn-bg-hover;
      }
    }

    input {
      width: 35px;
      height: 25px;
      border-radius: 13px;
      text-align: center;
      border: 1px solid $border-color;
    }
  }

  .switch-time-format {
    text-transform: uppercase;
    min-width: 54px;
    height: 25px;
    border-radius: 20px;
    border: 1px solid $border-color;
    background: $main-bg;
    color: $font-color-01;
    font-size: 13px;

    img {
      vertical-align: initial;
      margin-left: 4px;
    }
  }
}
