/**
 * @file _mixins.scss
 * @description 믹스인 파일
 */

//
//bootstrap start
//

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash infront.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin plain-hover-focus {
  &,
  &:hover,
  &:focus {
    @content;
  }
}

@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
//
//bootstrap end
//

@mixin avatar($width, $status-width) {
  position: relative;
  display: inline-block;
  width: $width;

  .img-avatar {
    width: $width;
    height: $width;
  }

  .avatar-status {
    position: absolute;
    right: -3px;
    bottom: -3px;
    display: block;
    width: $status-width;
    height: $status-width;
    border: 2px solid #fff;
    border-radius: 50em;
  }
}

@mixin borders($borders) {
  @each $border in $borders {
    $direction: nth($border, 1);
    @if $direction == "all" {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border: $size $style $color;
    } @else if $direction == "top" {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-top: $size $style $color;
    } @else if $direction == "right" {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-right: $size $style $color;
    } @else if $direction == "bottom" {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-bottom: $size $style $color;
    } @else if $direction == "left" {
      $size: map-get(map-get($borders, $direction), size);
      $style: map-get(map-get($borders, $direction), style);
      $color: map-get(map-get($borders, $direction), color);
      border-left: $size $style $color;
    }
  }
}

@mixin sidebar-width($borders, $width) {
  $sidebar-width: $width;

  @each $border in $borders {
    $direction: nth($border, 1);
    @if $direction == "all" {
      $size: map-get(map-get($borders, $direction), size);
      $sidebar-width: ($sidebar-width - (2 * $size));
    } @else if $direction == "right" {
      $size: map-get(map-get($borders, $direction), size);
      $sidebar-width: $sidebar-width - $size;
    } @else if $direction == "left" {
      $size: map-get(map-get($borders, $direction), size);
      $sidebar-width: $sidebar-width - $size;
    }
    width: $sidebar-width;
  }
}

@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color !important;
  }
  a#{$parent} {
    @include hover-focus {
      color: darken($color, 10%) !important;
    }
  }
}
