/**
 * @file _title.scss
 * @description 페이지, 모듈등의 타이틀 레이아웃 스타일 클래스 파일
 */

 .page-header {
  position: relative;
  min-height: $page-header-height;
  margin: 0;
  //border-bottom: 1px solid #f2f2f2;
  &:after {
    content:"";
    display:block;
    clear: both;
  }
  .btn-fav {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }
  h2 {
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
    height: $page-header-height;
    line-height: $page-header-height;
    //margin-left: 22px;
    font-size: 1.8rem;
    color: #202020;
    font-weight: $font-weight-bold;
  }
  .sitemap {
    float: right;
    display: inline-block;
    li {
      padding-left: 0;
      line-height: $page-header-height;
    &:not(:last-child):after {
      font-family: 'FontAwesome', sans-serif;
      content: "\f105";
      margin-left: 10px;
      display: inline-block;
      width: 10px;
      height: 10px;
      }
    }
  }  
}

.print-tit {
  font-size: 1.65rem;
  color: #000;
  text-align: center;
  font-weight: bold;
  padding-bottom: 20px;
}

.popup-window {
  .page-header {
    min-height: 45px;
    border-bottom: none;
    background-color: #3e4d5a;
    h2 {
      margin: 0;
      color: #fff;
      font-size: 1.4rem;
    }
  }
}

.header-blank {
  margin-top: 38px;
}

.page-button {
  float: left;
  display: inline-block;
  position: relative;
  top: 15px;
  margin-left: 10px;
  button {
    width: 16px;
    height: 16px;
    background: none;
  }
  .fav {
    i {
      font-size: 1.3rem;
      &.fa-star {
        color: #ff0066;
      }
      &.fa-star-o {
        color: #949494;
      }
    }
  }
  .newWin {
    margin-left: 2px;
    i {
      position: relative;
      top: 1px;
      font-size: 1.3rem;
      color: #909090;
    }
  }
}

.common-button {
  float: right;
  display: inline-block;
  position: relative;
  top: 4px;
  right: 8px;
  button {
    float: left;
    display: inline-block;
    width: 40px;
    height: 40px;
    background: none;
  }
  .userInfo {
    span {
      position: relative;
      width: 28px;
      height: 28px;
      background: url("/assets/icon/user.png") no-repeat 0 -2px;
    }
    &:hover span,
    &.on span {
      background: url("/assets/icon/user_hover.png") no-repeat 0 -2px;
    }
    +.userInfo-popup {
      display: block;
    }
  }
  .userInfo-popup {
    display: none;
    position: absolute;
    top: 43px;
    right: 43px;
    width: 136px;
    height: 114px;
    padding: 12px 14px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #293846;
    z-index: 10;
    p {
      position: relative;
      margin: 0;
      &:before {
        font-family: 'FontAwesome', sans-serif;
        content: "\f0d8";
        position: absolute;
        display: inline-block;
        font-size: 1.6rem;
        color: #293846;
        top: -26px;
        right: -2px;
      }
      span {
        display: block;
        color: #fff;
        letter-spacing: 1px;
        +span {
          margin-top: 7px;
        }
      }
    }
    ul li {
      margin-top: 7px;
    }
  }
  .talksy {
    span {
      position: relative;
      width: 23px;
      height: 23px;
      background: url("/assets/icon/talksy.png") no-repeat 0 -23px;
      .badge {
        display: inline-block;
        position: absolute;
        top: -8px;
        right: -6px;
        padding: 2px 3px;
        text-indent: 0;
        background: #ff0066;
        font-size: 1.1rem;
      }
    }
    &.on span {
      background-position-y: 0px;
      .badge {
        display: none;
      }
    }
    &.on:hover span:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: -5px;
      right: -3px;
      width: 12px;
      height: 12px;
      background: url("/assets/icon/close.png");
    }
  }
}

.header-intro {
  padding: 20px 30px;
  clear: both;
  background: #f6f6f6;
  p {
    line-height: 20px;
    color: #202020;
    font-size: 1.2rem;
    +.list-inline {
      margin-top: 20px;
    }
  }
}

.module-header {
  position: relative;
  margin-bottom: 10px;
  &.active {
    float:left;
  }
  h3 {
    display: inline-block;
    position: relative;
    height: 28px;
    line-height: 28px;
    padding: 0 0 0 10px;
    font-size: 1.4rem;
    font-weight: bold;
    color: $idp-navy;
    background: none;
    margin: 0;
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      margin-top:-6px;
      top: 50%;
      left: 0;
      width: 3px;
      height: 12px;
      background-color: $idp-navy;
    }
    + div {
      .grid-header {
        margin-left: 10px;
        margin-bottom: 0;
      }
    }
    &.custom1 {
      height: 34px;
      font-size: 1.6rem;
      color: #202020;
      text-align: center;
      line-height: 34px;
      padding: 0;
      border-bottom: 1px solid #cdcdcd;
    }
  }
  h3.custom1:before,
  &.underline h3:before {
    content: none;
  }
  &.underline {
    position: relative;
    min-height: 48px;
    border-bottom: 1px solid #cdcdcd;
    h3 {
      height: auto;
      color: #202020;
      text-align: center;
      line-height: 1.3em;
      margin-bottom: 10px;
      padding: 0;
      .tit {
        display: block;
        font-size: 1.7rem;
      }
      .sub {
        font-size: 1.2rem;
      }
    }
    .absolute-right {
      position: absolute;
      right: 0;
      bottom: 10px;
    }
  }
  .list-inline select {
    margin-right: -5px;
  }
  /*
  >p {
    height: 28px;
    line-height: 30px;
  }*/
  +.module-header {
    margin-top: 5px;
  }
}

.module-footer {
  position: relative;
  margin-top: 10px;
}

.module-header:after,
.module-footer:after {
  content: "";
  display: block;
  clear: both;
}

*+.module-wrapper,
*+.module-header {
  margin-top: 20px;
}

td .page-search+.module-wrapper,
td .page-search+.module-header {
  margin-top: 5px;
}

.division-toggleBtn+.module-header {
  margin-top: 0;
}

.module-header span.info-text,
.module-header .info-text p,
.module-footer span.info-text,
.module-footer .info-text p {
  height: 28px;
  line-height: 1.5rem;
}

.module-toggle {
  &.list-inline {
    margin-left: 0;
    >li {
      padding-right: 0;
      padding-left: 0;
      +li {
        margin-left: 2px;
      }
    }
  }
  .btn {
    &.btn-icon {
      margin-top: 6px;
    }
  }
  .fa {
    color: #337ab7;
    font-size: 1.0rem;
  }
  &.lg-red {
    .fa {
      color: $lua-pink-500;
    }
  }
  .fa-border {
    border: 1px solid #bbb;
    border-radius: 2px;
  }
}

h3+.module-toggle.list-inline {
  margin-left: 8px;
}
.app-body {
  h4 {
    //margin: 0;  
    margin-left: 10px;
    padding: 0;
    padding-left: 10px;
    position: relative;
    height: 26px;
    line-height: 26px;
    font-size: 1.3rem;
    font-weight: bold;
    color: #337ab7;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 12px;
      display: block;
      width: 4px;
      height: 4px;
      background-color: #337ab7;
    }
  }  
}

*+h4 {
  margin-top: 18px;
  margin-bottom: 10px;
}

h3+h4 {
  margin-top: 10px;
}

// 페이지 헤더 아이콘
.page-header {
  .pageheader-icons {
    margin-bottom: 0 !important;
    display: inline-block;
    vertical-align: middle;
    margin-left: 18px;
    li {
      margin: 0 6px !important;
      padding: 0 !important;
    }
    &-divider {
      display: inline-block;
      width: 1px;
      height: 11px;
      background-color: #d6d6d6;
      margin: 0 !important;
    }
    &-link {
      display: block;
      padding-left: 0;
      line-height: 45px;
      height: 48px;
      color: #999;
    }
    &-withtext {
      color: #9a9b9b;
      background-color: transparent;
      i {
        color: #337ab7;
        font-size: 1.4rem; 
        display: inline-block;
        vertical-align: middle;
      }
      span {
        font-size: 1.1rem;
        text-indent: 0;
        vertical-align: middle;
      }
      &:hover {
        span {
          position: relative;
          color: inherit;
          &:after {
            content:"";
            position: absolute;
            left: 0;
            bottom: -1px;
            display: block;
            width: 100%;
            height: 1px;
            background-color: #9a9b9b;
          }     
        }
      }
    }
  }
}
