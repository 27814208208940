/**
 * @file _division.scss
 * @description 레이아웃 그리드 컬럼 사이의 디비젼 스타일 클래스 파일 
 */

.division {
  &.row {
    margin: 0 -20px 0;
  }
  &.toggle {
    +.btn-block {
      padding-right: 20px;
    }
  }
}

.module-wrapper+.division.row {
  margin-top: 20px;
}

.division-col {
  position: relative;
  padding: 0 20px;
  &.expand {
    display: block !important;
    width: 100% !important;
  }
  &.hidden {
    display: none !important;
  }
}
.division-flex {
  display: flex;
  flex-direction: row
}

.division-left {
  float: left;
  //padding-right: 10px;
}

.division-right {
  float: right;
  margin-right: -20px;
  padding-left: 20px;
}

.division-toggle {
  &.row {
    margin: 0 -20px 20px;
    padding-right: 20px;
  }
  .division-col {
    padding: 25px 20px 0;
    transition: width 0.3s;
  }
  .division-right {
    padding-right: 0;
    border-left: 1px solid #ccc;
    &.expand {
      border-left: none;
    }
  }
  +.btn-block {
    padding-right: 20px;
  }
  .division-row {
    padding: 25px 0 0;
    &.division-top {
      padding-bottom: 25px;
    }
    &.division-bottom {
      padding-top: 50px;
      border-top: 1px solid #ccc;
    }
  }
  .division-bottom {
    &.expand {
      padding-top: 35px;
      border-top: none;
    }
  }
}

.division-toggleBtn {
  position: absolute;
  top: 0;
  right: 8px;
  width: 18px;
  height: 18px;
  line-height: 10px;
  border: 1px solid #a8a8a8;
  background: none;
  font-size: 1.2rem;
  color: #a8a8a8;
  &:hover {
    border-color: #202020;
    color: #202020;
  }
}

.division-row {
  position: relative;
  padding: 0 20px;
  .division-toggleBtn {
    right: 0;
  }
  &.division-bottom {
    .division-toggleBtn {
      top: 15px;
    }
    &.expand {
      .division-toggleBtn {
        top: 0;
      }
    }
  }
}

.division-overflow {
  padding-right: 10px;
  overflow-y: auto;
}

.division-flex {
  &.division-flex-row {
    flex-direction: row;
    .division-flex-item:not(:first-child) {
      margin-left: 20px;
    }
  }
  
  &.division-flex-column {
    flex-direction: column;
    .division-flex-item:not(:first-child) {
      margin-top: 20px;
    }
  }
  .flex-remain {
    flex-grow: 1;
  }
}
