// 사용자상세 (레프트메뉴)
.user {
  display: flex;
  &-page {
    width: 180px;
    .btn {
      padding: 4px 12px 5px;
      width: 100%;
      height: 25px;
      border: 1px solid #cadbdb;
      background-color: #f0f5f5;
      border-radius: 2px;
      text-align: center;
      font-size: 1.1rem;
      span {
        display: inline-block;
      }
      i.window {
        margin-top: -3px;
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url("/assets/images/icon/new_window.png") no-repeat top right;
      }
    }
    .btn-edit {
      padding: 0;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      border: 1px solid $border-color;
      font-size: 0;
      text-indent: -9999px;
      background: #ffffff url("/assets/images/icon/edit.png") no-repeat 4px 2px;
      vertical-align: middle;
    }
    .profile {
      &-photo {        
        position: relative;
        width: 180px;
        height: 258px;
        background-color: $idp-gray;
        border-radius: 4px;
        overflow: hidden;
          > span.img {
          display: inline-block;
          width: 100%;
          height: 100%;
          overflow: hidden;
          background-size: cover;
          background-position: center 10%;
          img {
            width: 100%;
          }
        }
        .btn-edit {
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
      &-info {
        padding: 10px 5px;
        position: relative;
        p {
          height: auto;
        }
        .name {
          div {
            font-size: 1.6rem;
            font-weight: $font-weight-bold;
            color: $idp-navy;
            line-height: 1.9rem;
            span {
              font-size: 1.4rem;
            }
            + div span {
              margin-right: 5px;
              font-weight: $font-weight-normal;
              font-size: 1.4rem;
              // &:before {
              //   content: "("
              // }
              // &:after {
              //   content: ")"
              // }              
            }
          }
        }
        .company {
          margin-top: 10px;
          div {
            font-size: 1.2rem;
            line-height: 1.8rem;
          }
        }
      }
    }
    .view {
      padding: 8px 0;
      border-top: 1px solid $border-color;
      + .view + .view {
        border-bottom: 1px solid $border-color;
      }
      li {
        + li {
          margin-top: 4px;
        }
        i {
          margin-left: 5px;
          display: inline-block;
          vertical-align: middle;
          width: 14px;
          height: 13px;
          &.mail {
            background: url("/assets/images/icon/mail.png") no-repeat 1px 0px;
          }
          &.phone {
            background: url("/assets/images/icon/phone.png") no-repeat 3px 0px;
          }
          + span {
            padding-left: 8px;
          }
        }
        &.mail {
          text-align: center;
        }
      }
    }
  }
  &-cont {
    flex: 1;
    margin-right: 0;
    padding-left: 30px;
  }
}

/* 사용자검색 */
.user-info {
  table-layout : fixed;
  border-top: 0;
  .tree-wrapper {
    .tree-body {
      height: 395px;
    }
    .tree-header {
      + .tree-body {
        height: 365px;
      }
    }
  }
  .list-block {
    &.btn-tree {
      position:relative;
    }
    li {
      display: block;
    }
    .btn-module {
      border-radius: 3px;
      width: 62px;
      text-align: left;

    }
    li + li {
      margin-top: 3px;
    }
  }
  .total {
    padding: 0 10px;
    border: 1px solid #cccccc;
    border-bottom: 0;
    height: 35px;
    line-height: 35px;
    background-color: $lua-cyan-100;
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
  }
}
.modal-body {
  .fa {font-size: 1.2rem;}
  .fav .fa {color: $red}
}

//사용자검색 자동리스트
.favorites {
  position: relative;
  display: inline-block;
  .fav-pop {
    right: 0;
  }
}

.fav-pop {
  position: absolute;
  top: 30px;
  background-color: #fff;
  border: 1px solid #ccd5d5;
  z-index: 999;
  min-width: 200px;
  max-width: 300px;
  border-radius: 2px;
  .fav-header {
    position: relative;
    height: 20px;
    border-radius: 0;
    .close {
      position: absolute;
      display: inline-block;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      margin: 0;
      padding: 0;
      background: none;
      opacity: 1;
      text-shadow: none;
      span {
        position: relative;
        display: inline-block;
        top: 2px;
        right: 0px;
        width: 10px;
        height: 10px;
        background: url("/assets/images/icon/close2.png") no-repeat;
      }
    }
  }
  .fav-list {
    padding: 2px 2px 0 2px;
    height: auto;
    max-height: 200px;
    li {
      padding: 3px 0;
      position: relative;
      max-width:300px;
      width: 100%;
      height: inherit;
      &:hover {
        background-color: #f0f5f5;
      }
      label {
        float: none;
        margin-right: 0;
        padding: 0 25px 0 10px;
        max-width: 300px;
        height:20px;
        line-height: 20px;
        vertical-align: top;
        input[type="radio"].form-control + span:before,
        input[type="checkbox"].form-control + span:before  {
          top: 3px;
          margin-top: 0;
        }      
      }
      span {
        max-width: 240px;
        height: auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }      
      .btn-delete {
        position: absolute;
        top: 0;
        right: 0;
      }
      &:not(:hover) .btn-delete {
        display: none;
      }
    }
    &.fav-multiple {
      li {
        height: auto;
        label {
          height: auto;
        }
        span {
          padding-top: 3px;
          white-space: pre-wrap;
          text-overflow: initial;
          overflow: initial;
          line-height: 1.4rem;
        }
      }
    }
  }
  .page-btn {
    margin-top:0;
    padding: 10px;
    height: 45px;
    width: auto;
    .btn {
      margin-right: -5px;
      padding: 3px 10px;
      height: 25px;
      border-radius: 2px;
      &.btn-fav-pop {
        padding-left: 7px;
        padding-right: 7px;
        border-color: #cadbdb;
        background: #f0f5f5;
        color: $gray-800;
        /*
        &:focus,
        &:hover,
        &:active,
        &:active:hover,
        &:active:focus {
          border-color: #193f55;
          background: #193f55;
        }
        */
        .star {
          display: inline-block;
          width: 14px;
          height: 13px;
          background: url("/assets/images/icon/fav_star.png") no-repeat -31px -6px;
          vertical-align: middle;
        }
        span {
          vertical-align: top;
        }
      }
    }
  }
}


.fav-list {  
  height: 300px;
  overflow-y: auto;
  /*
  li {
    padding: 2px 0;
  }
  label {
    //line-height: 1.2rem;
  }*/
}