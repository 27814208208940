/**
 * @file _grid-table.scss
 * @description 그리드 테이블 레이아웃과 관련된 스타일 클래스 파일
 */

div {
  .grid-wrapper {
    div {
      &.grid-header {
        margin-top: 0px;
        margin-bottom: 10px;
        &:after {
          content: "";
          display: block;
          clear: both;
          >p {
            height: 28px;
            line-height: 32px;
          }
        }
        .info-text {
          height: 30px;
          line-height: 28px !important;
          i,
          p {
            line-height: 28px;
          }
        }
        .division {
          height: 24px;
        }
      }
      &.grid-filter {
        float: left;
        height:28px;
        margin-right: 20px;
        margin-bottom: 5px;
        position: relative;
        top: 0px;
        label {
          position: relative;
        }
        .fa-search {
          position: absolute;
          top: 7px;
          left: 12px;
          display: inline-block;
          color: #747474;
          font-size: 1.3rem;
        }
        input {
          min-width: 215px;
          height: 28px;
          padding-left: 30px;
          margin-left: 0;
          border-color: #9facac;
          box-shadow: none;
          font-size: 1.2rem;
          color: #202020;
        }
      }
      .grid-toolbar {
        display: inline-block;
        float: left;
        height: 28px;
        position: relative;
        top: 0px;
        .grid-info+.grid-buttons {
          margin-left: 10px;
        }
      }
      .grid-info {
        display: inline-block;
        float: left;
        height: 28px;
        line-height: 28px;
        padding: 0;
        font-size: 1.2rem;
        color: #747474;
        em {
          font-weight: 600;
          color: $red;
        }
      }
      .grid-select {
        border: 1px solid #9facac;
        border-radius: 2px;
        height: 28px;
        > label {
          padding: 0 10px;
          display: inline-block;
          color: #2f607d;
        }
        select {
          min-width: 80px;
          height: 100%;
          border: none;
        }
        label + select {
          border-left: 1px solid #9facac;
        }
        &.light{
          border-color: #d8dde3;
          label + select {
            border-left: 1px solid #d8dde3;
          }
        }
      }
      .datepicker input[type="text"].form-control {
        height: 28px;
      }      
      .grid-length {
        float: left;
        margin-right: 12px;
        font-size: 1.2rem;
        color: #747474;
        select {
          width: auto;
          vertical-align: middle;
          height: 28px !important;
          padding: 0 5px;
          border-radius: 0;
          border-color: #eaeaea;
          box-shadow: none;
          color: #333333;
        }
        span {
          display: inline-block;
          position: relative;
          top: 2px;
        }
        select + span {
          margin-left: 10px;
        }
        span + select {
          margin-left: 10px;
        }
      }
      &.grid-paginate {
        padding: 4px 0;
        text-align: center;
        background-color: $idp-grid-bg;
        border: 1px solid $ag-border-color;
        border-top: none;
        ul {
          &.pagination {
            vertical-align: top;
            display: inline-block;
          }
        }
      }
      .grid-setting {
        li {
          + li {
            padding-left: 0;
          }
        }
        button {
          position: relative;
          top: 2px;
          width:25px;
          height:25px;
          padding: 5px;
          background-color: transparent;
          color: $gray-500;
          &.setlayout {
            border-radius: 3px;
            border: 1px solid $gray-300;            
          }
          &.active {
            color: $gray-900;
          }         
        }
      }
      .grid-form {
        border:1px solid #a8a8a8;
        border-radius: 2px;
        height: 28px;
        > span {
          padding: 0 10px;
          display: inline-block;
          line-height: 26px;
          vertical-align: top;
          color: #2f607d;
          font-weight: $font-weight-bold;
          i.plus {
            margin-right: 6px;
            display: inline-block;
            width: 10px;
            height: 10px;
            background: url("/assets/images/icon/grid_button.png") no-repeat -420px -9px;
          }
        }
        .form {
          display: inline-block;
          li {
            position:relative;
            float: left;
          }
        }        
        select, input[type="text"] {
          height: 25px;
          vertical-align: top;
          border: 0;
          border-left: 1px solid #eaeaea;
          border-right: 0;
        }
        .fav-pop {
          top: 25px;
        }
      }
      .grid-empinfo {
        position: relative;
        padding-right: 0;
        padding-left: 0;
        .empinfo {
          input[type="text"].form-control {
            border: 1px solid #949494;
            height: 28px;
            padding-right: 60px;
          }
          > button {
            position: absolute;
            top: 1px;
            width: 26px;
            height: 26px;
            background-color: #ffffff;
          }
          .fav-pop {
            top: 27px;
          }
          .btn-idcard {
            right: 29px;
            border-right: 1px solid #f2f2f2;
          }
          .btn-favorites {
            right: 1px;
            &:hover i:after {
              content: "\f005";
              position: absolute;
              left:0;
              top:0;
            }
            i {
              position: relative;
              color: $lua-pink-500;
            } 
          }
        }
      }
      &.grid-buttons {
        float: left;
        width: auto;
        text-align: right;
        a.buttons-colvis {
          position: relative;
          height: 28px;
          border: none;
          line-height: 24px;
          padding: 0px 12px;
          &:focus {
            background: none;
            box-shadow: none;
            outline: none;
          }
          &:before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 1px;
            height: 20px;
            top: 5px;
            left: 0;
            background-color: #cccccc;
          }
          span {
            display: inline-block;
            position: relative;
            top: 3px;
          }
          i {
            font-size: 1.4rem;
            color: #747474;
          }
        }
        &.module-buttons {
          float: right !important;
          .btn {
            padding-top: 5px;
          }
          .btn+.btn {
            margin-left: 5px;
          }
          &.list-detail {
            position: absolute;
            right: 0px;
            z-index: 1;
          }
        }
        &.crud-buttons {
          float: right;
          margin-top: 14px;
          .btn {
            padding-top: 6px;
          }
          .btn+.btn {
            margin-left: 10px;
          }
          &:after {
            content: '';
            clear: both;
          }
        }
      }
    }
    .grid-clickable {
      tr {
        cursor: pointer;
      }
    }
  }
  &.grid-button-background {
    background: none;
  }
}

.grid-wrapper {
  .grid-paginate {
    .paginate-button {
      float: left;
      min-width: 2.3rem;
      height: 2.3rem;
      padding: 0;
      margin: 0;
      margin-left: -1px;
      border-radius: 0;
      border: none;
      text-align: center;
      &:hover {
        a {
          text-decoration: underline;
          color: #f92465;
          outline: none;
        }
      }
      &.disabled,
      &.disabled:hover,
      &.disabled:active {
        a {
          cursor: not-allowed;
        }
      }
      &.first a,
      &.previous a,
      &.next a,
      &.last a {
        border: none;
        border-radius: 0;
        //background: #fff;
        font-size: 1.3rem;
        color: #202020;
      }
      &.disabled {
        &.first a,
        &.previous a,
        &.next a,
        &.last a {
          color: #909090;
        }
      }
      &.next {
        border-left: 1px solid #eaeaea;
      }
    }
  }
}

.pagination {
  >li {
    >a {
      width: 100%;
      height: 100%;
      padding: 4px;
      margin: 0;
      border-color: #eaeaea;
      color: #333333;
      font-size: 1.2rem;
      outline: none;
    }
  }
  >.active,
  .paginate-button.active {
    >a,
    >a:focus,
    >a:hover {
      border-color: #d1dbdb;
      background: #fff;
      text-decoration: underline;
      color: $lua-pink-500;
      font-weight: $font-weight-bold;
      outline: none;
      border-radius: 2px;
    }
  }
}

ul {
  &.grid-button-collection {
    &.dropdown-menu {
      padding: 6px 0 0;
      border: 1px solid #a8a8a8;
      border-radius: 0;
      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: -8px;
        left: 12px;
        width: 10px;
        height: 8px;
        background: url(/assets/bg/balloon_arrow.png) no-repeat;
      }
      >li {
        padding: 0 6px;
      }
      >.buttons-columnVisibility {
        >a {
          position: relative;
          padding: 0 0 0 24px;
          line-height: 26px;
          color: #202020;
          font-size: 1.2rem;
          background-color: #fff;
          :before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 7px;
            left: 6px;
            width: 13px;
            height: 13px;
            border: 1px solid #707070;
          }
          :after {
            content: "\f00c";
            display: inline-block;
            position: absolute;
            top: 0px;
            left: 7px;
            width: 12px;
            height: 12px;
            font-family: 'FontAwesome', sans-serif;
            color: #202020;
          }
        }
      }
      >.buttons-colvisRestore {
        padding: 8px 0;
        margin-top: 8px;
        border-top: 1px solid #eaeaea;
        text-align: center;
        >a {
          display: inline-block;
          position: relative;
          width: 82px;
          height: 30px;
          line-height: 28px;
          padding: 0;
          padding-left: 16px;
          border: 1px solid #949494;
          font-size: 1.1rem;
          :before {
            content: "\f01e";
            font-family: 'FontAwesome', sans-serif;
            display: inline-block;
            position: absolute;
            top: 1px;
            left: 12px;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width:767px) {
  div.grid-wrapper div.grid-filter {
    display: block;
    float: none;
    text-align: left;
    margin-bottom: 10px;
  }
  div.grid-wrapper div.grid-length span {
    top: 0;
  }
}

div.grid-wrapper div.grid-length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}


// 예외처리 2단일 경우 타이틀 1열정렬
.grid-wrapper {
  .active {
    &:after {
      content:"";
      display:block;
      clear: both;
    }
    .module-header {
      float: left;
    }
    .grid-header {
      float: right;
      &.grid-header-left {
        float: left;
        margin-left: 20px;
      }
    }
  }
}

.btn-edit-div {
  display: flex;
  align-items: center;
  .btn-edit {
    height: 20px;
    font-size: 1.1rem;
    border-radius: 2px;
    background-color: #ffffff;
    border: 1px solid #202020;
    padding: 3px 5px 4px;
    margin-left: 4px;
    +span {
      display: inline-block;
      vertical-align: middle;
    }
  } 
}

