/* widget */

.widget-section {
  margin: -20px;
}

.widget {
  //display: inline-block;
  display: block;
  //float: left;
  //min-width: 344px;
  margin: 20px;
  opacity: 1;
  &.widget-default {
    border-top: 2px solid #4d5762;
  }
  .widget-removed {
    opacity: 0;
    transition: opacity 0.5s;
  }
}

.widget-header {
  padding: 15px 20px;
  &:after {
    content: "";
    display: inline-block;
    clear: both;
  }
}

.widget-default {
  .widget-header {
    border-left: 1px solid #cdcdcd;
    border-right: 1px solid #cdcdcd;
  }
  .widget-body {
    border: 1px solid #cdcdcd;
    border-top: none;
  }
}

.widget-title {
  position: relative;
  margin: 0;
  margin-right: 15px;
  font-size: 1.4rem;
  color: #202020;
}

.widget-alarm {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: -6px;
  width: 4px;
  height: 4px;
  background-color: #ff0066;
  border-radius: 2px;
}

.widget-tools {
  position: relative;
  top: -2px;
  .btn {
    +.btn {
      margin-left: 2px;
    }
    i {
      font-size: 1.4rem;
      color: #b4b4b4;
    }
  }
  .btn[data-widget=minimize] i {
    font-size: 1.8rem;
  }
  .btn[data-widget=remove] i {
    font-size: 1.8rem;
  }
  .btn i {
    font-size: 1.4rem;
    color: #b4b4b4;
  }
}

.widget-body {
  height: auto;
  padding: 30px;
  text-align: center;
  overflow: hidden;
  transition: padding 0.5s, height 0.5s;
}

.widget-minimized .widget-body {
  height: 0;
  padding: 0 30px;
  transition: padding 0.5s, height 0.5s;
}
