@charset "utf-8";

// docs contents style

.docs-contents {
  .panel {
    code {
      font-family: $font-family-base;
    }
  }
}

.docs-sample-box {
  margin-top: 0.5rem !important;
  margin-bottom: 2rem !important;
  border: 0 !important;

  .docs-sample-box-tabs {
    .nav-tabs {
      border: none !important;
      margin-bottom: 0 !important;
      background: #282a36 !important;
    }

    .tab-content {
      background: #282a36;
      padding: 0 !important;
      border: 0;

      .tab-pane {
        padding: 0 1rem 1rem;
        max-height: 400px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 6px !important;
          height: 6px !important;
        }

        &::-webkit-scrollbar-track {
          background: #282a36 !important;
          border-radius: 3px !important;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(255,255,255,0.35) !important;
          border-radius: 3px !important;
        }
      }
    }

    // minimized
    // maximized

    &.compact {
      .tab-content {
        .tab-pane {
          max-height: 215px;
        }
      }
    }

    &.auto {
      .tab-content {
        .tab-pane {
          max-height: none;
        }
      }
    }

    code {
      overflow: visible !important;
    }

    pre {
      margin-bottom: 0;
      overflow: visible !important;
    }

    .nav-tabs {
      border-bottom: none !important;
    }

    .nav-tabs > li {
      margin-bottom: 0;
    }

    .nav-tabs > li > a {
      margin: 0 !important;
      font-size: 1.1rem !important;
      height: auto !important;
      min-width: 140px !important;
      text-align: center;
      padding: 5px 20px 5px 20px !important;
      background: rgba(255,255,255,0.1) !important;
      border-radius: 0 !important;
      border: none !important;
      color: #999;
      font-weight: 100 !important;
      transition: background-color .1s;
      span {
        vertical-align: middle;
      }
    }

    .nav-tabs > li > a:focus,
    .nav-tabs > li > a:hover {
      border :none;
      background: rgba(255,255,255,.05) !important;
      border-radius: 0 !important;
      border-bottom: none !important;
      color: #999 !important;
      font-weight: 100;
    }

    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:focus,
    .nav-tabs > li.active > a:hover {
      border: none;
      border-bottom: none !important;
      border-radius: 0 !important;
      background: #616db3 !important;
      color: #efefef !important;
      font-weight: 100;
    }
  }

  .tabs-wrapper {
    position: relative;

    .btn-compodoc {
      color: #999;
      position: absolute;
      right: 13px;
      top: 7px;
      z-index: 1;
      font-size: 1.1rem;
      font-weight: 100;
      padding: 0;
      border: 0;
      &:hover {
        color: #e9e9e9;
      }
    }
  }
}
