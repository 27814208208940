/**
 * @file _detail-tables.scss
 * @description 디테일 테이블 레이아웃 관련 스타일 클래스 파일 
 */

table {
  margin-bottom: 0;
  border-top: 1px solid $table-th-line;
  +table {
    margin-top: 20px;
  }
  +.table-no-borderTop {
    margin-top: 10px;
  }
  &.table-no-borderTop {
    border-top: 1px solid #cdcdcd;
  }
  .pic-container {
    text-align: center;
    .pic {
      display: inline-block;
      position: relative;
      img {
        min-height: 100px;
        min-width: 100px;
        max-height: 152px;
        max-width: 152px;
      }
      .btn-edit {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 0;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        border: 1px solid $border-color;
        font-size: 0;
        text-indent: -9999px;
        background: #ffffff url("/assets/images/icon/edit.png") no-repeat 4px 2px;
        vertical-align: middle;
      }
    }
  }
  td {
    &.td-required {
      select {
        background: #fff5f5;
      }
    }
  }
}

.table {
  display: table;
  margin-bottom: 0;
  border-top: 2px solid #4d5762;
  .thead {
    display: table-header-group;
  }
  .tbody {
    display: table-row-group;
  }
  .tr {
    display: table-row;
  }
  .th,
  .td {
    display: table-cell;
  }
}

.table-detail {
  width: 100%;
  border: 1px solid $table-th-line;
  border-collapse: collapse;
  &.fixed-layout {
    table-layout: fixed;
  }
  tr:first-child {
    th, td {
      border-top-color: $table-th-line;
    }
  }
  th {
    line-height: 20px;
    text-align: right;
    padding: 5px 10px;
    border-left: 1px solid $table-th-line;
    border-right: 1px solid $table-th-line;
    border-top: 1px solid $table-line;
    font-weight: normal;
    color: #000;
    background: $idp-grid-bg;
  }
  td {
    line-height: 20px;
    padding: 3px 4px;
    border-top: 1px solid $table-line;
    color: #202020;
    background: #fff;
    + th {
      border-left: 1px solid $table-th-line;
    }
    .favorites {
      .fav-pop {
        right: inherit;
        left:0;
        top: 24px;
        .fav-list {
          max-height: 155px;
        }
      }
    }
    .ng-select {
      height: 25px;
      border: #9facac 1px solid;
      &.ng-select-focused {
        .ng-select-container {
          border: 0;
        }
      }
      .ng-select-container {
        height: 23px;
        min-height: 23px;
        .ng-value-container {
          .ng-input {
            input {
              height: 20px !important;
            }
          }
          .more-div {
            top: 23px;
          }
        }
      }
      .ng-clear-wrap {
        top: 1px;
      }
    }
  }  
  .list-label-div {
    display: inline-block;
    background-color: #e6ffcc;
    border-radius: 0;
    border: 1px solid #a6ff46;
    margin-right: 5px;
    .remove-icon {
      border-right: 1px solid #a6ff46;
      color: #7fa653;
      padding: 1px 5px;
      cursor: pointer;
    }
    .label {
      font-size: 1.2rem;
      display: inline-block;
      padding: 1px 5px;
    }
  }
  td
  .td {
    padding: 5px 8px;
    line-height: normal !important;
    background: #fff;
    color: #202020;
    font-size: 1.2rem;
    border-bottom: 1px solid #cdcdcd;
  }
  td {
    +td {
      border-left: 1px solid #e2e2e2;
      &.dummy {
        border-left: 0;
      }
    }
    &.row-delete {
      line-height: 1;
      text-align: center;
      border-left: 1px solid #cdcdcd;
      button {
        height: 20px;
        padding: 0 10px;
        background: none;
        &:hover .fa-trash-o {
          color: #134a79;
        }
      }
    }
    input+p {
      margin-top: 4px;
    }
  }
  th.th-sub {
    border-right: 1px solid #cdcdcd;
  }
  .td {
    >p {
      display: inline-block;
      padding: 4px 0 5px;
      margin: 0;
    }
  }
  td {
    &.table-detail-in {
      padding: 0;
      table {
        border-top: 0;
        th, td {
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
    > div {
      span {
        display: inline-block;
        + select {
          margin-left: 5px;
        }
      }
    }
  }
  .multi-line {
    white-space: pre-wrap;
    word-break: break-word;
  }
}

table th.required>span,
.table .th.required>span,
table td.required>span:first-child {
  position: relative;
  display: inline-block;
  &:before {
    content: "*";
    position: absolute;
    top: -2px;
    left: -7px;
    display: inline-block;
    width: 5px;
    height: 5px;
    color: #fd195e;
    font-size: 1.3rem;
    &:before {
      content: "*";
      position: absolute;
      top: -2px;
      left: -6px;
      display: inline-block;
      width: 5px;
      height: 5px;
      color: #fd195e;
      font-size: 1.2rem;
    }
  }
}

.table-list td .table-detail th {
  padding: 5px 10px;
  line-height: 20px;
  text-align: right;
  color: #000;
  font-size: 1.2rem;
  font-weight: normal;
  border-bottom: 1px solid $table-line;
  background: $idp-grid-bg;
}

td {
  .table-detail {
    border: 1px solid #cdcdcd;
    border-bottom: none;
    th {
      border-right: 1px solid #cdcdcd;
    }
  }
}

.table-scroll {
  height: 100px;
  overflow-y: auto;
}

